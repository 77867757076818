class ColorToolBar extends HTMLElement {

    get selection() {
        return this._selection;
    }

    set selection(selection) {
        this._selection = selection;
    }

    set buttons(buttons) {
        this._buttons = buttons;
    }

    constructor() {
        super();
        this.buttons = {
            textColor : {
                yellow:false,
                red:false,
                green:false,
                pink:false,
            }
        },
        this.textSelectedColor = 'yellow';
    }

    connectedCallback() {
        //console.log('connectedCallback');
        this.addEventListener('mouseup', evt => {
            evt.stopPropagation();
        });
        this.addEventListener('mousedown', evt => {
            evt.stopPropagation();
        });
        this.innerHTML = `<button class="buttonTextColor orange" data-color="orange"></button>
            <button class="buttonTextColor yellow selected" data-color="yellow"></button>
            <button class="buttonTextColor red" data-color="red"></button>
            <button class="buttonTextColor blue" data-color="blue"></button>
            <button class="buttonTextColor blueTwo" data-color="blueTwo"></button>
            <button class="buttonTextColor green" data-color="green"></button>
            <button class="buttonTextColor greenTwo" data-color="greenTwo"></button>
            <button class="buttonTextColor fuchsia" data-color="fuchsia"></button>
            <button class="buttonTextColor pink" data-color="pink"></button>
            <button class="buttonTextColor pinkTwo" data-color="pinkTwo"></button>
            <button class="buttonHandler"></button>`;
        
        var colorButtons = this.querySelectorAll('.buttonTextColor');
        colorButtons.forEach((button) => {
            button.addEventListener('click', (evt) => {
                let b = this.querySelector('.selected');
                if (b) {
                   b.classList.remove('selected');
                }
                this.textSelectedColor = button.getAttribute("data-color");
                button.classList.add('selected');
            });
        })
        var closeButton = this.querySelector('.buttonHandler');
        closeButton.addEventListener('click', (evt) => {
            this.setDefaultAction();
        });
    }
   
    setTextColorActions() {
        this.classList.add('colorToolbarDeployed');
        var colorButtons = this.querySelectorAll('.buttonTextColor');
        colorButtons.forEach(function(button) {
            button.classList.remove('displayNone')
        });
    }

    setDefaultAction() {
        this.classList.remove('colorToolbarDeployed');
        var colorButtons = this.querySelectorAll('.buttonTextColor');
        colorButtons.forEach(function(button) {
            button.classList.remove('displayNone')
        });
    }
}

customElements.define('intkn-color-toolbar', ColorToolBar);