import { notificationBubble } from './ground';

export default class Api {
    constructor(path, query, callBacks, caller) {
        let self = this;
        this._callBacks = {
            done: false,
            error: false,
            empty: false,
            progress: false
        };
        if (callBacks) {
            this._callBacks = callBacks;
        }
        this._path = false;
        if (path) {
            this._path = path;
        }
        this._query = false;
        if (query) {
            this._query = query;
        }
        this._caller = false;
        if (caller) {
            this._caller = caller;
        }
        this.trials = 0;
    }
    get path() {
        return this._path;
    }
    set path(path) {
        this._path = path;
    }
    get callBacks() {
        return this._callBacks;
    }
    set callBacks(callBacks) {
        this._callBacks = callBacks;
    }
    get formData() {
        return this._formData;
    }
    set formData(formData) {
        this._formData = formData;
    }
    get query() {
        return this._query;
    }
    set query(query) {
        this._query = query;
    }
    get caller() {
        return this._caller;
    }
    set caller(caller) {
        this._caller = caller;
    }
    _encodeQuery() {
        if (!this._query) {
            return;
        }
        let tmp = '';
        let i = 0;
        for (var q in this._query) {
            if (i > 0) {
                tmp += '&';
            }
            if (q == 'login'
                || q == 'key') {
                if (this._query.encryption && q !== 'encryption') {
                    tmp += q + '=' + encodeURIComponent(btoa(this._query[q]));
                    i++;
                    continue;
                }
                tmp += q + '=' + encodeURIComponent(this._query[q]);
                i++;
                continue;
            }
            if (this._query.encryption && q !== 'encryption') {
                //console.log(q, this._query[q])
                tmp += q + '=' + encodeURIComponent(btoa(JSON.stringify(this._query[q])));
                i++;
                continue;
            }
            tmp += q + '=' + encodeURIComponent(JSON.stringify(this._query[q]));
            i++;
        }
        return tmp;
    }

    _consume(stream, total = 0) {
        var data = stream.read().then(({ done, value }) => {
            if (done) {
                stream.close();
                return;
            }
            total += value.byteLength;
            console.log("received " + value.byteLength + " bytes (" + total + " bytes in total).")
        });
    }
    sendRequest() {
        //console.log(this._query)
        if (this._query) {
            this._sendPostData();
            return;
        }
        if (this._formData) {
            this._sendFormData();
            return;
        }
    }
    _sendPostData() {
        let self = this;
        let options = {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: this._encodeQuery(this._query)
        };
        this.trials++;
        fetch(this._path, options).then(
            function (response) {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' + response.status);
                    self._setCallBackError(response);
                    return;
                }

                // Examine the text in the response
                response.json().then(function (data) {
                    if (data.dataSet.status == 'error') {
                        if (self.trials < 4) {
                            console.log('retry:' + self.trials);
                            self._sendPostData();
                            return;
                        }
                        self._setCallBackError(data);
                        return;
                    }
                    if (data.dataSet.message == 'noobject') {
                        self._setCallBackEmpty(data);
                        return;
                    }
                    self._setCallBackDone(data);
                });
            }
        ).catch(function (err) {
            if (self.trials < 4) {
                console.log('retry:' + self.trials);
                self._sendPostData();
                return;
            }
            console.log('Fetch Error :-S', err);
            self._setCallBackError(err);
        });
    }
    addFile(label, file) {
        if (!this._formData) {
            this._formData = new FormData();
        }
        this._formData.append(label, file);
    }
    addData(json) {
        if (!this._formData) {
            this._formData = new FormData();
        }
        for (var j in json) {
            if (!json[j]) {
                continue;
            }
            this._appendData(j, json[j]);
        }
    }
    _appendData(label, data) {
        if (typeof (data) == 'string') {
            this._formData.append(label, data);
            return;
        }
        this._formData.append(label, JSON.stringify(data));
    }
    /*_sendFormData() {
        let self = this;
        let options = {
            method: 'POST',
            body: this._formData
        };
        fetch(this._path, options).then(
            response => self._consume(response.body.getReader())
        ).then((resp) => console.log("consumed the entire body without keeping the whole thing in memory!",resp))
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
            self._setCallBackError(err);
        });
    }*/
    _sendFormData() {
        let self = this;
        let request = new XMLHttpRequest();
        this.trials++;
        request.open('POST', this._path);
        request.upload.onprogress = function (evt) {
            let percent = (evt.loaded / evt.total) * 100;
            console.log(evt);
            self._setCallBackProgress(percent);
        };
        request.addEventListener('load', function (evt) {
            if (this.readyState == 4) {
                if (this.status !== 200) {
                    if (self.trials < 4) {
                        console.log('retry:' + self.trials);
                        self._sendFormData();
                        return;
                    }
                    self._setCallBackError(this.response);
                    return;
                }
                if (this.response.dataSet.status == 'error') {
                    if (self.trials < 4) {
                        console.log('retry:' + self.trials);
                        self._sendFormData();
                        return;
                    }
                    self._setCallBackError(this.response);
                    return;
                }
                if (this.response.dataSet.message == 'noobject') {
                    self._setCallBackEmpty(request.response);
                    return;
                }
                self._setCallBackDone(this.response);
            }
        });
        request.responseType = 'json';
        request.send(this._formData);
    }
    _setCallBackProgress(percent) {
        console.log('percent', percent)
        if (!this.callBacks.progress) {
            return;
        }
        if (!this.callBacks.progress.extraData) {
            this.callBacks.progress.action(this.caller, percent);
            return;
        }
        this.callBacks.progress.action(this.caller, percent, this.callBacks.progress.extraData);
    }
    _setCallBackDone(result) {
        if (!this.callBacks.done) {
            return;
        }
        if (!this.callBacks.done.extraData) {
            this.callBacks.done.action(this.caller, result);
            return;
        }
        this.callBacks.done.action(this.caller, result, this.callBacks.done.extraData);
    }
    _setCallBackEmpty(result) {
        if (!this.callBacks.empty) {
            return;
        }
        if (!this.callBacks.empty.extraData) {
            this.callBacks.empty.action(this.caller, result);
            return;
        }
        this.callBacks.empty.action(this.caller, result, this.callBacks.empty.extraData);
    }
    _setCallBackError(result) {
        if (!this.callBacks.error) {
            notificationBubble(this.caller, 'errortraylater');
            return;
        }
        if (!this.callBacks.error.extraData) {
            this.callBacks.error.action(this.caller, result);
            return;
        }
        this.callBacks.error.action(this.caller, result, this.callBacks.error.extraData);
    }
}