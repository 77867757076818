const strings = {
    /*Calendar*/
    sunday: {
        fr: "dimanche",
        en: "sunday",
        de: "sonntag",
        it: "domenica",
    },
    monday: {
        fr: "lundi",
        en: "monday",
        de: "montag",
        it: "lunedi",
    },
    tuesday: {
        fr: "mardi",
        en: "tuesday",
        de: "dienstag",
        it: "martedì",
    },
    wednesday: {
        fr: "mercredi",
        en: "wednesday",
        de: "mittwoch",
        it: "mercoledì",
    },
    thursday: {
        fr: "jeudi",
        en: "thursday",
        de: "donnerstag",
        it: "giovedi",
    },
    friday: {
        fr: "vendredi",
        en: "friday",
        de: "freitag",
        it: "venerdì",
    },
    saturday: {
        fr: "samedi",
        en: "saturday",
        de: "samstag",
        it: "sabato",
    },
    january: {
        fr: "janvier",
        en: "january",
        de: "januar",
        it: "gennaio",
    },
    february: {
        fr: "février",
        en: "february",
        de: "februar",
        it: "febbraio",
    },
    march: {
        fr: "mars",
        en: "march",
        de: "märz",
        it: "marzo",
    },
    april: {
        fr: "avril",
        en: "april",
        de: "april",
        it: "aprile",
    },
    may: {
        fr: "mai",
        en: "may",
        de: "mai",
        it: "maggio",
    },
    june: {
        fr: "juin",
        en: "june",
        de: "juni",
        it: "giugno",
    },
    july: {
        fr: "juillet",
        en: "july",
        de: "juli",
        it: "luglio",
    },
    august: {
        fr: "août",
        en: "august",
        de: "august",
        it: "agosto",
    },
    september: {
        fr: "septembre",
        en: "september",
        de: "september",
        it: "settembre",
    },
    october: {
        fr: "octobre",
        en: "october",
        de: "oktober",
        it: "ottobre",
    },
    november: {
        fr: "novembre",
        en: "november",
        de: "november",
        it: "novembre",
    },
    december: {
        fr: "décembre",
        en: "december",
        de: "dezember",
        it: "dicembre",
    },
    /*Buttons*/
    register: {
        fr: "s'inscrire",
        en: "register",
        de: "registrieren",
        it: "Registrati",
    },
    dates: {
        fr: "dates",
        en: "dates",
        de: "termine",
        it: "date",
    },
    /*Labels*/
    facetofacecourse: {
        fr: "Cours en présentiel",
        en: "Face-to-face course",
        de: "Kurs von Angesicht zu Angesicht",
        it: "Corso faccia a faccia",
    },
    virtualtraining: {
        fr: "enseignement virtuel",
        en: "virtual training",
        de: "virtuelle Bildung",
        it: "educazione virtuale",
    },
    elearningcourse: {
        fr: "parcours elearning",
        en: "elearning course",
        de: "e-Learning-Kurs",
        it: "corso elearning",
    },
    animation: {
        fr: "animation",
        en: "animation",
        de: "animation",
        it: "animazione",
    },
    places: {
        fr: "lieux",
        en: "places",
        de: "setzt",
        it: "luoghi",
    },
    availableseats: {
        fr: "Places disponibles",
        en: "Available seats",
        de: "verfügbare Sitzplätzet",
        it: "posti disponibili",
    },
    places2: {
        fr: "places",
        en: "seats",
        de: "sitzplätzet",
        it: "posti",
    },
    details: {
        fr: "détails",
        en: "details",
        de: "einzelheiten",
        it: "dettagli",
    },
    /*Titles*/
    registration: {
        fr: "inscription",
        en: "registration",
        de: "anmeldung",
        it: "iscrizione",
    },
    session: {
        fr: "session",
        en: "session",
        de: "session",
        it: "sessione",
    },
    /*Forms*/
    requiredfield: {
        fr: "*Champ obligatoire",
        en: "*Required field",
        de: "*Pflichtfeld",
        it: "*Campo obbligatorio",
    },
    fieldmustnotempty: {
        fr: "Le champ ne doit pas être vide",
        en: "The field must not be empty",
        de: "Das Feld darf nicht leer sein",
        it: "Il campo non deve essere vuoto",
    },
    invalidemail: {
        fr: "Email non valide",
        en: "Invalid email",
        de: "Ungültige E-Mail",
        it: "E-mail non valido",
    },
    invalidavsnumber: {
        fr: "Un numéro AVS est composé de 13 chiffres",
        en: "An AVS number is made up of 13 digits",
        de: "Eine AVS-Nummer besteht aus 13 Ziffern",
        it: "Un numero AVS è composto da 13 cifre",
    },
    passwordminnumberchars: {
        fr: "Veuillez saisir un mot de passe de 6 lettres ou plus",
        en: "Please enter a password of 6 or more chars",
        de: "Bitte geben Sie ein Passwort mit 6 oder mehr Zeichen",
        it: "Inserisci una password di almeno 6 lettere",
    },
    firstnameandname: {
        fr: "prénom et nom",
        en: "firstname and name",
        de: "Vor-und Nachname",
        it: "cognome e nome",
    },
    firstname: {
        fr: "prénom",
        en: "firstname",
        de: "Vorname",
        it: "nome",
    },
    name: {
        fr: "nom",
        en: "name",
        de: "Nachname",
        it: "cognome",
    },
    professionalinformation: {
        fr: "Informations professionnelles",
        en: "Professional information",
        de: "Professionelle Informationen",
        it: "Informazioni professionali",
    },
    job: {
        fr: "Fonction",
        en: "job",
        de: "funktion",
        it: "funzione",
    },
    organization: {
        fr: "organisation",
        en: "organization",
        de: "Organisation",
        it: "Organizzazione",
    },
    companyaddress: {
        fr: "adresse de l'entreprise",
        en: "company address",
        de: "firmenanschrift",
        it: "indirizzo aziendale",
    },
    personalinformations: {
        fr: "informations personnelles",
        en: "personal informations",
        de: "persönliche Informationen",
        it: "informazioni personali",
    },
    avsnumber: {
        fr: "numéro AVS",
        en: "avs number",
        de: "AVS-Nummer",
        it: "numero AVS",
    },
    dateofbirthplaceholder: {
        fr: "__/__/____ (Date de naissance)",
        en: "__/__/____ (Date of Birth)",
        de: "__/__/____ (Geburtsdatum)",
        it: "__/__/____ (Data di nascita)",
    },
    phonenumber: {
        fr: "N° de tél. ou mobile",
        en: "Tel. No. or mobile",
        de: "Tel. Nr. oder mobil",
        it: "Tel n°. o mobile",
    },
    personalinformations: {
        fr: "informations personnelles",
        en: "personal informations",
        de: "persönliche Informationen",
        it: "informazioni personali",
    },
    myaccount: {
        fr: "mon compte",
        en: "my account",
        de: "mein Konto",
        it: "il mio conto",
    },
    mycredentials: {
        fr: "mes identifiants",
        en: "my credentials",
        de: "meine Anmeldeinformationen",
        it: "le mie credenziali",
    },
    email: {
        fr: "e-mail",
        en: "email",
        de: "email",
        it: "e-mail",
    },
    password: {
        fr: "mot de passe",
        en: "password",
        de: "passwort",
        it: "parola d'ordine",
    },
    previous: {
        fr: "précédent",
        en: "previous",
        de: "bisherige",
        it: "precedente",
    },
    next: {
        fr: "suivant",
        en: "next",
        de: "folgenden",
        it: "seguente",
    },
    modify: {
        fr: "modifier",
        en: "modify",
        de: "ändern",
        it: "modificare",
    },
    validate: {
        fr: "valider",
        en: "validate",
        de: "bestätigen",
        it: "convalidare",
    },
    youarealreadyregistered: {
        fr: "vous êtes déjà inscrit",
        en: "you are already registered",
        de: "du bist bereits registriert",
        it: "sei già registrato",
    },
    useralreadyexist: {
        fr: "l'adresse email est déjà utilisée",
        en: "the email address is already used",
        de: "die E-Mail-Adresse wird bereits verwendet",
        it: "l'indirizzo e-mail è già utilizzato",
    },
    tologin: {
        fr: "se connecter",
        en: "to log in",
        de: "einloggen",
        it: "per accedere",
    },
    login: {
        fr: "connexion",
        en: "log in",
        de: "einloggen",
        it: "accesso",
    },
    usingyouraccount: {
        fr: "utiliser votre compte",
        en: "using your account",
        de: "verwenden Sie Ihr Konto",
        it: "usando il tuo account",
    },
    nothaveanaccount: {
        fr: "vous n'avez pas de compte ? Créer un compte",
        en: "you do not have an account ? Create an account",
        de: "sie haben kein Konto? Ein Konto erstellen",
        it: "non hai un account? Creare un account",
    },
    alreadyhaveanaccount: {
        fr: "vous avez déjà un compte ? Se connecter",
        en: "already have an account ? To log in",
        de: "sie haben bereits ein Konto ? Einloggen",
        it: "hai già un account ? Per accedere",
    },
    waitingsignupcourse: {
        fr: "veuillez patienter...<br/>Votre inscription est en cours",
        en: "please wait...<br/>Your registration is in progress",
        de: "warten Sie mal...<br/>Ihre Registrierung ist in Bearbeitung",
        it: "attendere prego...<br>La tua registrazione è in corso",
    },
    congratulationssignupcourse: {
        fr: "<h2><b>Félicitations</b></h2>Votre inscription à la formation <b>{{nameRessource}}</b> a bien été prise en compte.<br/>Vous pouvez à présent vous connecter en cliquant sur le bouton ci-dessous.",
        en: "<h2><b>Congratulations</b></h2>Your registration for the <b>{{nameRessource}}</b> training has been taken into account.<br/>You can now connect by clicking on the button below.",
        de: "<h2><b>Herzliche Glückwünsche</b></h2>Ihre Registrierung für das <b>{{nameRessource}}</b> Training wurde berücksichtigt.<br/>Sie können jetzt eine Verbindung herstellen, indem Sie auf die Schaltfläche unten klicken",
        it: "<h2><b>Congratulazioni</b></h2>La tua registrazione per la formazione <b>{{nameRessource}}</b> è stata presa in considerazione.<br/>Ora puoi connetterti facendo clic sul pulsante in basso.",
    },
    mylibraryvideos: {
        fr: "Mes vidéos",
        en: "My videos",
        de: "Meine Videos",
        it: "I miei video",
    },
    youtubevideos: {
        fr: "Vidéos YouTube",
        en: "YouTube Videos",
        de: "YouTube Videos",
        it: "Video di YouTube",
    },
    uploadvideos: {
        fr: "Importer une vidéo",
        en: "Import video",
        de: "Videos importieren",
        it: "Importa video",
    },
    addavideo: {
        fr: "Ajouter une vidéo",
        en: "Add a video",
        de: "Ein Video hinzufügen",
        it: "Aggiungi un video",
    },
    addvideos: {
        fr: "Ajouter la vidéo",
        en: "Add video",
        de: "Video hinzufügen",
        it: "Aggiungi video",
    },
    novideo: {
        fr: "Aucune vidéo",
        en: "No video",
        de: "Kein Video",
        it: "Nessun video",
    },
    mylibraryfiles: {
        fr: "Mes fichiers",
        en: "My files",
        de: "Meine Akten",
        it: "I miei file",
    },
    uploadfiles: {
        fr: "Importer un fichier",
        en: "Import a file",
        de: "Importieren Sie eine Datei",
        it: "Importa un file",
    },
    addafile: {
        fr: "Ajouter un fichier",
        en: "Add a file",
        de: "Fügen Sie eine Datei hinzu",
        it: "Aggiungi un file",
    },
    addfiles: {
        fr: "Ajouter le fichier",
        en: "Add file",
        de: "Datei hinzufügen",
        it: "Aggiungi file",
    },
    nofile: {
        fr: "Aucun fichier",
        en: "No file",
        de: "Keine Datei",
        it: "Nessun file",
    },
    mylibraryimages: {
        fr: "Mes images",
        en: "My pictures",
        de: "Meine Bilder",
        it: "Le mie foto",
    },
    uploadimages: {
        fr: "Importer une image",
        en: "Import an image",
        de: "Importieren Sie ein Bild",
        it: "Importa un'immagine",
    },
    addimages: {
        fr: "Ajouter l'image",
        en: "Add picture",
        de: "Bild hinzufügen",
        it: "Aggiungi immagine",
    },
    addaimage: {
        fr: "Ajouter une image",
        en: "Add a picture",
        de: "Bild hinzufügen",
        it: "Aggiungi una foto",
    },
    noimage: {
        fr: "Aucune image",
        en: "No picture",
        de: "Kein Bild",
        it: "Nessuna immagine",
    },
    selectedvideo: {
        fr: "Vidéo sélectionnée",
        en: "Selected video",
        de: "Empfohlenes Video",
        it: "Video selezionato",
    },
    selectedimage: {
        fr: "Image sélectionnée",
        en: "Selected image",
        de: "Ausgewähltes Bild",
        it: "Immagine selezionata",
    },
    selectedfile: {
        fr: "Fichier sélectionné",
        en: "File selected",
        de: "Datei ausgewählt",
        it: "File selezionato",
    },
    searchvideo: {
        fr: "Rechercher une vidéo",
        en: "Search a video",
        de: "Suchen Sie ein Video",
        it: "Cerca un video",
    },
    searchimage: {
        fr: "Rechercher une image",
        en: "Search a picture",
        de: "Suchen Sie ein Bild",
        it: "Cerca un'immagine",
    },
    searchfile: {
        fr: "Rechercher un fichier",
        en: "Search a file",
        de: "Suchen Sie eine Datei",
        it: "Cerca un file",
    },
    searchcomment: {
        fr: "Rechercher un commentaire",
        en: "Search comment",
        de: "Kommentar suchen",
        it: "Cerca commento",
    },
    "explain-youtube-link": {
        fr: '*Le lien doit contenir "https://" ou "http://" pour être valide.',
        en: '*The link must contain "https://" or "http://" to be valid.',
        de: '*Der Link muss "https://" oder "http://" enthalten, um gültig zu sein.',
        it: '*Il collegamento deve contenere "https://" o "http://" per essere valido.',
    },
    "placeholder-youtube-link": {
        fr: "Coller le lien youtube ici",
        en: "Paste YouTube link here",
        de: "YouTube-Link hier einfügen",
        it: "Incolla qui il link di YouTube",
    },
    encodingsetup: {
        fr: "Préparation de l'encodage",
        en: "Encoding setup",
        de: "Codierung vorbereiten",
        it: "Preparazione per la codifica",
    },
    videoencodinginprogess: {
        fr: "Encodage en cours...<br>La vidéo sera disponible dans quelques minutes...",
        en: "Encoding in progress...<br>The video will be available in a few minutes...",
        de: "Kodierung läuft...<br>Das Video wird in wenigen Minuten verfügbar sein...",
        it: "Codifica in corso...<br>Il video sarà disponibile tra pochi minuti...",
    },
    novideoavailable: {
        fr: "Aucune vidéo disponible",
        en: "No video available",
        de: "Kein video vorhanden",
        it: "Nessun video disponibile",
    },
    editmode: {
        fr: "Mode édition",
        en: "Edit mode",
        de: "Bearbeitungsmodus",
        it: "Modalità Modifica",
    },
    delete: {
        fr: "Supprimer",
        en: "Delete",
        de: "Löschen",
        it: "Cancellare",
    },
    deletecomment: {
        fr: "Supprimer le commentaire",
        en: "Delete comment",
        de: "Kommentar löschen",
        it: "Elimina commento",
    },
    deleteattachedfile: {
        fr: "Supprimer le fichier attaché",
        en: "Delete attached file",
        de: "Angehängte Datei löschen",
        it: "Elimina il file allegato",
    },
    cancel: {
        fr: "Annuler",
        en: "Cancel",
        de: "Abbrechen",
        it: "Annulla",
    },
    yes: {
        fr: "Oui",
        en: "Yes",
        de: "Ja",
        it: "Sì",
    },
    doyouconfirmdeletion: {
        fr: "Confirmez-vous la suppression ?",
        en: "Do you confirm deletion?",
        de: "Bestätigen Sie die Löschung?",
        it: "Confermi la cancellazione?",
    },
    "deleteressource?": {
        fr: "Souhaitez vous supprimer cette ressource ?",
        en: "Do you want to delete this resource?",
        de: "Möchten Sie diese Ressource löschen?",
        it: "Vuoi eliminare questa risorsa?",
    },
    /*COMMNENTS*/
    nodescription: {
        fr: "Aucune description",
        en: "Keine Beschreibung",
        de: "Kein Video",
        it: "Nessuna descrizione",
    },
    ilikethis: {
        fr: "J'apprécie",
        en: "I like this",
        de: "Ich schätze",
        it: "Lo apprezzo",
    },
    idontlikethis: {
        fr: "Je n'apprécie pas",
        en: "I don't like this",
        de: "Ich weiß nicht zu schätzen wissen",
        it: "Non apprezzo",
    },
    comment: {
        fr: "Commenter",
        en: "Comment",
        de: "Kommentar",
        it: "Commento",
    },
    comments: {
        fr: "Commentaires",
        en: "Comments",
        de: "Kommentare",
        it: "Commenti",
    },
    addacomment: {
        fr: "Ajouter un commentaire",
        en: "Add a comment",
        de: "Einen Kommentar hinzufügen",
        it: "Aggiungi un commento",
    },
    emptysearchcommentssentence: {
        fr: "Aucun résultat trouvé pour",
        en: "No search results found for",
        de: "Keine Ergebnisse gefunden für",
        it: "Nessun risultato trovato per",
    },
    emptycommentssentence: {
        fr: "Il n'y a pas de commentaire. Commencez la conversation en écrivant un premier commentaire ci dessous.",
        en: "There are no comments. Start the conversation by writing a first comment below.",
        de: "Es gibt keine Kommentare. Beginnen Sie das Gespräch, indem Sie unten einen ersten Kommentar schreiben.",
        it: "Non ci sono commenti Inizia la conversazione scrivendo un primo commento qui sotto.",
    },
    toinvitetowriteacomment: {
        fr: "Ecrire un commentaire...",
        en: "Add a comment...",
        de: "Einen Kommentar hinzufügen...",
        it: "Aggiungi un commento...",
    },
    replyto: {
        fr: "Répondre à",
        en: "Reply to",
        de: "Antwort an",
        it: "Rispondi a",
    },
    reply: {
        fr: "Répondre",
        en: "Reply",
        de: "Antwort",
        it: "Rispondere",
    },
    showresource: {
        fr: "Afficher la ressource",
        en: "Show resource",
        de: "Ressource anzeigen",
        it: "Mostra risorsa",
    },
    downloadresource: {
        fr: "Télécharger la ressource",
        en: "Download resource",
        de: "Ressource herunterladen",
        it: "Scarica risorsa",
    },
    writeacomment: {
        fr: "Ecrire un commentaire",
        en: "Write a comment",
        de: "Einen Kommentar schreiben",
        it: "Scrivi un commento",
    },
    downloadfile: {
        fr: "Télécharger le fichier",
        en: "Download file",
        de: "Datei herunterladen",
        it: "Scarica il file",
    },
    editcomment: {
        fr: "Modifier le commentaire",
        en: "Edit comment",
        de: "Kommentar bearbeiten",
        it: "Modifica commento",
    },
    deletecomment: {
        fr: "Supprimer le commentaire",
        en: "Delete comment",
        de: "Kommentar löschen",
        it: "Elimina commento",
    },
    delete: {
        fr: "Supprimer",
        en: "Delete",
        de: "Löschen",
        it: "Rimuovere",
    },
    pressesctocancel: {
        fr: "Appuyer sur échap ou cliquez ici pour annuler",
        en: "Press escape or click here to cancel",
        de: "Drücken Sie esc oder klicken Sie hier, um abzubrechen",
        it: "Premi esc o clicca qui per annullare",
    },
    encodinginprogress: {
        fr: "Encodage en cours...",
        en: "Encoding in progress...",
        de: "Codierung läuft...",
        it: "Codifica in corso...",
    },
    commentdeleted: {
        fr: "Le commentaire a bien été supprimé",
        en: "The comment has been deleted",
        de: "Der Kommentar wurde gelöscht",
        it: "Il commento è stato cancellato",
    },
    sendcomment: {
        fr: "Envoyer le commentaire",
        en: "Send comment",
        de: "Kommentar senden",
        it: "Invia commento",
    },
    commentsent: {
        fr: "Le commentaire a bien été envoyé",
        en: "The comment has been sent",
        de: "Der Kommentar wurde gesendet",
        it: "Il commento è stato inviato",
    },
    sendinginprogress: {
        fr: "Envoi en cours...",
        en: "Sending in progress...",
        de: "Senden läuft...",
        it: "Invio in corso...",
    },
    updateinprogress: {
        fr: "La modification est en cours...",
        en: "The modification is in progress...",
        de: "Die Änderung ist im Gange...",
        it: "La modifica è in corso...",
    },
    updaterealized: {
        fr: "La modification a bien été effectuée",
        en: "The modification has been made",
        de: "Die Änderung wurde vorgenommen",
        it: "La modifica è stata apportata",
    },
    errortraylater: {
        fr: "Une erreur s'est produite, veuillez réessayer plus tard",
        en: "An error occurred please try again later",
        de: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut",
        it: "Si è verificato un errore, riprova più tardi",
    },
    chapters: {
        fr: "Chapitres",
        en: "Chapters",
        de: "Kapitel",
        it: "Capitoli",
    },
    informations: {
        fr: "Informations",
        en: "Informations",
        de: "Informationen",
        it: "Informazione",
    },
    descriptiontitle: {
        fr: "Description",
        en: "Description",
        de: "Beschreibung",
        it: "Descrizione",
    },
    authorizationsgranted: {
        fr: "Autorisations accordées",
        en: "Authorizations granted",
        de: "Erteilte Berechtigungen",
        it: "Autorizzazioni concesse",
    },
    authorizationssentence: {
        fr: "Les extraits vidéo collectés ont reçu l'autorisation d'être utilisés à des fins :",
        en: "The collected video clips have received permission to be used for the following purposes:",
        de: "Die gesammelten Videoclips dürfen für folgende Zwecke verwendet werden:",
        it: "I videoclip raccolti hanno ricevuto il permesso di essere utilizzati per i seguenti scopi:",
    },
    authorizationsdevsentence: {
        fr: "d'évaluation et de développement professionnel du participant",
        en: "professional development and evaluation of the participant",
        de: "berufliche Entwicklung und Bewertung des Teilnehmers",
        it: "sviluppo professionale e valutazione del partecipante",
    },
    authorizationsresearchsentence: {
        fr: "de recherche",
        en: "of research",
        de: "der Forschung",
        it: "della ricerca",
    },
    authorizationstrainingsentence: {
        fr: "de formation",
        en: "training",
        de: "Ausbildung",
        it: "formazione",
    },
    digitizedauthorization: {
        fr: "Autorisations numérisées",
        en: "Digitized authorizations",
        de: "Digitalisierte Vollmachten",
        it: "Autorizzazioni digitalizzate",
    },
    date: {
        fr: "Date",
        en: "Date",
        de: "Datum",
        it: "Data", 
    }
};

export default class Translator {
    get lang() {
        return this._lang;
    }
    set lang(lang) {
        this._lang = lang;
    }
    constructor(lang, cStrings, debug) {
        this._strings = cStrings ?? strings;
        this._lang = lang ?? "fr";
        this._debug = debug ? [] : undefined;
    }
    get(string) {
        if (this._strings[string] && this._strings[string][this._lang]) {
            return this._strings[string][this._lang];
        }
        console.warn("translator:stringnottranslated:[" + string + "]");
        if (!this._debug) return;
        this._debug.push(string);
        //console.warn(this._debug);
        return string;
    }
}
