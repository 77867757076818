import '@webcomponents/webcomponentsjs';
import 'core-js/stable';
import 'proxy-polyfill';
import Api from './lib/Api';
import Translator from './lib/Translator';
import { donwloadMedia, getMediaPlayer, getUserPreferences, isFile } from './lib/ground';
import { CallBackApi } from './lib/Types';
//import Rocket from './img/intkn_rocket_orange.gif';


export class IntknPopupUpload extends HTMLElement {

    static get observedAttributes() {
        return [
            'element-id',
        ];
    }

    _elementId: string;
    _action: string = 'getLibrary';
    _scope: [string] = ['playerLibrary'];
    _type: string = 'video';
    _isFile: string[] = [
        "calcul",
        "document",
        "pdf",
        "compression",
        "presentation",
        "graphicEditor",
        "vectorialEditor",
        "designEditor",
        "file"
    ];
    _title: string;
    _className: string;
    _element: any;
    _user = getUserPreferences();
    _translator = new Translator(this._user.language);
    _startIndex: number = 0;
    _stepIndex: number = 55;
    _loader: HTMLDivElement;
    _loading: boolean = false;
    _searching: NodeJS.Timeout;
    _selected: {
        domElement?: HTMLDivElement,
        element?: any
    } = {};

    get element() {
        return this._element;
    }

    set element(element) {
        this._element = element;
    }

    get type() {
        return this._type;
    }

    set type(type) {
        this._type = type;
    }

    get scope() {
        return this._scope;
    }

    set scope(scope: [string]) {
        this._scope = scope;
    }

    get action() {
        return this._action;
    }

    set action(action: string) {
        this._action = action;
    }

    get title() {
        return this._title;
    }

    set title(title) {
        this._title = title;
    }

    get className() {
        return this._className;
    }

    set className(className) {
        this._className = className;
    }

    constructor() {
        super();
    }

    //attributeChangedCallback(name: string, oldValue: string, newValue: string) {}
    _close() {

        var event = new CustomEvent('close-popup-upload', {
            bubbles: true,
        });
        this.dispatchEvent(event);
        if (this.parentNode) {
            this.remove();
        }
    }

    _constructLoader() {
        this._loader = document.createElement('div');
        this._loader.setAttribute('class', 'loader');
    }
    _getLibrary(startIndex?: number, search?: string) {
        //UI LOADER
        if (this._loading) return;
        if (startIndex) this._startIndex = startIndex;
        if (startIndex === 0) this._resetContainer();
        const emptysentence = this.querySelector('.emptysentence');
        if (emptysentence) emptysentence.remove();
        const myLibraryContainer = this.querySelector('.container.data');
        if (!this._loader) this._constructLoader();
        myLibraryContainer.appendChild(this._loader);

        let api = new Api(
            '/api3/object/get/',
            {
                login: this._user.login,
                key: this._user.key,
                query: {
                    action: this._action,
                    user: this._user.login,
                    subType: this._type == 'file' ? this._isFile : [this._type],
                    domain: [this._user.domain],
                    scope: this._scope,
                    //globalCount: true,
                    search: search ? {
                        query: search,
                        type: ["nameCreator", "name", "description", "creator"]
                    } : undefined,
                    order: {
                        by: "name",
                        way: "asc"
                    },
                    limit: {
                        start: this._startIndex,
                        end: this._stepIndex
                    }
                }
            },
            {
                done: {
                    action: this._contructListLibrary
                },
                empty: {
                    action: this._emptyListLibrary
                },
                error: {
                    action: (self: IntknPopupUpload, data: any) => {
                        self._loading = false;
                    }
                }
            },
            this
        );
        this._loading = true;
        api.sendRequest();
    }
    _resetContainer() {
        this.querySelector('.container.data').innerHTML = '';
        this.querySelector('button.addmedia').classList.add('disabled');
    }
    _contructListLibrary(self: IntknPopupUpload, data: any, options: any) {
        self._loading = false;
        self._loader.remove();
        //self._startIndex += data.dataSet.query.length;
        console.log(data)
        data.dataSet.query.forEach((element: any) => {
            self._constructCard(element);
        });
    }
    _constructCard(element: any) {
        const myLibraryContainer = this.querySelector('.container.data');
        const card = document.createElement('div');
        card.setAttribute('class', 'card');
        card.setAttribute('data-text', this._translator.get('selected' + this._type));
        card.innerHTML = `
            <button class="delete"></button>
            <button class="download"></button>
            <button class="maximize"></button>
            <img src="${this._getImageUrl(element)}" alt="${element.name}"/>
            <div class="title">
                <textarea readonly="true">${element.name}</textarea>
                <button class="edit"></button>
                <div class="loader none"></div>
            </div>
        `;
        const title = card.querySelector('.title');
        const button = card.querySelector('button.edit');
        const buttonDelete = card.querySelector('button.delete');
        const buttonDownload = card.querySelector('button.download');
        const buttonMaximize = card.querySelector('button.maximize');
        const textarea = card.querySelector('textarea');
        const loader = card.querySelector('.loader');
        buttonDelete.addEventListener('click', (evt) => {
            evt.stopPropagation();
            this._confirmDelete(element, card);
        });
        buttonDownload.addEventListener('click', (evt) => {
            evt.stopPropagation();
            donwloadMedia(element, this._user);
        });
        buttonMaximize.addEventListener('click', (evt) => {
            getMediaPlayer(element, this._user);
        });

        button.addEventListener('click', (evt) => {
            evt.stopPropagation();
            textarea.removeAttribute('readonly');
            loader.classList.add('none')
            title.classList.add('nobutton');
            textarea.focus();
        });
        textarea.addEventListener('click', (evt) => {
            evt.stopPropagation();
        });
        textarea.addEventListener('blur', (evt) => {
            textarea.setAttribute('readonly', 'true');
            title.classList.remove('nobutton');
            if (textarea.value.trim
                && textarea.value.trim() == '') {
                textarea.value = element.name;
            }
        });
        const callBacks: CallBackApi = {
            done: {
                action: (self, result, extraData) => {
                    loader.classList.add('none')
                }
            },
            error: {
                action: (self, dataError) => {
                    loader.classList.add('none')
                }
            },
        }
        textarea.addEventListener('change', (evt) => {
            console.log('updatename', textarea.value, element);
            if (element.name === textarea.value) {
                return;
            }
            this._sendUpdate(element, { name: textarea.value.trim() }, callBacks);
            loader.classList.remove('none')
        });
        card.addEventListener('click', (evt) => {
            evt.stopPropagation();
            this._setSelected(element, card);
        });
        myLibraryContainer.appendChild(card);
    }
    _setSelected(element: any, domElement: HTMLDivElement) {
        if (this._selected.domElement) {
            this._selected.domElement.classList.remove('selected');
        }
        this._selected.domElement = domElement;
        this._selected.element = element;
        this._selected.domElement.classList.add('selected');
        this._displayAddButton();
    }
    _displayAddButton() {
        this.querySelector('button.addmedia').classList.remove('disabled');
    }
    _getImageUrl(element: any) {

        if (element.subType != 'pdf'
            && isFile.includes(element.subType)) {
            return "/office/ressources/img/" + "intkn_" + element.subType + ".svg";
        }
        //PREVIEW
        let url = '/api2/object/download/?query=';
        let type = 'jpg';
        let source;
        let typeImg = undefined;
        if (element.extension == '.svg'
            || element.extension == '.gif') {
            type = undefined;
            source = element.extension;
        }
        if (element.subType == 'video') {
            typeImg = 'thumbnail';
        }
        var query = {
            action: "download",
            user: this._user.login,
            list: [{
                id: element.id,
                options: {
                    type: type,
                    source: source,
                    transfert: "inline",
                    resume: "on",
                    embeddedKey: element.embeddedKey,
                    size: {
                        width: 400,
                        height: 400
                    },
                    typeImg: typeImg
                }
            }]
        };
        return url + encodeURIComponent(JSON.stringify(query));
    }
    _emptyListLibrary(self: IntknPopupUpload, data: any) {
        self._loading = false;
        self._loader.remove();
        const myLibraryContainer = self.querySelector('.container.data');
        if (myLibraryContainer.hasChildNodes()) {
            return;
        }
        self._startIndex = 0;
        myLibraryContainer.innerHTML = `<div class="emptysentence">${self._translator.get('no' + self._type)}</diV>`;
    }
    _sendUpdate(element: any, values: { [key: string]: string }, callBacks: CallBackApi) {
        let api = new Api(
            '/api3/object/update/',
            {
                login: this._user.login,
                key: this._user.key,
                query: {
                    action: "update",
                    user: this._user.login,
                    dataAction: [
                        {
                            id: element.id,
                            commonAttributes: values
                        }
                    ],
                    domain: [
                        this._user.domain
                    ]
                }
            },
            callBacks,
            this
        );
        api.sendRequest();
    }
    _sendFile() {
        console.log('_sendFile')
        const input: HTMLInputElement = this.querySelector('input.uploader');
        if (input.files
            && input.files.length == 0) {
            return;
        }
        let api = new Api(
            '/api3/object/put/',
            false,
            {
                done: {
                    action: this._dispatchImport,
                },
                empty: false,
                error: false,
                progress: {
                    action: this._displayUploading,
                }
            },
            this
        );
        api.addData({
            login: this._user.login,
            key: this._user.key,
            query: {
                action: "put",
                user: this._user.login,
                type: this._type != 'file' ? this._type : undefined,
                description: this._type,
                domain: [this._user.domain],
                export: 1,
                depth: '/',
                scope: ["playerLibrary"]
            }
        });
        for (var f = 0; f < input.files.length; f++) {
            var file = input.files[f];
            api.addFile("file_" + f, file);
        }
        api.sendRequest();

    }
    _confirmDelete(element: any, domElement: HTMLDivElement) {
        const deleting = document.createElement('div');
        deleting.setAttribute('class', 'deleting');
        deleting.innerHTML = `<div class="container">
            <div class="label">${this._translator.get('deleteressource?')}</div>
            <div class="buttons">
                <button class="confirm delete">${this._translator.get('delete')}</button>
                <button class="cancel">${this._translator.get('cancel')}</button>
            </div>
        </div>`;
        deleting.querySelector('button.cancel').addEventListener('click', (evt) => {
            evt.stopPropagation();
            deleting.remove();
        });
        deleting.querySelector('button.confirm').addEventListener('click', (evt) => {
            evt.stopPropagation();
            this._sendDelete(element, domElement);
            deleting.remove();
        });
        this.appendChild(deleting);
    }
    _sendDelete(element: any, domElement: HTMLDivElement) {
        const query = {
            action: "delete",
            user: this._user.login,
            dataAction: [
                {
                    id: element.id,
                    type: 'media',
                    users: [{
                        id: this._user.login,
                        setAction: {
                            deleteShare: "true",
                            deleteLink: [
                                { id: element.id }
                            ]
                        }
                    }]
                }
            ],
            domain: [
                this._user.domain
            ]
        };
        const callBacks: any = {
            done: {
                action: (self: IntknPopupUpload, data: any, extra: any) => {
                    console.log('deleted');
                    domElement.remove();
                }
            },
            empty: false,
            error: {
                action: (self: IntknPopupUpload, dataError: any) => {
                    console.log('error', dataError);
                    let message = dataError.dataSet.message;
                    let options: { [key: string]: string } = {};
                    if (message) {
                        options.message = message;
                    }
                    console.log(options);
                }
            }
        };

        let api = new Api(
            '/api2/object/delete/',
            {
                login: this._user.login,
                key: this._user.key,
                signature: 1,
                query: query
            },
            callBacks,
            this
        );
        api.sendRequest();
    }
    _displayUploading(self: IntknPopupUpload, purcent: number) {
        let uploading = self.querySelector('.uploading');
        if (!uploading) {
            uploading = self._constructUploading();
            self.appendChild(uploading);
        }
        const progress = uploading.querySelector('progress');
        progress.value = Math.round(purcent);
        progress.setAttribute('data-label', Math.round(purcent) + '%');
        if (purcent == 100) {
            const label: HTMLDivElement = uploading.querySelector('.label');
            label.innerText = self._translator.get('encodingsetup');
            return;
        }
    }
    _constructUploading(): HTMLDivElement {
        const uploading = document.createElement('div');
        uploading.setAttribute('class', 'uploading');
        uploading.innerHTML = `<div class="container">
            <progress class="upload" max="100" data-label="80%" value="80"></progress>
            <div class="label">Importation en cours...</div>
        </div>`;
        return uploading;
    }
    _dispatchImport(self: IntknPopupUpload, data: any) {
        let uploading = self.querySelector('.uploading');
        if (uploading) uploading.remove();
        var event = new CustomEvent('intkn-popup-upload-add', {
            bubbles: true,
            detail: {
                element: data.dataSet?.query?.[0]
            }
        });
        self.dispatchEvent(event);
        self._close();
    }
    _dispatchAdd() {
        var event = new CustomEvent('intkn-popup-upload-add', {
            bubbles: true,
            detail: {
                element: this._selected.element
            }
        });
        this.dispatchEvent(event);
        this._close();
    }
    _constructTitle() {
        if (!this._title) return;
        let title: HTMLTitleElement = this.querySelector('.title');
        title.classList.remove('none');
        title.innerText = this._translator.get(this._title);
    }

    connectedCallback() {
        /*<button data-text="youtube" class="video none youtube"></button>*/
        this.innerHTML = `
            <div class="intkn-popup">
                <title class="title none"></title>
                <button class="close"></button>
                <section class="section">
                    <menu class="menu">
                        <button data-text="mylibrary" class="default my selected"></button>
                        <button data-text="youtube" class="none youtube"></button>
                        <label class="default upload">
                            ${this._translator.get('upload' + this._type + 's')}
                            <input type="file" class="uploader" accept="${this._type}/*"/>
                        </label>
                    </menu>
                    <section class="containers">
                        <div class="banner none">
                            <input class="search" placeholder="${this._translator.get('search' + this._type)}" type="text"/>
                            <div class="filter"></div>
                        </div>
                        <div class="container data"></div>
                        <div class="container youtube none">
                            <input class="text" data-text="placeholder-youtube-link"/>
                            <label data-text="explain-youtube-link" class="sublabel"></label>
                        </div>
                        <div class="button">
                            <button data-text="add" class="addmedia disabled"></button>
                        </div>
                    </section>
                </section>
               
            </div>
        `;
        this._constructTitle();
        this._getLibrary();
        this.addEventListener('keyup', (evt) => {
            evt.stopPropagation();
        });
        this.addEventListener('keydown', (evt) => {
            evt.stopPropagation();
        });
        const buttons = this.querySelectorAll('button');
        buttons.forEach(button => {
            if (!button.getAttribute('data-text')) return;
            button.innerText = this._translator.get(button.getAttribute('data-text') + this._type + 's');
            button.classList.add('none');
        });
        const buttonsDefault = this.querySelectorAll('.default');
        buttonsDefault.forEach(button => {
            button.classList.remove('none');
        });
        const buttonsType = this.querySelectorAll('.' + this._type);
        buttonsType.forEach(button => {
            button.classList.remove('none');
        });
        const labels = this.querySelectorAll('label');
        labels.forEach(label => {
            if (!label.getAttribute('data-text')) return;
            label.innerText = this._translator.get(label.getAttribute('data-text'));
        });

        const inputs: NodeListOf<HTMLInputElement> = this.querySelectorAll('input.text');
        inputs.forEach(input => {
            input.placeholder = this._translator.get(input.getAttribute('data-text'));
        });

        if (this._className) {
            this.querySelector('.intkn-popup').classList.add(this._className);
        }
        this.querySelector('.intkn-popup').addEventListener('click', (evt) => {
            evt.stopPropagation();
        });
        this.querySelector('.close').addEventListener('click', (evt) => {
            evt.stopPropagation();
            this._close();
        });
        const inputUpload = this.querySelector('input.uploader');
        inputUpload.addEventListener('input', (evt) => {
            this._sendFile();
        });
        const myLibraryBanner = this.querySelector('.banner');
        const myLibraryButton = this.querySelector('button.my');
        const youtubeButton = this.querySelector('button.youtube');
        const myLibraryContainer = this.querySelector('.container.data');
        const youtubeContainer = this.querySelector('.container.youtube');
        const addButton = this.querySelector('button.addmedia');
        const search: HTMLInputElement = this.querySelector('.search');
        myLibraryButton.addEventListener('click', (evt) => {
            evt.stopPropagation();
            youtubeButton.classList.remove('selected');
            myLibraryButton.classList.add('selected');
            youtubeContainer.classList.add('none');
            myLibraryContainer.classList.remove('none');
            myLibraryBanner.classList.remove('none');
            addButton.classList.add('disabled');
            addButton.classList.remove('none');
            search.value = '';
            this._getLibrary(0, '');
        });
        youtubeButton.addEventListener('click', (evt) => {
            evt.stopPropagation();
            myLibraryButton.classList.remove('selected');
            youtubeButton.classList.add('selected');
            myLibraryBanner.classList.add('none');
            myLibraryContainer.classList.add('none');
            youtubeContainer.classList.remove('none');
            addButton.classList.add('none');
        });

        addButton.addEventListener('click', (evt) => {
            evt.stopPropagation();
            this._dispatchAdd();
        });
        search.addEventListener('keyup', (evt) => {
            evt.stopPropagation();
            const value = search.value.trim();
            if (this._searching) clearTimeout(this._searching);
            if (value == '') {
                this._getLibrary(0);
                return;
            }
            if (value.length < 3) {
                return;
            }
            this._searching = setTimeout((evt) => {
                this._getLibrary(0, value);
            }, 950);

        });
        addButton.classList.remove('none');
        myLibraryBanner.classList.remove('none');
    }
}

customElements.define('intkn-popup-upload', IntknPopupUpload);