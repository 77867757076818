import Api from "./Api";
import { timer } from "./img/svg/comments";

const getUrlImg = function (element, user) {
    if (typeof element.id == "undefined") {
        return "/office/ressources/img/intkn_nobody.svg";
    }

    var rootQuery = "/api2/actor/download/";
    if (element.id.indexOf("@") == -1) {
        var rootQuery = "/api2/object/download/";
    }

    var width = "60";
    var height = "60";
    if (
        typeof element.size !== "undefined" &&
        typeof element.size.width !== "undefined"
    ) {
        width = element.size.width;
        height = element.size.height;
    }

    var format = "png";
    if (typeof element.format !== "undefined") {
        format = element.format;
    }

    var type = "image";
    if (typeof element.type !== "undefined") {
        type = element.type;
    }
    var query = {
        action: "download",
        user: user.login,
        list: [
            {
                id: element.id,
                options: {
                    transfert: "inline",
                    size: {
                        width: width,
                        height: height,
                    },
                    typeImg: type,
                    resume: "on",
                },
            },
        ],
    };
    if (format) {
        query.list[0].options.type = format;
    }
    if (typeof element.name !== "undefined") {
        query.list[0].options.image = element.name;
    }
    if (typeof element.image !== "undefined") {
        query.list[0].options.image = element.image;
    }
    if (
        typeof element.currentTime != "undefined" &&
        element.currentTime != ""
    ) {
        query.list[0].options.currentTime = element.currentTime;
    }
    if (typeof element.embeddedKey !== "undefined") {
        query.list[0].options.embeddedKey = element.embeddedKey;
    }
    if (typeof element.metadata != "undefined") {
        query.list[0].options.typeImg = "thumbnail";
    }

    return (
        rootQuery +
        "?query=" +
        encodeURIComponent(btoa(JSON.stringify(query))) +
        "&key=" +
        btoa(user.key) +
        "&login=" +
        btoa(user.login) +
        "&encryption=true"
    );
};
const isFile = [
    "calcul",
    "document",
    "pdf",
    "compression",
    "presentation",
    "graphicEditor",
    "vectorialEditor",
    "designEditor",
    "file",
];
const exportFromTextarea = (string) => {
    var exp1 = new RegExp("\n", "gi");
    var exp2 = new RegExp("\r", "gi");
    var exp3 = new RegExp("\t", "gi");
    return string
        .replace(exp3, "<br>")
        .replace(exp2, "<br>")
        .replace(exp1, "<br>");
};
const importToTextarea = (string) => {
    var exp1 = new RegExp("&lt;br&gt;", "gi");
    var exp2 = new RegExp("<br>", "gi");
    var exp3 = new RegExp("<br/>", "gi");
    return string.replace(exp3, "\n").replace(exp2, "\n").replace(exp1, "\n");
};

const donwloadMedia = (object, user, options, returnUrl) => {
    const query = {
        user: user.login,
        action: "download",
        list: [
            {
                id: object.id,
                options: {
                    transfert: "attachment",
                    resume: "off",
                    //type: object.subType,
                    embeddedKey: object.embeddedKey,
                    source: true, //object.metadata ? true : undefined,
                },
            },
        ],
    };
    const url =
        "/api2/object/download/" +
        "?signature=1&login=" +
        user.login +
        "&key=" +
        user.key +
        "&query=" +
        encodeURIComponent(JSON.stringify(query));
    if (returnUrl) return url;
    //console.log(url)
    const save = document.createElement("a");
    save.href = url;
    document.body.appendChild(save);
    save.click();
    document.body.removeChild(save);
};

const getMediaPlayer = (object, user, options, returnUrl) => {
    if (!object.embeddedKey) {
        return;
    }
    var embeddedKey = "&embeddedKey=" + object.embeddedKey;
    var scope = object.scope.split(",")[0];
    var url = "https://" + getSubDomain() + "." + user.domain;
    switch (object.subType) {
        case "document":
        case "calcul":
        case "presentation":
            url += "/officeplayer/?";
            url += "&lang=" + user.language + "&scope=" + scope + embeddedKey;
            if (object.export == "1") {
                url += "&action=getLibrary";
            }
            if (options && options.edit) {
                url += "&token=" + btoa(object.id + "--writer");
            } else {
                url += "&token=" + btoa(object.id + "--reader");
            }
            break;
        default:
            url += "/mediaplayer/?token=";
            var jsonToken = {
                lang: user.language,
                scope: object.scope.split(",")[0],
                embeddedKey: object.embeddedKey,
                id: object.id,
            };
            if (object.export == "1") {
                jsonToken.action = "getLibrary";
            }
            if (options && options.edit) {
                jsonToken.edition = "writer";
            } else {
                jsonToken.edition = "reader";
            }
            if (options && options.comments) {
                jsonToken.comments = options.comments;
            } else {
                jsonToken.comments = false;
            }
            if (options && options.openWindow) {
                jsonToken.openWindow = options.openWindow;
            } else {
                jsonToken.openWindow = false;
            }
            url += btoa(JSON.stringify(jsonToken));
            //activity.setActionByType(false, object, false, true);
            break;
    }

    if (returnUrl) {
        return url;
    }
    window.open(url);
};
const getSubDomain = function () {
    var url = window.location.href;
    var url_parts = url.split("/");
    var subdomains_parts = url_parts[2].split(":");
    var subdomains = subdomains_parts[0].split(".");
    var nbSubDomains = subdomains.length;

    return subdomains[0];
};
const getDomain = function () {
    var url = window.location.href;
    var url_parts = url.split("/");
    var subdomains_parts = url_parts[2].split(":");
    var subdomains = subdomains_parts[0].split(".");
    var nbSubDomains = subdomains.length;
    var domain =
        subdomains[nbSubDomains - 2] + "." + subdomains[nbSubDomains - 1];
    return domain;
};
const getPathName = function () {
    var url = window.location.pathname;
    var urlParts = url.split("/");
    //console.log(urlParts);
    var path = [];
    var nbPath = urlParts.length;
    for (var u = 0; u < nbPath; u++) {
        if (urlParts[u] !== "" && urlParts[u] !== "office") {
            path.push(urlParts[u]);
        }
    }
    return path;
};
const getUserPreferences = function () {
    let get = getValuesUrl();
    let lang = "fr";
    if (getCookie("lang") && !get.values.language) {
        lang = getCookie("lang");
    }
    var job = "-";
    if (getCookie("job")) {
        job = atob(getCookie("job"));
    }
    var bitrate = "720p";
    if (getCookie("bitrate")) {
        bitrate = getCookie("bitrate");
    }
    var organization = "-";
    if (getCookie("organization")) {
        organization = atob(getCookie("organization"));
    }
    let login = "nobody";
    if (getCookie("login")) {
        login = getCookie("login");
    }
    let key = "e3561504b2764ed07dcda513a9a7f3e0";
    if (getCookie("tokenSession")) {
        key = getCookie("tokenSession");
    }
    let publicKey = "";
    if (getCookie("tokenPublic")) {
        let publicKey = getCookie("tokenPublic");
    }
    let firstName = "nobody";
    if (getCookie("firstName")) {
        firstName = atob(getCookie("firstName"));
    }
    let name = "nobody";
    if (getCookie("name")) {
        name = atob(getCookie("name"));
    }
    let sessionId = false;
    if (getCookie("sessionId")) {
        sessionId = getCookie("sessionId");
    }
    let level = "user";
    if (getCookie("level")) {
        level = getCookie("level");
    }
    return {
        login: login,
        key: key,
        publicKey: publicKey,
        token: key,
        tokenPublic: publicKey,
        language: lang,
        firstName: firstName,
        organization: organization,
        job: job,
        bitrate: bitrate,
        sessionId: sessionId,
        name: name,
        level: level,
        domain: getDomain(),
    };
};
const setCookie = function (name, value, expires, path, domain, secure) {
    document.cookie =
        name +
        "=" +
        escape(value) +
        (expires ? "; expires=" + expires.toGMTString() : "") +
        (path ? "; path=" + path : "; path=/") +
        (domain ? "; domain=" + domain : "; domain=" + getDomain()) +
        (secure ? "; secure" : "");
};
const getCookie = function (name) {
    if (document.cookie.length == 0) {
        return false;
    }
    let cStart = document.cookie.indexOf(name + "=");
    if (cStart == -1) {
        return false;
    }
    cStart = cStart + name.length + 1;
    let cEnd = document.cookie.indexOf(";", cStart);
    if (cEnd == -1) {
        cEnd = document.cookie.length;
    }
    return unescape(document.cookie.substring(cStart, cEnd));
};
const removeCookie = function (label) {
    let dt = new Date("December 25, 1995 23:15:20");
    setCookie(label, "", dt);
};
const getValuesUrl = function () {
    if (window.location.toString().indexOf("?") === -1) {
        return {
            values: {},
            string: "",
        };
    }
    let values = {};
    let query = window.location
        .toString()
        // get the query string
        .replace(/^.*?\?/, "")
        .replace(/#.*$/, "")
        .split("&");
    for (var i = 0, l = query.length; i < l; i++) {
        let aux = decodeURIComponent(query[i]).split("=");
        values[aux[0]] = aux[1];
    }
    let string = window.location.toString().split("?");
    if (string[1].indexOf("#") !== -1) {
        let subString = string[1].split("#");
        string[1] = subString[0];
    }
    let stringValues = "?" + string[1];
    return {
        values: values,
        string: stringValues,
    };
};
const getMonthLabel = function (month) {
    switch (month) {
        case 0:
            return "january";
        case 1:
            return "february";
        case 2:
            return "march";
        case 3:
            return "april";
        case 4:
            return "may";
        case 5:
            return "june";
        case 6:
            return "july";
        case 7:
            return "august";
        case 8:
            return "september";
        case 9:
            return "october";
        case 10:
            return "november";
        case 11:
            return "december";
    }
};
const getDayLabel = function (day) {
    switch (day) {
        case 0:
            return "sunday";
        case 1:
            return "monday";
        case 2:
            return "tuesday";
        case 3:
            return "wednesday";
        case 4:
            return "thursday";
        case 5:
            return "friday";
        case 6:
            return "saturday";
    }
};
const notificationBubble = function (component, text, time) {
    let user = getUserPreferences();
    if (!time) {
        time = 2500;
    }

    /*if (getString) {
        text = getString(text, user.language)
    }*/

    var event = new CustomEvent("open-snackbar", {
        bubbles: true,
        detail: {
            closeTimeout: time,
            text: text,
        },
    });
    component.dispatchEvent(event);
};
const sortDateStartUp = function (a, b) {
    if (parseInt(a.dateStart) < parseInt(b.dateStart)) {
        return -1;
    } else {
        return 1;
    }
};
const sortTagUp = function (a, b) {
    if (a.sortTag === false || b.sortTag === false) {
        return 1;
    }
    if (a.sortTag < b.sortTag) {
        return -1;
    } else {
        return 1;
    }
};
const sortTagDown = function (a, b) {
    if (a.sortTag === false || b.sortTag === false) {
        return 1;
    }
    if (a.sortTag > b.sortTag) {
        return -1;
    } else {
        return 1;
    }
};
const sortNameUp = function (a, b) {
    if (!a.name || !b.name) {
        return 1;
    }
    if (!a.name.toLowerCase) {
        if (a.name.toString) {
            a.name = a.name.toString();
        }
    }
    if (!b.name.toLowerCase) {
        if (b.name.toString) {
            b.name = b.name.toString();
        }
    }
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
    } else {
        return 1;
    }
};
const sortNameDown = function (a, b) {
    if (!a.name || !b.name) {
        return 1;
    }
    if (!a.name.toLowerCase) {
        if (a.name.toString) {
            a.name = a.name.toString();
        }
    }
    if (!b.name.toLowerCase) {
        if (b.name.toString) {
            b.name = b.name.toString();
        }
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return -1;
    } else {
        return 1;
    }
};
const sortZindexUp = (a, b) => {
    if (a.zIndex === false || b.zIndex === false) {
        return 1;
    }
    if (a.zIndex < b.zIndex) {
        return -1;
    } else {
        return 1;
    }
};
const sortZindexDown = (a, b) => {
    if (a.zIndex === false || b.zIndex === false) {
        return 1;
    }
    if (a.zIndex > b.zIndex) {
        return -1;
    } else {
        return 1;
    }
};
const linkify = function (str) {
    let options = {
        /* … */
    };
    return linkifyHtml(str.toString(), options);
};
const convertTimecodeToString = function (str) {
    return str.toHHMMSSPoint();
};
const addTimecodeAction = function (str) {
    let regex = /\b(\d:)?(([0-5]?\d):)([0-5]?\d)\b/gi;
    /// /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/gi;
    return str.replace(regex, (match) => {
        //console.log(match);
        return `<a href=javascript:void(0); class="timecodeAction" onclick="dispatchTimeToPlayer(event)">
            ${timer}${match.trim()}
        </a>`;
    });
};
const displayDate = function (timeIn, _translator) {
    let d = new Date();
    let time = parseInt(timeIn) * 1000;
    d.setTime(time);
    let today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    let week = today.getDay();

    let minNormalize = function (min) {
        if (parseInt(min) < 10) {
            return "0" + min;
        }
        return min;
    };

    let returnMonth = function (month, _translator) {
        switch (month) {
            case 1:
                return _translator.get("january");
            case 2:
                return _translator.get("february");
            case 3:
                return _translator.get("march");
            case 4:
                return _translator.get("april");
            case 5:
                return _translator.get("may");
            case 6:
                return _translator.get("june");
            case 7:
                return _translator.get("july");
            case 8:
                return _translator.get("august");
            case 9:
                return _translator.get("september");
            case 10:
                return _translator.get("october");
            case 11:
                return _translator.get("november");
            case 12:
                return _translator.get("december");
        }
    };

    if (
        d.getDate() === day &&
        d.getMonth() + 1 === month &&
        d.getFullYear() === year
    ) {
        return d.getHours() + "h" + minNormalize(d.getMinutes());
    }
    if (d.getMonth() + 1 === month && d.getFullYear() === year) {
        return (
            d.getDate() +
            " " +
            returnMonth(parseInt(d.getMonth() + 1), _translator)
        );
    }
    return (
        d.getDate() + "/" + parseInt(d.getMonth() + 1) + "/" + d.getFullYear()
    );
};
const setUrlImgByType = function (actor, user) {
    if (typeof actor.id == "undefined") {
        return "/office/ressources/img/intkn_nobody.svg";
    }
    let rootQuery = "/api2/actor/download/";
    if (actor.id.indexOf("@") == -1) {
        rootQuery = "/api2/object/download/";
        //rootQuery = getRootExtraImg(rootQuery);
    } else {
        //rootQuery = getRootUsersImg(rootQuery);
    }
    let width = "60";
    let height = "60";
    if (
        typeof actor.size !== "undefined" &&
        typeof actor.size.width !== "undefined"
    ) {
        width = actor.size.width;
        height = actor.size.height;
    }
    let typeExport = "png";
    if (typeof actor.typeExport !== "undefined") {
        typeExport = actor.typeExport;
    }
    let typeImg = "image";
    if (typeof actor.typeImg !== "undefined") {
        typeImg = actor.typeImg;
    }
    let query = {
        action: "download",
        user: user.login,
        list: [
            {
                id: actor.id,
                options: {
                    transfert: "inline",
                    size: {
                        width: width,
                        height: height,
                    },
                    typeImg: typeImg,
                    resume: "on",
                },
            },
        ],
    };
    if (typeExport) {
        query.list[0].options.type = typeExport;
    }
    if (typeof actor.name !== "undefined") {
        query.list[0].options.image = actor.name;
    }
    if (typeof actor.image !== "undefined") {
        query.list[0].options.image = actor.image;
    }
    if (typeof actor.currentTime != "undefined" && actor.currentTime != "") {
        query.list[0].options.currentTime = actor.currentTime;
    }
    if (typeof actor.embeddedKey !== "undefined") {
        query.list[0].options.embeddedKey = actor.embeddedKey;
    }
    if (typeof actor.metadata != "undefined") {
        query.list[0].options.typeImg = "thumbnail";
    }
    return rootQuery + "?query=" + encodeURIComponent(JSON.stringify(query));
};
const closeCurrentPopup = function (popup) {
    popup._close();
};
const removeCurrentPopup = function (popup) {
    popup.remove();
};
const createPopupLogin = function (src) {
    let value;
    if (src.element) {
        value = src.element;
    }
    let event = new CustomEvent("open-login-popup", {
        bubbles: true,
        detail: {
            element: value,
        },
    });
    src.dispatchEvent(event);
    /*let dataId = new Date().getTime();
    let popupLogin = document.createElement('intkn-popup');
    document.body.appendChild(popupLogin);
    popupLogin.setAttribute('data-id', dataId);
    popupLogin.setAttribute('display-title','tologin');
    popupLogin.formStructure = loginFormStructure;*/
};
const createPopupSignup = function (src) {
    let value;
    if (src.element) {
        value = src.element;
    }
    let event = new CustomEvent("open-signup-popup", {
        bubbles: true,
        detail: {
            element: value,
        },
    });
    src.dispatchEvent(event);
    /*let dataId = new Date().getTime();
    let popupLogin = document.createElement('intkn-popup');
    document.body.appendChild(popupLogin);
    popupLogin.setAttribute('data-id', dataId);
    popupLogin.setAttribute('display-title','tologin');
    popupLogin.formStructure = signupFormStructure;*/
};
const ucFirst = function (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
const uniqueId = function () {
    var d = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == "x" ? r : (r & 0x7) | 0x8).toString(16);
        }
    );
    return uuid;
};
const debounce = function (func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};
const filterObject = function (obj, fn) {
    return Object.fromEntries(Object.entries(obj).filter(fn));
};
//MUST UPDATE AS GROUND.JS internal and inknplayer!!!!!!

const getNewSessionCourseSet = function () {
    return {
        id: uniqueId(),
        position: {
            index: {
                current: 0,
                finished: 0,
                fraction: "0 / 1",
                inProgress: 0,
                inProgressPurcent: 0,
                nbModules: 1,
                notStarted: 0,
                purcent: 0,
            },
        },
        progress: {
            courses: {},
        },
        time: 0,
        lastAccess: parseInt(new Date().getTime() / 1000),
        dateStart: 0,
        dateEnd: 0,
    };
};

const getNewSessionCourse = function (dateStart, dateEnd) {
    return {
        id: uniqueId(),
        position: {
            index: {
                current: 0,
                finished: 0,
                fraction: "0 / 1",
                inProgress: 0,
                inProgressPurcent: 0,
                nbModules: 1,
                notStarted: 0,
                purcent: 0,
            },
        },
        progress: {
            modules: {},
        },
        time: 0,
        lastAccess: Math.round(new Date().getTime() / 1000),
        dateStart: dateStart,
        dateEnd: dateEnd,
    };
};

const Streamer = {};
const addToStreamer = function (object) {
    Streamer[object.id] = object;
};

const computeProgressCourset = function (
    rootObject,
    after,
    _userObject,
    _translator
) {
    console.log("computeProgressCourset");
    if (Streamer[rootObject.id]) {
        console.log("alreadyinstreamer");
        rootObject = Streamer[rootObject.id];
    }

    if (!rootObject.child) {
        if (after) {
            console.log("computeProgressCourset", "nochild");
            return;
        }
        console.log("getcourseset");
        let api = new Api(
            "/api3/object/get/",
            {
                login: _userObject.login,
                key: _userObject.token,
                query: {
                    action: "getLibrary",
                    user: _userObject.login,
                    domain: [_userObject.domain],
                    list: [{ id: rootObject.id }],
                    scope: [
                        "courses",
                        "library",
                        "spaces",
                        "groups",
                        "playerLibrary",
                    ],
                    projection: [
                        "nbChild",
                        { name: "child", options: { session: true } },
                        "session",
                        "creatorInfos",
                    ],
                    limit: { start: 0, end: 1 },
                },
            },
            {
                done: {
                    action: (self, data) => {
                        if (!data?.dataSet?.query) {
                            return;
                        }
                        addToStreamer(data.dataSet.query[0]);
                        const courseSet = Streamer[data.dataSet.query[0].id];
                        self(courseSet, true, _userObject, _translator);
                    },
                },
                empty: false,
                error: {
                    action: (evt) =>
                        console.log("errorresumecoursetgethild", evt),
                },
                progress: false,
            },
            computeProgressCourset
        );
        api.sendRequest();
        return;
    }
    const currentTime = parseInt(new Date().getTime() / 1000);
    let notStarted = 0;
    let inProgress = 0;
    let totalCourses = 0;
    let position = 0;
    let done = 0;
    let sessionScore;
    let time = 0;
    let totalPurcent = 0;
    var dateStart = Math.round(new Date().getTime() / 1000);
    var dateEnd = rootObject.sessionDuration
        ? Math.round(
              (new Date().getTime() +
                  parseInt(rootObject.sessionDuration) * 1000) /
                  1000
          )
        : 0;

    if (rootObject.session?.tokenValidate || rootObject.session?.dateValidate) {
        console.log("sessionfinished");
        return rootObject.session;
    }

    let sessionsData = getNewSessionCourseSet();

    const oldPurcent = rootObject.session?.position?.index?.purcent ?? 0;
    const oldFraction =
        rootObject.session?.position?.index?.fraction ?? undefined;
    const oldTotalPurcent =
        rootObject.session?.position?.index?.totalPurcent ?? undefined;
    const oldPurcentScore = rootObject.session?.score?.purcent ?? undefined;
    const oldTotalPurcentScore =
        rootObject.session?.score?.totalPurcent ?? undefined;

    if (rootObject.session?.id) {
        sessionsData.id = rootObject.session.id;
        sessionsData.dateStart = rootObject.session.dateStart;
        sessionsData.dateEnd = rootObject.session.dateEnd;
    } else {
        sessionsData.dateStart = dateStart;
        sessionsData.dateEnd = dateEnd;
    }

    let courses = rootObject.child;
    courses.sort(sortNameUp);
    if (courses?.length > 0) {
        for (let c = 0; c < courses.length; c++) {
            let course = courses[c];
            if (!course) {
                console.log("nochildcourseset");
                continue;
            }
            if (
                course.visibility === "hide" ||
                course.visibility === "invisible"
            ) {
                continue;
            }
            totalCourses++;
            const dateStartC = Math.round(new Date().getTime() / 1000);
            const dateEndC = course.sessionDuration
                ? Math.round(
                      (new Date().getTime() +
                          parseInt(course.sessionDuration) * 1000) /
                          1000
                  )
                : 0;
            sessionsData.progress.courses[course.id] = course.session
                ? course.session
                : getNewSessionCourse(dateStartC, dateEndC);
            time += sessionsData.progress.courses[course.id].time;
            sessionsData.time = time;
            //(sessionsData.progress.courses[course.id].position.index.finished > 0
            //	&& sessionsData.progress.courses[course.id].position.index.finished == sessionsData.progress.courses[course.id].position.index.nbModules
            //	&& (course.scoreLimit == 0 || course.scoreLimit <= sessionsData.progress.courses[course.id].score?.purcent))
            //	||

            if (course.scoreLimit) {
                if (!sessionScore) {
                    sessionScore = {
                        purcent: 0,
                        totalPurcent: 0,
                        nbScored: 0,
                        details: {},
                        nbModule: 1,
                    };
                } else {
                    sessionScore.nbModule++;
                }
            }
            if (sessionsData.progress.courses[course.id].score) {
                let score = sessionsData.progress.courses[course.id].score;
                if (!sessionScore) {
                    sessionScore = {
                        purcent: 0,
                        totalPurcent: 0,
                        nbScored: 0,
                        details: {},
                        nbModule: 0,
                    };
                }
                sessionScore.totalPurcent += parseFloat(score.purcent) ?? 0;
                sessionScore.nbScored++;
                sessionScore.details[course.id] = score;
            }
            if (
                sessionsData.progress.courses[course.id].tokenValidate ||
                sessionsData.progress.courses[course.id].dateValidate
            ) {
                position++;
                done++;
                totalPurcent += 100;
                sessionsData.last = {
                    course: {
                        id: course.id,
                        name: course.name,
                    },
                    progress:
                        sessionsData.progress.courses[course.id].position.index
                            .purcent,
                };
                if (sessionsData.progress.courses[course.id].last.module) {
                    sessionsData.last.module =
                        sessionsData.progress.courses[course.id].last.module;
                }
                if (sessionsData.progress.courses[course.id].last.slide) {
                    sessionsData.last.slide =
                        sessionsData.progress.courses[course.id].last.slide;
                }
                sessionsData.position.index.finished = done;
                continue;
            }
            if (
                sessionsData.progress.courses[course.id].position.index
                    .inProgress > 0 ||
                sessionsData.progress.courses[course.id].position.index
                    .finished > 0
            ) {
                //position++;
                inProgress++;
                totalPurcent +=
                    sessionsData.progress.courses[course.id].position.index
                        .purcent;
                sessionsData.last = {
                    course: {
                        id: course.id,
                        name: course.name,
                    },
                    progress:
                        sessionsData.progress.courses[course.id].position.index
                            .purcent,
                };
                if (sessionsData.progress.courses[course.id].last.module) {
                    sessionsData.last.module =
                        sessionsData.progress.courses[course.id].last.module;
                }
                if (sessionsData.progress.courses[course.id].last.slide) {
                    sessionsData.last.slide =
                        sessionsData.progress.courses[course.id].last.slide;
                }
                sessionsData.position.index.inProgress = inProgress;
                sessionsData.position.index.inProgressPurcent =
                    sessionsData.progress.courses[
                        course.id
                    ].position.index.purcent;
                continue;
            }
            notStarted++;
            sessionsData.position.index.notStarted = notStarted;
        }
    }

    //console.log(sessionsData.last);
    if (sessionScore) {
        sessionScore.purcent =
            sessionScore.totalPurcent / sessionScore.nbScored;
        sessionsData.score = sessionScore;
    } else if (sessionsData.score) {
        delete sessionsData.score;
    }

    if (rootObject.session?.progress?.modules) {
        let modules = rootObject.session?.progress?.modules;
        let access = false;
        for (const m in modules) {
            if (
                modules[m.lastAccess] &&
                access < parseInt(modules[m.lastAccess])
            ) {
                access = parseInt(modules[m.lastAccess]);
            }
        }
        if (access) {
            rootObject.session.lastAccess = access;
        }
    }
    sessionsData.lastAccess = currentTime;
    sessionsData.position.index.nbModules = totalCourses ?? 0;
    sessionsData.position.index.purcent = totalCourses
        ? Math.round(totalPurcent / totalCourses)
        : 0;
    sessionsData.position.index.fraction = position + "/" + totalCourses;
    sessionsData.position.index.current = position;
    sessionsData.position.index.totalPurcent = totalPurcent;

    if (
        totalCourses &&
        sessionsData.position.index.finished == totalCourses &&
        !sessionsData.tokenValidate
    ) {
        sessionsData.tokenValidate = uniqueId();
        sessionsData.dateValidate = currentTime;
    }
    const mustbesend =
        sessionsData.position.index.purcent != oldPurcent ||
        sessionsData.position.index.fraction != oldFraction ||
        sessionsData.position.index.totalPurcent != oldTotalPurcent ||
        sessionsData.score?.purcent != oldPurcentScore ||
        sessionsData.score?.totalPurcent != oldTotalPurcentScore ||
        sessionsData.dateValidate == currentTime;

    console.log(oldPurcent, sessionsData.position.index.purcent);

    if (!mustbesend) {
        console.log("alreadysendsendsessioncourseset");
    } else {
        console.log("sendsessioncourseset");
        rootObject.session = sessionsData;
        bounceProgressCourseSet(rootObject, _userObject, _translator);
    }
    return sessionsData;
};
let timerProgressCourseSet;
//debounce
const bounceProgressCourseSet = function (
    rootObject,
    _userObject,
    _translator
) {
    clearTimeout(timerProgressCourseSet);
    console.log("bounceProgressCourseSet");
    timerProgressCourseSet = setTimeout(() => {
        console.log("debounceProgressCourseSet");
        sendProgressCourseSet(rootObject, _userObject, _translator);
    }, 500);
};

const sendProgressCourseSet = function (rootObject, _userObject, _translator) {
    console.log("sendProgressCourseSet");
    let api = new Api(
        "/api3/object/update/",
        {
            login: _userObject.login,
            key: _userObject.token,
            query: {
                action: "update",
                user: _userObject.login,
                domain: [_userObject.domain],
                dataAction: [
                    {
                        id: rootObject.id,
                        type: rootObject.subType,
                        name: rootObject.name,
                        embeddedKey: rootObject.embeddedKey,
                        users: [
                            {
                                id: _userObject.login,
                                setAction:
                                    rootObject.session.tokenValidate &&
                                    !rootObject.session.endOfCourseMail?.user
                                        ? {
                                              endOfSession: rootObject.session,
                                          }
                                        : {
                                              session: rootObject.session,
                                          },
                            },
                        ],
                    },
                ],
            },
        },
        {
            done: {
                action: (self, data) => {
                    if (!data?.dataSet?.query) {
                        return;
                    }
                    if (
                        rootObject.session.tokenValidate &&
                        rootObject.typeEnd == "attestation"
                    ) {
                        bounceEndCourseSet(
                            rootObject,
                            "user",
                            _userObject,
                            _translator
                        );
                    }
                    console.log("sessioncoursesetsaved");
                },
            },
            empty: false,
            error: {
                action: (evt) => console.log("errorsessioncoursesetsave", evt),
            },
            progress: false,
        },
        false
    );
    api.sendRequest();
};

let timerEndCourseSet;
//debounce
const bounceEndCourseSet = function (
    courseSet,
    type,
    _userObject,
    _translator
) {
    clearTimeout(timerEndCourseSet);
    console.log("bounceEndCourseSet");
    timerEndCourseSet = setTimeout(() => {
        console.log("debounceEndCourseSet");
        sendEndOfCourseSet(courseSet, type, _userObject, _translator);
    }, 500);
};

function sendEndOfCourseSet(courseSet, type, _userObject, _translator) {
    console.log("sendEndOfCourseSet");
    let toAdmin;
    if (type == "admin") {
        const idAdmin = courseSet.idAdmin
            ? courseSet.idAdmin
            : courseSet.idCreator;
        //SEND MAIL END COURSE
        toAdmin = [{ id: "contact@workstreams.ch" }, { id: idAdmin }];
    }
    const url = getAttestationUrl("postSession", courseSet, _userObject);
    if (!courseSet.session.endOfCourseMail) {
        courseSet.session.endOfCourseMail = {};
    }
    if (courseSet.session.endOfCourseMail?.[type]) {
        console.log("endOfCourseSetMail:alreadysend");
        return;
    }

    courseSet.session.endOfCourseMail[type] = {
        time: parseInt(new Date().getTime() / 1000),
    };

    let api = new Api(
        "/api2/mail/put/",
        {
            login: _userObject.login,
            key: _userObject.token,
            query: {
                action: "sendMail",
                user: _userObject.login,
                replyTo: "contact@workstreams.ch",
                dataAction: [
                    {
                        id: courseSet.id,
                        type: "customMail",
                        rootObject: courseSet.id,
                        replaceData: {
                            nameUser:
                                _userObject.firstName + " " + _userObject.name,
                            emailUser: _userObject.login,
                            nameCourse: courseSet.name,
                            organizationUser: _userObject.organization,
                            functionUser: _userObject.job,
                            tokenValidate: courseSet.session.tokenValidate,
                        },
                        email: toAdmin
                            ? toAdmin
                            : [
                                  {
                                      id: _userObject.login,
                                  },
                              ],
                        message:
                            type == "user"
                                ? _translator
                                      .get("endOfCourseMailUser")
                                      .replace(/{{attestationUrl}}/g, url)
                                : _translator
                                      .get("endOfCourseMailAdmin")
                                      .replace(/{{attestationUrl}}/g, url),
                        title:
                            type == "user"
                                ? _translator.get("endOfCourseTitleMailUser")
                                : _translator.get("endOfCourseTitleMailAdmin"),
                    },
                ],
                domain: [_userObject.domain],
            },
        },
        {
            done: {
                action: (self, data) => {
                    if (!data?.dataSet?.query) {
                        return;
                    }
                    console.log("sendEndOfCourseDone");
                },
            },
            empty: false,
            error: {
                action: (evt) => console.log("errorendofcoursemail", evt),
            },
            progress: false,
        },
        false
    );
    api.sendRequest();

    if (type == "user") {
        sendEndOfCourseSet(courseSet, "admin", _userObject);
    }
}

const getAttestationUrl = function (subType, course, _userObject) {
    var url =
        location.origin +
        "/api2/object/download/?signature=1&login=" +
        btoa(_userObject.login) +
        "&key=" +
        btoa(_userObject.token) +
        "&encryption=true&query=";

    var query = {
        action: "getAttestation",
        user: _userObject.login,
        type: subType,
        list: [
            {
                id: course.id,
            },
        ],
        sessionId: course.session.id,
        tokenValidate: course.session.tokenValidate ?? undefined,
        transfert: "inline",
        resume: "off",
        lang: _userObject.language,
        company: _userObject.organization?.replace(/’/gi, "'"),
    };
    url += encodeURIComponent(btoa(JSON.stringify(query)));
    return url;
};

const computeProgressCourse = function (
    rootObject,
    after,
    _userObject,
    _translator
) {
    console.log("computeProgressCourse");
    if (Streamer[rootObject.id]) {
        console.log("alreadyinstreamer");
        rootObject = Streamer[rootObject.id];
    }
    if (rootObject.subType === "articulate") rootObject.child = [rootObject];
    if (!rootObject.child) {
        if (after) {
            warn.log("computeProgressCourse", "nochilds");
            return;
        }
        let api = new Api(
            "/api3/object/get/",
            {
                login: _userObject.login,
                key: _userObject.token,
                query: {
                    action: "getLibrary",
                    user: _userObject.login,
                    domain: [_userObject.domain],
                    list: [{ id: rootObject.id }],
                    session: true,
                    scope: [
                        "courses",
                        "trajectory",
                        "spaces",
                        "groups",
                        "topteam",
                        "topmanager",
                        "playerLibrary",
                    ],
                    projection: [
                        "softShared",
                        "creatorInfos",
                        "session",
                        "tags",
                        "child",
                        "parent",
                        "path",
                    ],
                    limit: { start: 0, end: 1 },
                },
            },
            {
                done: {
                    action: (self, data) => {
                        if (!data?.dataSet?.query) {
                            return;
                        }
                        addToStreamer(data.dataSet.query[0]);
                        const course = Streamer[data.dataSet.query[0].id];
                        course.session = rootObject.session ?? course.session;
                        /* course.session =  */
                        self(course, true, _userObject, _translator);
                    },
                },
                empty: false,
                error: {
                    action: (evt) =>
                        console.log("errorresumecoursegetchild", evt),
                },
                progress: false,
            },
            computeProgressCourse
        );
        api.sendRequest();
        return;
    }

    if (!rootObject.session?.progress) {
        console.log("noprogressorcomputeProgressCourse");
        return rootObject.session;
    }

    /*if (rootObject.session?.tokenValidate
		|| rootObject.session?.dateValidate) {
		console.log('coursesessionfinished');
		return rootObject.session;
	}*/
    const currentTime = parseInt(new Date().getTime() / 1000);
    let totalPurcent = 0;
    let position = 0;
    let positionMax = 0;
    let score = 0;
    let nbModuleScore = 0;
    let nbModuleScored = 0;
    let modulesLoaded = false;
    let finished = 0;
    let scoreFinished = 0;
    let inprogress = 0;
    let inProgressPurcent = 0;
    const listScored = [];
    const session = rootObject.session;
    const listOfSequence = [
        "bloc",
        "questionnaire",
        "articulate",
        "test",
        "module",
    ];
    const listOfVisibility = ["invisible", "hide"];

    const oldPurcent = rootObject.session.position.index.purcent;
    const oldTotalPurcent = rootObject.session.score?.totalPurcent ?? 0;
    const oldFraction = rootObject.session.position.index.fraction;
    const oldPurcentScore = rootObject.session.score?.purcent;
    const oldTotalPurcentScore = rootObject.session.score?.totalPurcent;
    const oldCurrent = rootObject.session.position.index.current;
    const oldInProgressPurcent =
        rootObject.session.position.index.inProgressPurcent;
    setSortChilds(false, false, rootObject);
    const childs = rootObject.child;
    const nbModule = rootObject.child.length;
    //console.log(rootObject.session.last)
    for (let m = 0; m < nbModule; m++) {
        if (
            listOfSequence.indexOf(childs[m].subType) === -1 ||
            listOfVisibility.indexOf(childs[m].visibility) !== -1
        ) {
            continue;
        }
        let currentModuleFinished = false;
        modulesLoaded = true;
        positionMax++;
        if (!session.progress?.modules?.[childs[m].id]) {
            continue;
        }

        if (
            childs[m].scoreLimit ||
            session.progress.modules[childs[m].id].score?.index?.limit
        ) {
            nbModuleScore++;
        }
        if (session.progress.modules[childs[m].id].score?.index?.purcent) {
            const moduleScore = parseFloat(
                session.progress.modules[childs[m].id].score.index.purcent
            );

            const detailModuleScore = {
                id: childs[m].id,
                name: childs[m].name,
                scoreLimit: childs[m].scoreLimit
                    ? childs[m].scoreLimit
                    : session.progress.modules[childs[m].id].score?.index?.limit
                    ? session.progress.modules[childs[m].id].score?.index?.limit
                    : undefined,
                score: moduleScore,
                //MAX SCORE ???
            };
            if (childs[m].scoreLimit) {
                score += moduleScore;
                nbModuleScored++;
                if (
                    childs[m].scoreLimit <=
                    parseFloat(
                        session.progress.modules[childs[m].id].score.index
                            .purcent
                    )
                ) {
                    scoreFinished++;
                    currentModuleFinished = true;
                    detailModuleScore.validated = true;
                }
            }
            listScored.push(detailModuleScore);
        }
        if (
            session.progress.modules[childs[m].id].position.index.purcent ==
                100 ||
            currentModuleFinished
        ) {
            //MAYBE move here position++; ??
            if (!childs[m].scoreLimit || currentModuleFinished) {
                position++;
                finished++;
                totalPurcent += 100;
            } else {
                inprogress++;
                inProgressPurcent += parseFloat(
                    session.progress.modules[childs[m].id].position.index
                        .purcent
                );
                totalPurcent += parseFloat(
                    session.progress.modules[childs[m].id].position.index
                        .purcent
                );
            }
        } else if (
            session.progress.modules[childs[m].id].position.index.purcent > 0 ||
            session.progress.modules[childs[m].id].time
        ) {
            inprogress++;
            inProgressPurcent +=
                parseFloat(
                    session.progress.modules[childs[m].id].position.index
                        .purcent
                ) ?? 0;
            totalPurcent +=
                parseFloat(
                    session.progress.modules[childs[m].id].position.index
                        .purcent
                ) ?? 0;
        }
    }

    if (!modulesLoaded) {
        //CAN NOT COMPUT WITH CURRENT VALUES
        console.log("nothingforcompute");
        return rootObject.session;
    }

    //_this.getDataObject(rootObject).session.position.index.purcent = Math.round(position / positionMax * 100);
    session.position.index.purcent = totalPurcent
        ? Math.round(totalPurcent / positionMax)
        : 0;
    session.position.index.current = position;
    session.position.index.totalPurcent = totalPurcent;
    session.position.index.nbModules = positionMax;
    session.position.index.fraction = position + " / " + positionMax;
    session.position.index.finished = finished;
    session.position.index.inProgress = inprogress;
    session.position.index.inProgressPurcent = inProgressPurcent;
    session.position.index.notStarted = positionMax - inprogress - finished;
    let purcentScore = 0;
    if (nbModuleScore > 0) {
        purcentScore = Math.round(score / nbModuleScore);
        session.score = {
            purcent: purcentScore,
            totalPurcent: score,
            nbScored: nbModuleScored,
            nbModule: nbModuleScore,
            finished: scoreFinished,
            details: listScored,
        };
    }

    if (
        session.position?.index?.nbModules &&
        session.position.index.finished == session.position.index.nbModules &&
        !session.tokenValidate
    ) {
        session.tokenValidate = uniqueId();
        session.dateValidate = currentTime;
    }

    const mustbesend =
        session.position.index.purcent != oldPurcent ||
        session.position.index.totalPurcent != oldTotalPurcent ||
        session.position.index.fraction != oldFraction ||
        session.position.index.current != oldCurrent ||
        session.position.index.inProgressPurcent != oldInProgressPurcent ||
        session.score?.purcent != oldPurcentScore ||
        session.score?.totalPurcent != oldTotalPurcentScore ||
        session.dateValidate == currentTime;

    //console.log(session)
    rootObject.session = session;
    //TRY TO UPDATE COURSESET
    updateCourseSet(rootObject, _userObject, _translator);

    if (!mustbesend) {
        console.log("alreadysendsessioncourse");
    } else {
        console.log("sendsessioncourse");
        bounceProgressCourse(rootObject, _userObject, _translator);
    }
    return session;
};

let timerProgressCourse;
//debounce
const bounceProgressCourse = function (rootObject, _userObject, _translator) {
    clearTimeout(timerProgressCourse);
    console.log("bounceProgressCourse");
    timerProgressCourse = setTimeout(() => {
        console.log("debounceProgressCourse");
        sendProgressCourse(rootObject, _userObject, _translator);
    }, 500);
};

const sendProgressCourse = function (rootObject, _userObject, _translator) {
    console.log("sendProgressCourse");
    let api = new Api(
        "/api3/object/update/",
        {
            login: _userObject.login,
            key: _userObject.token,
            query: {
                action: "update",
                user: _userObject.login,
                domain: [_userObject.domain],
                dataAction: [
                    {
                        id: rootObject.id,
                        type: rootObject.subType,
                        name: rootObject.name,
                        embeddedKey: rootObject.embeddedKey,
                        users: [
                            {
                                id: _userObject.login,
                                setAction:
                                    rootObject.session.tokenValidate &&
                                    !rootObject.session.endOfCourseMail?.user
                                        ? {
                                              endOfSession: rootObject.session,
                                          }
                                        : {
                                              session: rootObject.session,
                                          },
                            },
                        ],
                    },
                ],
            },
        },
        {
            done: {
                action: (self, data) => {
                    if (!data?.dataSet?.query) {
                        return;
                    }
                    if (
                        rootObject.session.tokenValidate &&
                        rootObject.typeEnd == "attestation"
                    ) {
                        bounceEndCourse(
                            rootObject,
                            "user",
                            _userObject,
                            _translator
                        );
                    }
                    console.log("sessioncoursesaved");
                },
            },
            empty: false,
            error: {
                action: (evt) => console.log("errorsessioncoursesave", evt),
            },
            progress: false,
        },
        false
    );
    api.sendRequest();
};
let timerEndCourse;
//debounce
const bounceEndCourse = function (course, type, _userObject, _translator) {
    clearTimeout(timerEndCourse);
    console.log("bounceEndCourse");
    timerEndCourse = setTimeout(() => {
        console.log("debounceEndCourse");
        sendEndOfCourse(course, type, _userObject, _translator);
    }, 500);
};
function sendEndOfCourse(course, type, _userObject, _translator) {
    console.log("sendEndOfcourse");
    let toAdmin;
    if (type == "admin") {
        const idAdmin = course.idAdmin ? course.idAdmin : course.idCreator;
        //SEND MAIL END COURSE
        toAdmin = [{ id: "contact@workstreams.ch" }, { id: idAdmin }];
    }
    const url = getAttestationUrl("postSession", course, _userObject);
    if (!course.session.endOfCourseMail) {
        course.session.endOfCourseMail = {};
    }
    if (course.session.endOfCourseMail?.[type]) {
        console.log("endOfcourseMail:alreadysend");
        return;
    }

    course.session.endOfCourseMail[type] = {
        time: parseInt(new Date().getTime() / 1000),
    };

    let api = new Api(
        "/api2/mail/put/",
        {
            login: _userObject.login,
            key: _userObject.token,
            query: {
                action: "sendMail",
                user: _userObject.login,
                replyTo: "contact@workstreams.ch",
                dataAction: [
                    {
                        id: course.id,
                        type: "customMail",
                        rootObject: course.id,
                        replaceData: {
                            nameUser:
                                _userObject.firstName + " " + _userObject.name,
                            emailUser: _userObject.login,
                            nameCourse: course.name,
                            organizationUser: _userObject.organization,
                            functionUser: _userObject.job,
                            tokenValidate: course.session.tokenValidate,
                        },
                        email: toAdmin
                            ? toAdmin
                            : [
                                  {
                                      id: _userObject.login,
                                  },
                              ],
                        message:
                            type == "user"
                                ? _translator
                                      .get("endOfCourseMailUser")
                                      .replace(/{{attestationUrl}}/g, url)
                                : _translator
                                      .get("endOfCourseMailAdmin")
                                      .replace(/{{attestationUrl}}/g, url),
                        title:
                            type == "user"
                                ? _translator.get("endOfCourseTitleMailUser")
                                : _translator.get("endOfCourseTitleMailAdmin"),
                    },
                ],
                domain: [_userObject.domain],
            },
        },
        {
            done: {
                action: (self, data) => {
                    if (!data?.dataSet?.query) {
                        return;
                    }
                    console.log("sendEndOfCourseDone");
                },
            },
            empty: false,
            error: {
                action: (evt) => console.log("errorendofcoursemail", evt),
            },
            progress: false,
        },
        false
    );
    api.sendRequest();

    if (type == "user") {
        sendEndOfCourse(course, "admin", _userObject, _translator);
    }
}
const updateCourseSet = function (course, _userObject, _translator) {
    const courseSet = course.parent?.filter(
        (parent) => parent.extension.toLowerCase().indexOf("courseset") !== -1
    )?.[0];
    if (!courseSet) {
        return;
    }
    const refCourseSet = Streamer[courseSet.id];
    if (refCourseSet?.child) {
        console.log("updatechildcourseset");
        refCourseSet.child
            .filter((child) => child.id === course.id)
            .map((child) => (child.session = course.session));
    }
    computeProgressCourset(courseSet, false, _userObject, _translator);
};

const computeProgressModule = function (
    rootObject,
    parentObject,
    forceEnd,
    _translator
) {
    console.log("computeprogressmodule");
    if (!parentObject) {
        console.log("noparentforcomputeprogressmodule");
        return;
    }
    if (!rootObject?.session) {
        console.log("nosessionforcomputeprogressmodule");
        return;
    }
    const session = rootObject.session;
    if (!session?.progress?.modules?.[parentObject.id]) {
        return {
            progress: {
                purcent: 0,
                label: "0% " + _translator.get("accomplished"),
                explorationRate: 0,
            },
            session: session,
        };
    }

    let explorationRate = 0;
    let purcentProgress = 0;
    //if (!session.progress.modules[parentObject.id].progressEnd) {
    if (forceEnd) {
        session.progress.modules[parentObject.id].progressEnd = true;
    }
    let position = 0;
    let nbSlides = 0;
    let finished = 0;
    let started = 0;
    let notStarted = 0;
    setSortChilds(false, false, parentObject);
    const childs = Object.assign([], parentObject.child);

    if (rootObject.extension.indexOf(".formation") != -1) {
        childs.unshift(parentObject);
    }
    const nbChild = childs?.length;
    if (childs) {
        //NEED BECAUSE STRUCTURE CAN CHANGE WHEN CONCEPTOR ADD OR DELETE SLIDE
        for (let c = 0; c < nbChild; c++) {
            const child = childs[c];
            if (child.display == "hidden") {
                continue;
            }
            nbSlides++;
            const isCurrent =
                child.id ==
                session.progress.modules[parentObject.id].position.index?.last;
            if (isCurrent) {
                position = c;
            }
            if (
                session.progress.modules[parentObject.id].position?.details?.[
                    child.id
                ]?.end ||
                (c == nbChild - 1 && isCurrent)
            ) {
                //console.log('norealised');
                finished++;
            } else {
                if (
                    session.progress.modules[parentObject.id].position
                        ?.details?.[child.id]
                ) {
                    started++;
                } else {
                    notStarted++;
                }
            }
        }

        //CAN UPDATE SESSION
        session.progress.modules[parentObject.id].position.index.purcent =
            session.progress.modules[parentObject.id].progressEnd
                ? 100
                : Math.round((finished / nbSlides) * 100);
        session.progress.modules[parentObject.id].position.index.fraction =
            position + " / " + nbSlides;
        session.progress.modules[parentObject.id].position.index.nbSlides =
            nbSlides;
        session.progress.modules[parentObject.id].position.index.max = nbSlides;
        session.progress.modules[parentObject.id].position.index.current =
            position;
        session.progress.modules[parentObject.id].position.index.notStarted =
            notStarted;
        session.progress.modules[parentObject.id].position.index.inProgress =
            started;
        session.progress.modules[parentObject.id].position.index.finished =
            finished;
        session.progress.modules[
            parentObject.id
        ].position.index.explorationRate = Math.round(
            ((started + finished) / nbSlides) * 100
        );
        //UPDATE LAST
        if (session.progress.modules[parentObject.id].last) {
            if (session.progress.modules[parentObject.id].last.module) {
                session.progress.modules[parentObject.id].last.module.progress =
                    session.progress.modules[
                        parentObject.id
                    ].position.index.purcent;
            }
            session.progress.modules[parentObject.id].last.progress =
                session.progress.modules[
                    parentObject.id
                ].position.index.purcent;
        }

        if (session.last?.module?.id == parentObject.id) {
            if (session.last.module) {
                session.last.module.progress =
                    session.progress.modules[
                        parentObject.id
                    ].position.index.purcent;
            }
            session.last.progress = session.last.module.progress;
        }
    }

    //}
    //TAKE RECORDS
    purcentProgress = session.progress.modules[parentObject.id].progressEnd
        ? 100
        : session.progress.modules[parentObject.id].position?.index?.purcent ??
          0;
    explorationRate =
        session.progress.modules[parentObject.id].position?.index
            ?.explorationRate ?? purcentProgress;

    //////////////SET END MODULE
    if (purcentProgress === 100) {
        session.progress.modules[parentObject.id].position.index.purcent = 100;
        if (
            session.progress.modules[parentObject.id].position?.details[
                parentObject.id
            ] &&
            Object.keys(
                session.progress.modules[parentObject.id].position?.details
            ).length == 1
        ) {
            session.progress.modules[
                parentObject.id
            ].position.index.explorationRate = 100;
            session.progress.modules[parentObject.id].position.index.fraction =
                "1 / 1";
        }
        session.progress.modules[parentObject.id].progressEnd = true;
    }
    //CHECK IF SCORE
    if (!session.progress.modules[parentObject.id].score?.index) {
        return {
            session: session,
            progress: {
                purcent: purcentProgress,
                label: purcentProgress + "% " + _translator.get("accomplished"),
                explorationRate: explorationRate,
            },
        };
    }

    const totalPoint =
        session.progress.modules[parentObject.id].score.index.max ??
        session.progress.modules[parentObject.id].score.index.fraction
            ?.replace(" ", "")
            .split("/")?.[1];
    let score;
    if (totalPoint && parseInt(totalPoint) > 0) {
        score =
            session.progress.modules[parentObject.id].score.index.purcent ?? 0;
        return {
            session: session,
            progress: {
                purcent: purcentProgress,
                score: score,
                scoreMax: parseInt(totalPoint),
                label: _translator.get("scoreobtained") + " " + score + " %",
                explorationRate: explorationRate,
            },
        };
    }
    return {
        session: session,
        progress: {
            purcent: purcentProgress,
            label: purcentProgress + "% " + _translator.get("accomplished"),
            explorationRate: explorationRate,
        },
    };
};

function displayUploading(self, purcent, _translator) {
    let uploading = document.body.querySelector(".uploading");
    if (!uploading) {
        uploading = constructUploading();
        document.body.appendChild(uploading);
    }
    const progress = uploading.querySelector("progress");
    progress.value = Math.round(purcent);
    progress.setAttribute("data-label", Math.round(purcent) + "%");
    const label = uploading.querySelector(".label");
    if (purcent == 100) {
        label.innerText = _translator.get("encodingsetup");
        return;
    }
    label.innerText = _translator.get("uploadinprogress");
}
function constructUploading(_translator) {
    const uploading = document.createElement("div");
    uploading.setAttribute("class", "uploading");
    uploading.innerHTML = `<div class="container">
		<progress class="upload" max="100" data-label="80%" value="80"></progress>
		<div class="label">${_translator.get("uploadinprogress")}</div>
	</div>`;
    return uploading;
}
function dispatchImport(self, data) {
    let uploading = document.body.querySelector(".uploading");
    if (uploading) uploading.remove();
    var event = new CustomEvent("intkn-popup-upload-add", {
        bubbles: true,
        detail: {
            element: data.dataSet?.query,
        },
    });
    self.dispatchEvent(event);
}

const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
};
const getScormPlayer = (object, user, options, returnUrl) => {
    if (!object.embeddedKey) {
        return;
    }
    const report = options?.report ?? "module";
    var url = `https://${getSubDomain()}.${
        user.domain
    }/scormplayer/index.html?resource=${object.id}&scope=${
        object.scope.split(",")[0]
    }&report=${report}&interfaceType=web&embeddedKey=${object.embeddedKey}`;

    if (returnUrl) {
        return url;
    }
    window.open(url);
};

const getNewSlide = function (index = 0, id = undefined) {
    return {
        time: 0,
        reading: 1,
        lastReading: Math.round(new Date().getTime() / 1000),
        dateStart: Math.round(new Date().getTime() / 1000),
        dateEnd: 0,
        end: false,
        attempt: [],
        validated: 0,
        index: index,
        id: id,
        purcent: 0,
    };
};

const isVisible = function (domElement, container, margin) {
    const { bottom, height, top } = domElement.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    return top <= containerRect.top
        ? containerRect.top - top <= height - (margin?.top ?? 0)
        : bottom - containerRect.bottom <= height - (margin?.bottom ?? 0);
};

const deviceDetection = () => {
    const ua = navigator.userAgent;
    const checks = {
        iphone: Boolean(ua.match(/iPhone/)),
        ipod: Boolean(ua.match(/iPod/)),
        ipad: Boolean(ua.match(/iPad/)),
        blackberry: Boolean(ua.match(/BlackBerry/)),
        playbook: Boolean(ua.match(/PlayBook/)),
        android: Boolean(ua.match(/Android/)),
        macOS: Boolean(ua.match(/Mac/)),
        win: Boolean(ua.match(/Windows/)),
        mac: Boolean(ua.match(/Macintosh/)),
        wphone: Boolean(
            ua.match(/(Windows Phone OS|Windows CE|Windows Mobile)/)
        ),
        mobile: Boolean(ua.match(/Mobile/)),
        tabletPc: Boolean(ua.match(/Tablet PC/)),
        palmDevice: Boolean(ua.match(/(PalmOS|PalmSource| Pre\/)/)),
        kindle: Boolean(ua.match(/(Kindle)/)),
        safari: Boolean(ua.match(/(Safari)/)),
        edge: Boolean(ua.match(/(Edge)/)),
        msie: Boolean(ua.match(/(MSIE)/)),
        trident: Boolean(ua.match(/(Trident)/)),
        firefox: Boolean(ua.match(/(Firefox)/)),
        otherMobileHints: Boolean(
            ua.match(/(Opera Mini|IEMobile|SonyEricsson|smartphone)/)
        ),
    };
    return {
        isEdge: checks.edge,
        isSafari: checks.safari,
        isFirefox: checks.firefox,
        isAndroid: () => {
            return checks.android;
        },
        isTouchDevice: () => {
            return checks.iphone || checks.ipod || checks.ipad;
        },
        isTablet: () => {
            return (
                checks.ipad ||
                checks.playbook ||
                checks.android ||
                checks.kindle
            );
        },
        isDesktop: () => {
            return (
                !(checks.iphone || checks.ipod || checks.ipad) &&
                !(
                    checks.mobile ||
                    checks.blackberry ||
                    checks.palmDevice ||
                    (checks.otherMobileHints &&
                        !(
                            checks.ipad ||
                            checks.playbook ||
                            checks.android ||
                            checks.kindle
                        ) &&
                        !checks.ipod)
                ) &&
                !(
                    checks.ipad ||
                    checks.playbook ||
                    checks.android ||
                    checks.kindle
                )
            );
        },
        isSmartPhone: () => {
            return (
                checks.mobile ||
                checks.blackberry ||
                checks.palmDevice ||
                (checks.otherMobileHints &&
                    !(
                        checks.ipad ||
                        checks.playbook ||
                        checks.android ||
                        checks.kindle
                    ) &&
                    !checks.ipod)
            );
        },
        isResponsive: () => {
            if (window.innerWidth <= 1250) {
                return true;
            }
            return false;
        },
    };
};

const sendMessageToMobileInterface = function (action, data) {
    if (!typeof data == "string") {
        data = JSON.stringify(data);
    }
    var messageString = { app: "INTKNPlayer", action: action, arguments: data };
    parent.postMessage(messageString, "*");
    //parent.postMessage(messageString, window.location);
};

const setSortChilds = (id, objectStream, object) => {
    //console.log('setSortChilds');
    if (objectStream) {
        var stream = objectStream;
    }
    const parentObject = object ?? stream.getGlobalObject({ id: id });
    if (!parentObject?.child?.[0]) {
        return;
    }
    //console.log(parentObject.child[0].sortTag);
    if (
        parentObject.child[0].sortTag !== undefined &&
        parentObject.child[0].sortTag !== false
    ) {
        //console.log('sortTag');
        parentObject.child.sort(sortTagUp);
        return;
    }
    //console.log('sortNameUp');
    parentObject.child.sort(sortNameUp);
    //console.log('setSortChilds',parentObject.child);
};

const addEvent = (object, action, funct) => {
    if (!object) {
        return;
    }
    object.addEventListener(action, funct, false);
};

const removeEvent = (object, action, funct) => {
    if (!object) {
        return;
    }
    object.removeEventListener(action, funct, false);
};

//SCROLL & SCALE
const scrollAndScale = function () {
    this.container;
    this.object;
    this.rootContainer;
    this.perfectScale = 1;
    this.labels = {
        text: false,
        range: false,
    };
    this.handScroll = true;
    this.position = {
        x: 50,
        y: 50,
        translateX: -100,
        translateY: -100,
        cursor: false,
    };
    this.callBack;
    this.values = {
        min: 0.2,
        max: 1.5,
    };
    this.move = false;
    var _this = this;
    this.setContainer = function (container) {
        this.container = container;
        this.rootContainer = container;
    };
    this.setPerfectScale = function (perfect) {
        this.perfectScale = perfect;
    };
    this.setHandScrollOptions = function (scroll) {
        this.handScroll = scroll;
    };
    this.setCallback = function (callBack) {
        //console.log('setCallback1')
        this.callBack = callBack;
    };
    this.setObject = function (object) {
        this.object = object;
    };
    this.setLimits = function (limits) {
        if (limits.min) {
            this.values.min = limits.min;
        }
        if (limits.max) {
            this.values.max = limits.max;
        }
    };
    this.setLabels = function (text, range) {
        if (text) {
            this.labels.text = text;
        }
        if (range) {
            this.labels.range = range;
        }
    };
    this.setZoomStart = function (evt, zoom) {
        _this.zoom = true;
        _this.setZoom(evt, zoom);
        if (_this.pressLoop) {
            clearTimeout(_this.buttonPressLoopZoom);
            _this.buttonPressLoopZoom = setTimeout(function (evt) {
                _this.setZoomStart(evt, zoom);
            }, 110);
        }
    };
    this.setZoomStartFromRange = function (evt, zoom) {
        _this.zoom = true;
        _this.setZoom(evt, false, zoom);
        if (_this.pressLoop) {
            clearTimeout(_this.buttonPressLoopZoom);
            _this.buttonPressLoopZoom = setTimeout(function (evt) {
                _this.setZoomStart(evt, zoom);
            }, 110);
        }
    };

    this.setZoomStop = function (evt, zoom) {
        if (_this.pressLoop) {
            clearTimeout(_this.buttonPressLoopZoom);
        }
        _this.zoom = false;
    };

    this.setZoom = function (evt, zoom, forceZoom) {
        if (!this.zoom) {
            return;
        }

        var translate = getTranslate(_this.object);
        var oldScale = parseFloat(getScale(_this.object));
        var scale = oldScale + zoom;
        if (forceZoom) {
            scale = forceZoom;
        }
        var rotate = parseInt(getRotate(_this.object).split("deg")[0]);
        if (scale < this.values.min) {
            scale = this.values.min;
        } else if (scale > this.values.max) {
            scale = this.values.max;
        }
        _this.position.scale = scale;
        //console.log(_this.position.scale);
        /*if (scale > 1) {*/
        var difScale = scale - oldScale;
        var translate = getTranslate(_this.object);
        if (translate.x.replace) {
            translate.x = parseFloat(translate.x.replace("%", ""));
        }
        if (translate.y.replace) {
            translate.y = parseFloat(translate.y.replace("%", ""));
        }
        translate.x = (translate.x * oldScale) / scale + "%";
        translate.y = (translate.y * oldScale) / scale + "%";
        /*} else {
			translate.y = (_this.position.translateY / scale) + '%';
			translate.x = (_this.position.translateX / scale) + '%';
		}*/
        _this.object.style.transform =
            "scale(" +
            scale +
            ") translateY(" +
            translate.y +
            ")" +
            " translateX(" +
            translate.x +
            ")" +
            " rotate(" +
            rotate +
            "deg)";
        if (_this.labels.text) {
            _this.labels.text.innerText = parseInt(scale * 100) + "%";
        }
        if (_this.labels.range) {
            _this.labels.range.value = scale;
        }
        if (this.handScroll) {
            _this.setHandScroll();
        }
        this.callBack?.();
        //_this.setDelayedControlsUp();
    };

    this.setHandScroll = function () {
        if (this.position.scale <= this.perfectScale) {
            this.object.classList.remove("handCursor");
            removeEvent(this.object, "mousedown", this.setMoveEnable);
            removeEvent(this.object, "mousemove", this.setMove);
            removeEvent(this.object, "mouseup", this.setMoveDisable);
            removeEvent(this.rootContainer, "mouseup", this.setMoveDisable);
            return;
        }
        this.object.classList.add("handCursor");
        addEvent(this.object, "mousedown", this.setMoveEnable);
        addEvent(this.object, "mousemove", this.setMove);
        addEvent(this.object, "mouseup", this.setMoveDisable);
        addEvent(this.rootContainer, "mouseup", this.setMoveDisable);
    };

    this.setMoveEnable = function (evt) {
        evt.preventDefault();
        _this.move = true;
        _this.object.classList.remove("handCursor");
        _this.object.classList.add("handCursorDown");
        _this.position.cursor = {
            x: evt.x,
            y: evt.y,
        };
    };

    this.setMove = function (evt) {
        if (!_this.move) {
            return;
        }
        _this.object.classList.add("handCursorDown");

        var distanceX =
            (evt.x - _this.position.cursor.x) / _this.position.scale;
        var distanceY =
            (evt.y - _this.position.cursor.y) / _this.position.scale;
        _this.position.cursor = {
            x: evt.x,
            y: evt.y,
        };

        var pourcentX = parseFloat(distanceX / _this.object.offsetWidth) * 100;
        var pourcentY = parseFloat(distanceY / _this.object.offsetHeight) * 100;
        var translate = getTranslate(_this.object);
        var translateX =
            parseFloat(translate.x.replace("%", "")) + pourcentX + "%";
        var translateY =
            parseFloat(translate.y.replace("%", "")) + pourcentY + "%";

        var scale = parseFloat(getScale(_this.object));
        var rotate = parseInt(getRotate(_this.object).split("deg")[0]);

        _this.object.style.transform =
            "scale(" +
            scale +
            ") translateY(" +
            translateY +
            ")" +
            " translateX(" +
            translateX +
            ")" +
            " rotate(" +
            rotate +
            "deg)";
    };

    this.setMoveDisable = function (evt) {
        _this.move = false;
        _this.object.classList.remove("handCursorDown");
        _this.object.classList.add("handCursor");
    };

    this.getRatio = function () {
        return this.container.offsetHeight / this.container.offsetWidth;
    };
};
const getTranslate = (element) => {
    if (!element || !element.style.transform) {
        return {
            x: 0,
            y: 0,
        };
    }
    const patternY = /translateY\(([^\)]+)\).*/;
    const matchY = patternY.exec(element.style.transform);
    const translateY = matchY && matchY.length > 1 ? matchY[1] : 0;
    const patternX = /translateX\(([^\)]+)\).*/;
    const matchX = patternX.exec(element.style.transform);
    const translateX = matchX && matchX.length > 1 ? matchX[1] : 0;
    return {
        x: translateX,
        y: translateY,
    };
};
const getRotate = (element) => {
    if (!element || !element.style.transform) {
        return "0deg";
    }
    const pattern = /rotate\(([^\)]+)\).*/;
    const match = pattern.exec(element.style.transform);
    return match && match.length > 1 ? match[1] : "0deg";
};

const getScale = (element) => {
    if (!element || !element.style.transform) {
        return 1;
    }
    const pattern = /scale\(([^\)]+)\).*/;
    const match = pattern.exec(element.style.transform);
    return match && match.length > 1 ? match[1] : 1;
};

//DRAG AND DROP
const DragAndDrop = function () {
    const _this = this;
    this.elementDragged = false;
    this.targetDragged = false;
    this.container = false;
    this.subContainer = false;
    this.handlers;
    this.data = [];
    this.cloneGhost = false;
    this.currentInsert = false;
    this.fakeGhost = {
        domElement: false,
        oldX: 0,
        oldY: 0,
        startX: 0,
        startY: 0,
        className: false,
    };
    this.classNames = {
        drag: false,
        drop: false,
    };
    this.setClassNames = function (drag, drop, ghost) {
        if (drag) {
            this.classNames.drag = drag;
        }
        if (drop) {
            this.classNames.drop = drop;
        }
        if (ghost) {
            this.fakeGhost.className = ghost;
        }
    };
    this.setCloneGhost = function (value) {
        this.cloneGhost = value;
    };
    this.setCurrentInsert = function (value) {
        this.currentInsert = value;
    };
    this.getLine = function (element) {
        if (
            element.parentNode &&
            element.parentNode != _this.container &&
            !_this.getSubContainer(element.parentNode)
        ) {
            return this.getLine(element.parentNode);
        }

        return element;
    };

    this.getContainer = function (element) {
        if (element == _this.container) {
            return element;
        }
        if (_this.getSubContainer(element)) {
            return element;
        }
        return this.getContainer(element.parentNode);
    };

    this.getIndex = function (element) {
        return getChildrenIndex(element);
    };

    this.getWay = function (element) {
        var indexDroppedElement = this.getIndex(element);
        var indexDraggedElement = this.getIndex(this.elementDragged);
        if (indexDroppedElement < indexDraggedElement) {
            return -1;
        }
        return 1;
    };

    this.setContainer = function (container) {
        this.container = container;
    };
    this.setSubContainer = function (subContainer) {
        if (!this.subContainer) {
            this.subContainer = [];
        }
        this.subContainer.push(subContainer);
    };
    this.getSubContainer = function (parent) {
        if (!_this.subContainer) {
            return false;
        }
        var nbSc = _this.subContainer.length;
        for (var sC = 0; sC < nbSc; sC++) {
            if (parent == _this.subContainer[sC]) {
                return true;
            }
        }
        return false;
    };
    this.setNodeInsertWithData = function (dropLine, dataLine) {
        var container = this.getContainer(dropLine);
        //console.log('setNodeInsertWithData',this.elementDragged,dropLine,dataLine.dropElement);
        container.insertAfter(this.elementDragged, dropLine);
        container.insertAfter(dataLine.dropElement, this.elementDragged);
    };

    this.setNodeInsertForOrdered = function (dropLine) {
        //var way = this.getWay(element);
        //console.log(dropLine);
        var containerDrop = dropLine.parentNode;
        var indexDrop = _this.getIndex(dropLine);
        //console.log(this.elementDragged.parentNode);
        this.elementDragged.parentNode.insertBefore(
            dropLine,
            this.elementDragged
        );

        if (containerDrop.childNodes[indexDrop]) {
            containerDrop.insertBefore(
                this.elementDragged,
                containerDrop.childNodes[indexDrop]
            );
            return;
        }
        containerDrop.appendChild(this.elementDragged);
    };

    this.setExtraHandlers = function (handlers) {
        if (typeof handlers != "undefined" && handlers) {
            this.handlers = handlers;
        }
    };
    // TODO(#80) Issues in layer drag events Firefox
    this.addHandlersWithData = function (dataLine) {
        var element = dataLine.domElement;
        var drop = dataLine.dropElement;
        _this.data[dataLine.data.id] = dataLine;
        element.addEventListener(
            "dragstart",
            function (evt) {
                _this.handleDragStart(evt);
            },
            false
        );
        element.addEventListener(
            "drag",
            function (evt) {
                _this.handleDrag(evt);
            },
            false
        );
        drop.addEventListener(
            "dragenter",
            function (evt) {
                _this.handleDragEnter(evt);
            },
            false
        );
        drop.addEventListener(
            "dragover",
            function (evt) {
                _this.handleDragOver(evt);
            },
            false
        );
        drop.addEventListener(
            "dragleave",
            function (evt) {
                _this.handleDragLeave(evt);
            },
            false
        );
        drop.addEventListener(
            "drop",
            function (evt) {
                _this.handleDrop(evt);
            },
            false
        );
        element.addEventListener(
            "dragend",
            function (evt) {
                _this.handleDragEnd(evt, dataLine);
            },
            false
        );
    };
    this.addHandlers = function (element, container, drop) {
        if (
            !this.container &&
            (typeof container == "undefined" || !container) &&
            element.parentNode
        ) {
            this.container = element.parentNode;
        } else if (typeof container != "undefined" && container) {
            this.container = container;
        }
        if (typeof drop == "undefined" || !drop) {
            var drop = element;
        }
        element.addEventListener(
            "dragstart",
            function (evt) {
                _this.handleDragStart(evt);
            },
            false
        );
        element.addEventListener(
            "drag",
            function (evt) {
                _this.handleDrag(evt);
            },
            false
        );
        drop.addEventListener(
            "dragenter",
            function (evt) {
                _this.handleDragEnter(evt);
            },
            false
        );
        drop.addEventListener(
            "dragover",
            function (evt) {
                _this.handleDragOver(evt);
            },
            false
        );
        drop.addEventListener(
            "dragleave",
            function (evt) {
                _this.handleDragLeave(evt);
            },
            false
        );
        drop.addEventListener(
            "drop",
            function (evt) {
                _this.handleDrop(evt);
            },
            false
        );
        element.addEventListener(
            "dragend",
            function (evt) {
                _this.handleDragEnd(evt);
            },
            false
        );
    };
    this.addHandlersDrop = function (drop) {
        drop.addEventListener(
            "dragenter",
            function (evt) {
                _this.handleDragEnter(evt);
            },
            false
        );
        drop.addEventListener(
            "dragover",
            function (evt) {
                _this.handleDragOver(evt);
            },
            false
        );
        drop.addEventListener(
            "dragleave",
            function (evt) {
                _this.handleDragLeave(evt);
            },
            false
        );
        drop.addEventListener(
            "drop",
            function (evt) {
                _this.handleDrop(evt);
            },
            false
        );
    };
    this.handleDragStart = function (evt) {
        evt.stopPropagation();
        _this.timeStamp = Date.now();
        //console.log('start');
        _this.elementDragged = _this.getLine(evt.target);

        if (_this.cloneGhost) {
            var img = new Image();
            img.src =
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPhfDwAChwGA60e6kgAAAABJRU5ErkJggg==";
            evt.dataTransfer.setDragImage(img, 0, 0);
            _this.fakeGhost.domElement = _this.elementDragged.cloneNode(true);
            _this.fakeGhost.domElement.style.position = "fixed";
            _this.fakeGhost.domElement.style.zIndex = 9999;
            _this.fakeGhost.domElement.style.left = "-999px";
            _this.fakeGhost.domElement.style.margin = 0;
            _this.fakeGhost.domElement.style.padding = 0;
            _this.fakeGhost.domElement.style.opacity = 0;
            _this.fakeGhost.domElement.style.visibility = "hidden";
            _this.fakeGhost.domElement.style.userSelect = "none";
            _this.fakeGhost.domElement.style.pointerEvents = "none";
            _this.fakeGhost.domElement.removeAttribute("draggable");
            if (_this.fakeGhost.className) {
                _this.fakeGhost.domElement.classList.add(
                    _this.fakeGhost.className
                );
            }
            (_this.fakeGhost.domElement.style.left = 0),
                (_this.fakeGhost.domElement.style.top = 0);
            _this.fakeGhost.startX = evt.layerX;
            _this.fakeGhost.startY = evt.layerY;
            _this.elementDragged.parentNode.appendChild(
                _this.fakeGhost.domElement
            );
        }
        evt.dataTransfer.effectAllowed = "move";
        if (!_this.classNames.drag) {
            _this.elementDragged.classList.add("dragElement");
        } else {
            _this.elementDragged.classList.add(_this.classNames.drag);
        }
        if (_this.targetDragged) {
            if (!_this.classNames.drop) {
                _this.targetDragged.classList.remove("dragElementOver");
            } else {
                _this.targetDragged.classList.remove(_this.classNames.drop);
            }
            _this.targetDragged = false;
        }
        return false;
    };
    this.handleDrag = function (evt) {
        evt.stopPropagation();
        evt.preventDefault();
        setGhostPosition(evt);
        return false;
    };
    function setGhostPosition(evt) {
        if (!_this.cloneGhost) {
            return;
        }
        //console.log(evt.x)
        var useX = evt.x;
        var useY = evt.y;
        if (useX === 0 && useY === 0) {
            return;
        }

        if (useX === _this.fakeGhost.oldX && useY === _this.fakeGhost.oldY) {
            return;
        }
        var now = Date.now();
        var startTime = now - _this.timeStamp;
        if (startTime > 40) {
            //console.log('stopopacityforghost');
            _this.fakeGhost.domElement.style.visibility = "visible";
            _this.fakeGhost.domElement.style.opacity = 1;
        }
        _this.fakeGhost.domElement.style.left =
            useX - _this.fakeGhost.startX + "px";
        _this.fakeGhost.domElement.style.top =
            useY - _this.fakeGhost.startY + "px";

        _this.fakeGhost.domElement.oldX = useX;
        _this.fakeGhost.domElement.oldY = useY;
    }
    this.handleDragEnter = function (evt) {
        // this / evt.target is the current hover target.
        //console.log('enter',evt);
        if (evt.stopPropagation) {
            evt.stopPropagation();
            evt.preventDefault();
        }
        var now = Date.now();
        var startTime = now - _this.timeStamp;
        if (startTime < 140) {
            console.log("stopforbugchrome");
            return;
        }
        var line = _this.getLine(evt.target);
        if (_this.targetDragged && _this.targetDragged != line) {
            console.log("cleandragElementOver");
            if (!_this.classNames.drop) {
                _this.targetDragged.classList.remove("dragElementOver");
            } else {
                _this.targetDragged.classList.remove(_this.classNames.drop);
            }
        }

        if (!_this.elementDragged || line.isSameNode(_this.elementDragged)) {
            console.log("samenode");
            return false;
        }

        if (!_this.targetDragged || _this.targetDragged != line) {
            _this.targetDragged = line;
            if (!_this.classNames.drop) {
                line.classList.add("dragElementOver");
            } else {
                line.classList.add(_this.classNames.drop);
            }
            if (_this.currentInsert) {
                _this.setNodeInsertForOrdered(_this.targetDragged);
            }
            //console.log('newtargetfordragelementover',_this.targetDragged);
        }

        return false;
    };

    this.handleDragOver = function (evt) {
        setGhostPosition(evt);
        evt.preventDefault();
        evt.dataTransfer.dropEffect = "move";
        return false;
    };

    this.handleDragLeave = function (evt) {
        if (evt.stopPropagation) {
            evt.stopPropagation();
        }
        if (!_this.targetDragged) {
            return false;
        }
        var line = _this.getLine(evt.target);
        if (line != _this.targetDragged) {
            //console.log('leaveover');
            if (!_this.classNames.drop) {
                _this.targetDragged.classList.remove("dragElementOver");
            } else {
                _this.targetDragged.classList.remove(_this.classNames.drop);
            }
            _this.targetDragged = false;
        }
        return false;
        //_this.targetDragged = false;
        // this / evt.target is previous target element.
    };

    this.handleDrop = function (evt) {
        if (evt.stopPropagation) {
            evt.stopPropagation();
            evt.preventDefault();
        }
        if (_this.elementDragged) {
            if (!_this.classNames.drag) {
                _this.elementDragged.classList.remove("dragElement");
            } else {
                _this.elementDragged.classList.remove(_this.classNames.drag);
            }
        }
        if (_this.targetDragged) {
            if (!_this.classNames.drop) {
                _this.targetDragged.classList.remove("dragElementOver");
            } else {
                _this.targetDragged.classList.remove(_this.classNames.drop);
            }
        }
        return false;
    };

    this.handleDragEnd = function (evt, dataLine) {
        if (!_this.elementDragged) {
            return false;
        }
        if (_this.cloneGhost) {
            _this.fakeGhost.domElement.parentNode.removeChild(
                _this.fakeGhost.domElement
            );
        }
        if (!_this.classNames.drag) {
            _this.elementDragged.classList.remove("dragElement");
        } else {
            _this.elementDragged.classList.remove(_this.classNames.drag);
        }
        if (!_this.targetDragged && !_this.currentInsert) {
            _this.elementDragged = false;
            return false;
        }
        if (_this.targetDragged) {
            if (!_this.classNames.drop) {
                _this.targetDragged.classList.remove("dragElementOver");
            } else {
                _this.targetDragged.classList.remove(_this.classNames.drop);
            }
            if (typeof dataLine != "undefined") {
                //console.log('end2',dataLine);
                _this.setNodeInsertWithData(_this.targetDragged, dataLine);
            } else {
                if (!_this.currentInsert) {
                    //console.log('endInsert');
                    _this.setNodeInsertForOrdered(_this.targetDragged);
                }
            }
        }
        if (typeof _this.handlers != "undefined" && _this.handlers.over) {
            _this.handlers.over(evt, _this);
        }
        _this.elementDragged = false;
        _this.targetDragged = false;
        if (typeof _this.handlers != "undefined" && _this.handlers.end) {
            _this.handlers.end(evt, _this);
        }
    };
};

const getChildrenIndex = function (ele) {
    if (!ele) {
        return 0;
    }
    if (ele.sourceIndex) {
        var eles = ele.parentNode.children;
        var low = 0,
            high = eles.length - 1,
            mid = 0;
        var esi = ele.sourceIndex;
        var nsi;
        //USE NINARY SEARCH ALGO
        while (low <= high) {
            mid = (low + high) >> 1;
            nsi = eles[mid].sourceIndex;
            if (nsi > esi) {
                high = mid - 1;
            } else if (nsi < esi) {
                low = mid + 1;
            } else {
                return mid;
            }
        }
    }
    //OTHER BROWSERS
    var i = 0;
    while ((ele = ele.previousSibling)) {
        i++;
    }
    return i;
};

//STYLE GETTER
const getCSSAttributeInInteger = (attribute, element) => {
    if (!attribute) {
        return 0;
    }
    if (attribute.indexOf("px") != -1) {
        return parseFloat(attribute.split("px")[0].trim());
    } else if (attribute.indexOf("em") != -1) {
        attribute = attribute.split("em")[0].trim();
        return parseFloat(attribute) * 14;
    } else if (attribute.indexOf("%") != -1) {
        const integer = attribute.split("%")[0].trim();
        return (element.parentNode.offsetWidth * parseFloat(integer)) / 100;
    }
    return 0;
};

const getIntegerFromAttributeValue = (attribute, parentNode) => {
    if (!attribute) {
        return 0;
    }
    if (attribute.indexOf("px") != -1) {
        return parseFloat(attribute.split("px")[0].trim());
    } else if (attribute.indexOf("em") != -1) {
        attribute = attribute.split("em")[0].trim();
        return parseFloat(attribute) * 14;
    } else if (attribute.indexOf("%") != -1) {
        const integer = attribute.split("%")[0].trim();
        return (parentNode.offsetWidth * parseFloat(integer)) / 100;
    }
    return 0;
};

const arraymove = (arr, fromIndex, toIndex) => {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
};
//DISTANCE WITH TWO WORDS
const levenshtein = (s1, s2) => {
    if (s1 == s2) {
        return 0;
    }
    if (!s1 || !s2) {
        return 0;
    }
    var s1_len = s1.length;
    var s2_len = s2.length;
    if (s1_len === 0) {
        return s2_len;
    }
    if (s2_len === 0) {
        return s1_len;
    }

    // BEGIN STATIC
    var split = false;
    try {
        split = !"0"[0];
    } catch (e) {
        split = true; // Earlier IE may not support access by string index
    }
    // END STATIC
    if (split) {
        s1 = s1.split("");
        s2 = s2.split("");
    }

    var v0 = new Array(s1_len + 1);
    var v1 = new Array(s1_len + 1);

    var s1_idx = 0,
        s2_idx = 0,
        cost = 0;
    for (s1_idx = 0; s1_idx < s1_len + 1; s1_idx++) {
        v0[s1_idx] = s1_idx;
    }

    var char_s1 = "",
        char_s2 = "";
    for (s2_idx = 1; s2_idx <= s2_len; s2_idx++) {
        v1[0] = s2_idx;
        char_s2 = s2[s2_idx - 1];

        for (s1_idx = 0; s1_idx < s1_len; s1_idx++) {
            char_s1 = s1[s1_idx];
            cost = char_s1 == char_s2 ? 0 : 1;
            var m_min = v0[s1_idx + 1] + 1;
            var b = v1[s1_idx] + 1;
            var c = v0[s1_idx] + cost;
            if (b < m_min) {
                m_min = b;
            }
            if (c < m_min) {
                m_min = c;
            }
            v1[s1_idx + 1] = m_min;
        }
        var v_tmp = v0;
        v0 = v1;
        v1 = v_tmp;
    }
    return v0[s1_len];
};

const getPurcent = (top, bottom, noRound) => {
    if (isNaN(top) || isNaN(bottom)) {
        return;
    }
    if (!noRound) {
        return Math.round((top / bottom) * 100);
    }
    return Math.round((top / bottom) * 10000) / 100;
};

const isURLAndSetPath = (str) => {
    if (str.indexOf("@") != -1) {
        var valid =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                str
            );
        if (!valid) {
            return false;
        }
        return "mailto:" + str.toLowerCase();
    }
    if (str.indexOf("http://") == -1 && str.indexOf("https://") == -1) {
        str = "https://" + str;
    }
    try {
        new URL(str);
        return str;
    } catch (e) {
        return false;
    }
};
const isURL = (str) => {
    if (str.indexOf("@") != -1) {
        return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            str
        );
    }
    if (
        str.indexOf("http://") == -1 &&
        str.indexOf("https://") == -1 &&
        str.indexOf("@") == -1
    ) {
        str = "https://" + str;
    }
    try {
        new URL(str);
        return true;
    } catch (e) {
        return false;
    }
};

const linkifyPath = (function () {
    var SCHEME = "[a-z\\d.-]+://",
        IPV4 =
            "(?:(?:[0-9]|[1-9]\\d|1\\d{2}|2[0-4]\\d|25[0-5])\\.){3}(?:[0-9]|[1-9]\\d|1\\d{2}|2[0-4]\\d|25[0-5])",
        HOSTNAME = "(?:(?:[^\\s!@#$%^&*()_=+[\\]{}\\\\|;:'\",.<>/?]+)\\.)+",
        TLD =
            "(?:ac|ad|aero|ae|af|ag|ai|al|am|an|ao|aq|arpa|ar|asia|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|biz|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|cat|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|coop|com|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|edu|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gov|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|info|int|in|io|iq|ir|is|it|je|jm|jobs|jo|jp|ke|kg|kh|ki|km|kn|kp|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mil|mk|ml|mm|mn|mobi|mo|mp|mq|mr|ms|mt|museum|mu|mv|mw|mx|my|mz|name|na|nc|net|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|org|pa|pe|pf|pg|ph|pk|pl|pm|pn|pro|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tel|tf|tg|th|tj|tk|tl|tm|tn|to|tp|travel|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|xn--0zwm56d|xn--11b5bs3a9aj6g|xn--80akhbyknj4f|xn--9t4b11yi5a|xn--deba0ad|xn--g6w251d|xn--hgbk6aj7f53bba|xn--hlcj6aya9esc7a|xn--jxalpdlp|xn--kgbechtv|xn--zckzah|ye|yt|yu|za|zm|zw)",
        HOST_OR_IP = "(?:" + HOSTNAME + TLD + "|" + IPV4 + ")",
        PATH = "(?:[;/][^#?<>\\s]*)?",
        QUERY_FRAG = "(?:\\?[^#<>\\s]*)?(?:#[^<>\\s]*)?",
        URI1 = "\\b" + SCHEME + "[^<>\\s]+",
        URI2 = "\\b" + HOST_OR_IP + PATH + QUERY_FRAG + "(?!\\w)",
        MAILTO = "mailto:",
        EMAIL =
            "(?:" +
            MAILTO +
            ")?[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@" +
            HOST_OR_IP +
            QUERY_FRAG +
            "(?!\\w)",
        URI_RE = new RegExp(
            "(?:" + URI1 + "|" + URI2 + "|" + EMAIL + ")",
            "ig"
        ),
        SCHEME_RE = new RegExp("^" + SCHEME, "i"),
        quotes = {
            "'": "`",
            ">": "<",
            ")": "(",
            "]": "[",
            "}": "{",
            "Â»": "Â«",
            "â€º": "â€¹",
        },
        default_options = {
            callback: function (text, href) {
                return href ? href : text;
            },
            punct_regexp:
                /(?:[!?.,:;'"]|(?:&|&amp;)(?:lt|gt|quot|apos|raquo|laquo|rsaquo|lsaquo);)$/,
        };

    return function (txt, options) {
        options = options || {};

        // Temp variables.
        var arr,
            i,
            link,
            href,
            // Output HTML.
            html = "",
            // Store text / link parts, in order, for re-combination.
            parts = [],
            // Used for keeping track of indices in the text.
            idx_prev,
            idx_last,
            idx,
            link_last,
            // Used for trimming trailing punctuation and quotes from links.
            matches_begin,
            matches_end,
            quote_begin,
            quote_end;

        // Initialize options.
        for (i in default_options) {
            if (options[i] === undefined) {
                options[i] = default_options[i];
            }
        }

        // Find links.
        while ((arr = URI_RE.exec(txt))) {
            link = arr[0];
            idx_last = URI_RE.lastIndex;
            idx = idx_last - link.length;

            // Not a link if preceded by certain characters.
            if (/[\/:]/.test(txt.charAt(idx - 1))) {
                continue;
            }

            // Trim trailing punctuation.
            do {
                // If no changes are made, we don't want to loop forever!
                link_last = link;

                quote_end = link.substr(-1);
                quote_begin = quotes[quote_end];

                // Ending quote character?
                if (quote_begin) {
                    matches_begin = link.match(
                        new RegExp("\\" + quote_begin + "(?!$)", "g")
                    );
                    matches_end = link.match(new RegExp("\\" + quote_end, "g"));

                    // If quotes are unbalanced, remove trailing quote character.
                    if (
                        (matches_begin ? matches_begin.length : 0) <
                        (matches_end ? matches_end.length : 0)
                    ) {
                        link = link.substr(0, link.length - 1);
                        idx_last--;
                    }
                }

                // Ending non-quote punctuation character?
                if (options.punct_regexp) {
                    link = link.replace(options.punct_regexp, function (a) {
                        idx_last -= a.length;
                        return "";
                    });
                }
            } while (link.length && link !== link_last);

            href = link;

            // Add appropriate protocol to naked links.
            if (!SCHEME_RE.test(href)) {
                href =
                    (href.indexOf("@") !== -1
                        ? !href.indexOf(MAILTO)
                            ? ""
                            : MAILTO
                        : !href.indexOf("irc.")
                        ? "irc://"
                        : !href.indexOf("ftp.")
                        ? "ftp://"
                        : "https://") + href;
            }

            // Push preceding non-link text onto the array.
            if (idx_prev != idx) {
                parts.push([txt.slice(idx_prev, idx)]);
                idx_prev = idx_last;
            }

            // Push massaged link onto the array
            parts.push([link, href]);
        }

        // Push remaining non-link text onto the array.
        parts.push([txt.substr(idx_prev)]);

        // Process the array items.
        for (i = 0; i < parts.length; i++) {
            html += options.callback.apply(window, parts[i]);
        }

        // In case of catastrophic failure, return the original text;
        return html || txt;
    };
})();

const isLink = (txt) => {
    const lnk = linkifyPath(txt);
    if (!lnk) {
        return false;
    }
    if (
        lnk === txt &&
        lnk.indexOf("http://") === -1 &&
        lnk.indexOf("https://") === -1
    ) {
        return false;
    }
    return lnk;
};

///////////////////////////////FULLSCREEN
const setFullScreenElement = function (evt, _element) {
    if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
    ) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    } else {
        const element = _element ?? window;
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitEnterFullscreen) {
            element.webkitEnterFullscreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    }
};
const getChangeFullScreenEvent = function (element) {
    if (element.requestFullscreen) {
        return "fullscreenchange";
    } else if (element.webkitRequestFullscreen) {
        return "webkitfullscreenchange";
    } else if (element.mozRequestFullScreen) {
        return "mozfullscreenchange";
    } else if (element.msRequestFullscreen) {
        return "MSFullscreenChange";
    }
    return false;
};

export {
    setCookie,
    getValuesUrl,
    getUrlImg,
    getUserPreferences,
    notificationBubble,
    getMonthLabel,
    sortDateStartUp,
    getDayLabel,
    getSubDomain,
    getDomain,
    closeCurrentPopup,
    removeCurrentPopup,
    createPopupLogin,
    createPopupSignup,
    uniqueId,
    ucFirst,
    debounce,
    getMediaPlayer,
    donwloadMedia,
    isFile,
    convertTimecodeToString,
    addTimecodeAction,
    linkify,
    displayDate,
    setUrlImgByType,
    exportFromTextarea,
    importToTextarea,
    filterObject,
    computeProgressCourse,
    computeProgressModule,
    getNewSessionCourse,
    getNewSlide,
    sendMessageToMobileInterface,
    deviceDetection,
    setSortChilds,
    addEvent,
    removeEvent,
    getScormPlayer,
    calculateAspectRatioFit,
    dispatchImport,
    constructUploading,
    displayUploading,
    scrollAndScale,
    DragAndDrop,
    getCSSAttributeInInteger,
    getIntegerFromAttributeValue,
    getChildrenIndex,
    arraymove,
    levenshtein,
    getPurcent,
    sortZindexUp,
    isURLAndSetPath,
    isURL,
    isLink,
    setFullScreenElement,
    getChangeFullScreenEvent,
    isVisible,
};
