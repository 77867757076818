import '@webcomponents/webcomponentsjs';
import 'core-js/stable';
import 'proxy-polyfill';
import Api from './lib/Api';
import { IntknPopupUpload } from './lib/intkn-popup-upload';
import Translator from './lib/Translator';
import { DataActionCommonAttributes, JsonComponent, StylesComponent, ListComponents, Component, BoxSize } from './lib/Types';
import { getUserPreferences, uniqueId, ucFirst, getMediaPlayer, donwloadMedia, isFile } from './lib/ground';
import { execCommandStyle } from './lib/intkn-inline-editor';
import * as AColorPicker from 'a-color-picker';
import { PlayerVideo } from './lib/intkn-video';
//CHANNEL FOR PARENT WINDOW
window.addEventListener("popstate", function (evt) {
    console.log('popstate', evt);
});

export class EditToolbar extends HTMLElement {

    static get observedAttributes() {
        return [
            'element-id',
            'type'
        ];
    }
    _mode: string = 'viewer';
    _zoom: string | number = 1;
    _popups: {
        video?: IntknPopupUpload,
        image?: IntknPopupUpload,
        file?: IntknPopupUpload
    } = {};
    _container: HTMLDivElement;
    _context: { page: number | undefined; } = {
        page: undefined
    }
    _floatTopBox: {
        domElement?: HTMLElement;
        styles?: StylesComponent;
    } = {};
    _floatTextEditingBox: {
        domElement?: HTMLElement;
        pickers?: {
            container?: HTMLElement;
            fontColorPicker?: {
                domElement?: HTMLElement;
                picker?: AColorPicker.ACPController;
            };
            backgroundColorPicker?: {
                domElement?: HTMLElement;
                picker?: AColorPicker.ACPController;
            };
            borderColorPicker?: {
                domElement?: HTMLElement;
                picker?: AColorPicker.ACPController;
            };
        };
        styles?: StylesComponent;
    } = {};
    _boxSelectedArea: {
        domElement?: HTMLElement;
        styles?: StylesComponent;
    } = {};
    _boxHoverArea: {
        domElement?: HTMLElement;
        styles?: StylesComponent;
    } = {};
    _elementId: string;
    _type: string;
    _element: any;
    _listComponents: ListComponents = {};
    _listSelectedComponents: { [key: string]: boolean; } = {};
    _positionCursor: {
        y?: number;
        x?: number;
        rotateX?: number;
        rotateY?: number;
    } = {};
    _editing: undefined | Component;
    _draggable: undefined | boolean;
    _inTransformation: undefined | string;
    _user = getUserPreferences();
    _translator = new Translator(this._user.language);
    _savedAt = (new Date().getTime() / 1000);
    _updatedAt = this._savedAt;
    _updateTimer: NodeJS.Timeout;

    get zoom() {
        return this._zoom;
    }
    set zoom(zoom: string | number) {
        this._zoom = parseFloat(zoom.toString());
    }
    get element() {
        return this._element;
    }

    set element(element) {
        this._element = element;
        //console.log(element)
        if (element.sharing == 'writer'
            || element.sharing == 'owner') {
            this._setMode('preview');
        }
    }

    get floatTextEditingBox() {
        return this._floatTextEditingBox;
    }

    get container() {
        return this._container;
    }

    set container(container) {

        this._container = container;
        this._container.classList.add(this._mode);
    }

    get context() {
        return this._context;
    }

    set context(context) {
        this._context = context;
    }

    get updatedAt() {
        return this._updatedAt;
    }

    set updatedAt(time: number) {
        this._updatedAt = time;
        //console.log('updated', time)
        this._autoSave();
    }

    constructor() {
        super();
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        switch (name) {
            case 'element-id':
                this._elementId = newValue;
                //MUST UPDATE COMMENTS
                this._loadElement();
                break;
        }
    }

    _close() {
        if (this.parentNode) {
            this.remove();
        }
    }
    _setMode(mode: string) {
        this.classList.add(mode);
        this.classList.remove(this._mode);

        if (this.container) {
            this.container.classList.add(mode);
            this.container.classList.remove(this._mode);
        }
        this._mode = mode;
        this._resetComponents();
    }
    _autoSave() {
        if (((new Date().getTime() / 1000) - this._updatedAt) < 1) {
            if (this._updateTimer) clearTimeout(this._updateTimer);
            this._updateTimer = setTimeout((evt) => this._autoSave(), 1100);
            return;
        }
        const components: Component[] = Object.entries(this._listComponents)
            .filter(([key, value]) => value.updatedAt.time > this._savedAt)
            .map(([key, value]) => value);

        if (components.length == 0) return;
        let debounce = false;
        const dataAction: DataActionCommonAttributes[] = components.map(component => {
            const listAttributes: { [key: string]: StylesComponent | string } =
                Object.entries(component.updatedAt.on).filter(on => on[1] === true)
                    .map(attr => {
                        let data: { [key: string]: StylesComponent | string; } = {};
                        data[attr[0] as unknown as string] = component.data[attr[0]];
                        return data;
                    }).reduce((prev, current) => {
                        return Object.assign(prev, current);
                    });
            if (!component.data.id) {
                debounce = true;
            }
            return {
                id: component.data.id,
                commonAttributes: listAttributes
            }
        });
        if (debounce) {
            if (this._updateTimer) clearTimeout(this._updateTimer);
            this._updateTimer = setTimeout((evt) => this._autoSave(), 1100);
            return;
        }
        //console.log(dataAction)
        let api = new Api(
            '/api3/object/update/',
            {
                login: this._user.login,
                key: this._user.key,
                query: {
                    action: "update",
                    user: this._user.login,
                    dataAction: dataAction,
                    domain: [
                        this._user.domain
                    ]
                }
            },
            {
                done: {
                    action: (self: EditToolbar, result: any, extraData: any) => {
                        //console.log('updated', result)
                    }
                },
                empty: false,
                error: {
                    action: (self: EditToolbar, dataError: any) => {
                        console.log('error', dataError);
                        let message = dataError.dataSet.message;
                        let options: { [key: string]: string } = {};
                        if (message) {
                            options.message = message;
                        }
                        console.log(options);
                    }
                }
            },
            this
        );
        api.sendRequest();
    }
    _loadElement() {
        let api = new Api(
            '/api3/object/get/',
            {
                login: this._user.login,
                key: this._user.key,
                query: {
                    action: "getLibrary",
                    user: this._user.login,
                    subType: 'all',
                    domain: [this._user.domain],
                    list: [{ id: this._elementId }],
                    //scope:["components"],
                    //projection:["parent","child"],
                    limit: { "start": 0, "end": 1 },
                }
            },
            {
                done: {
                    action: this._setElementLoaded
                },
                empty: false,
                error: false
            },
            this
        );
        api.sendRequest();
    }
    _setElementLoaded(self: EditToolbar, data: any) {
        if (!data
            || !data.dataSet
            || !data.dataSet.query) {
            console.log('error_setElementLoaded', data);
            return;
        }
        self.element = data.dataSet.query[0];
    }

    public loadComponents() {
        //console.log('loadComponents');
        let api = new Api(
            '/api3/object/get/',
            {
                login: this._user.login,
                key: this._user.key,
                query: {
                    action: "getLibrary",
                    user: this._user.login,
                    subType: 'all',
                    domain: [this._user.domain],
                    page: this._context.page,
                    parent: [{ id: this._element.id }],
                    scope: ["components"],
                    //projection:["parent","child"],
                    limit: { "start": 0, "end": 80 },
                }
            },
            {
                done: {
                    action: this._constructComponents
                },
                empty: false,
                error: false
            },
            this
        );
        api.sendRequest();
    }
    _constructComponents(self: EditToolbar, data: any) {
        if (!data
            || !data.dataSet
            || !data.dataSet.query) {
            console.log('error_constructComponents', data);
            return;
        }
        data.dataSet.query.forEach((element: any) => {
            const json: JsonComponent = (({ name, nodeType, subType, attributes, styles, externalId, id, content }) => element)(element)

            self._constructComponent(undefined, undefined, json);
        });
    }
    _createComponent(component: Component) {

        let api = new Api(
            '/api3/object/put/',
            {
                login: this._user.login,
                key: this._user.key,
                query: {
                    action: "put",
                    user: this._user.login,
                    type: component.data.subType,
                    name: component.data.name,
                    domain: [this._user.domain],
                    parent: [{ id: this.element.id }],
                    styles: component.data.styles,
                    attributes: component.data.attributes,
                    nodeType: component.data.nodeType,
                    externalId: component.data.externalId,
                    page: component.data.page,
                    depth: this._element.depth + this.element.id,
                    export: 1,
                    scope: "components"
                },
                encryption: true,
            },
            {
                done: {
                    action: (self: EditToolbar, data: any, extra: any) => {
                        if (!data?.dataSet?.query?.[0]) {
                            console.log('errordone-nodataset', data);
                            return;
                        }
                        const object = data.dataSet.query[0];
                        if (!self._listComponents[object.externalId]) {
                            console.log('errordone-nolocalobject', data);
                            return;
                        }
                        self._listComponents[object.externalId].data.id = object.id;
                    }
                },
                empty: false,
                error: {
                    action: (self: EditToolbar, dataError: any) => {
                        console.log('error', dataError);
                        let message = dataError.dataSet.message;
                        let options: { [key: string]: string } = {};
                        if (message) {
                            options.message = message;
                        }
                        console.log(options);
                    }
                }
            },
            this
        );
        api.sendRequest();
    }

    _constructToolBar(self: EditToolbar, data: any) {
        if (!data
            || !data.dataSet
            || !data.dataSet.query) {
            return;
        }

        self = self ? self : this;

        self._element = data.dataSet.query[0];
    }

    _constructComponent(nodeType?: string, subType?: string, json?: JsonComponent) {
        const zIndex = Object.keys(this._listComponents).length.toString();
        const id = uniqueId();

        const jsonComponent: JsonComponent = json ? json : {
            name: subType,
            nodeType: nodeType,
            subType: subType,
            page: this._context.page,
            attributes: {
                "data-text": subType == 'text' ? 'Ajouter un texte' : subType == 'file' ? 'Ajouter un fichier' : undefined,
                class: 'intkn-editor-slide-' + subType,
                "data-id": id,
                src: subType == 'image' ? '/office/ressources/img/intkn_image_background_slide_white.svg' : undefined
            },
            styles: {
                width: subType == 'image' ? '270px' : subType == 'video' ? '320px' : '150px',
                height: 'auto',
                minHeight: '40px',
                top: '100px',
                left: '20px',
                position: 'absolute',
                textAlign: (subType == 'file') ? 'left' : 'center',
                padding: (subType == 'text' || subType == 'file') ? '8px' : undefined,
                color: subType == 'text' ? '#000000' : undefined,
                zIndex: zIndex,
                borderWidth: "1px",
                borderColor: subType == 'image' ? '#03a9f4' : subType == 'video' ? "#ffffff" : "#dcdcdc",
                borderStyle: "solid",
                backgroundColor: subType == 'image' ? '#03a9f4' : (subType == 'text' || subType == 'file') ? '#ffffff' : undefined
            },
            externalId: id
        };

        this._listComponents[jsonComponent.externalId] = {
            data: jsonComponent,
            updatedAt: {
                time: !json ? (new Date().getTime() / 1000) : undefined,
                on: {}
            }
        };
        if (!jsonComponent.attributes || !jsonComponent.styles) {
            return;
        }
        this._appendComponent(jsonComponent);
        if (!json) {
            this._createComponent(this._listComponents[jsonComponent.externalId]);
        }

    }
    _resetComponents() {
        if (!this.container) return;
        while (this.container.firstChild) {
            this.container.firstChild.remove();
        }
        if (Object.keys(this._listComponents).length == 0) return;

        Object.entries(this._listComponents).map(component => {
            this._appendComponent(component[1].data);
        })
    }
    _appendComponent(jsonComponent: JsonComponent) {
        const file = isFile.includes(jsonComponent.subType);
        const domElement = jsonComponent.subType === 'video' ?
            jsonComponent.attributes?.minimize == 'true' ? document.createElement('img')
                : document.createElement('intkn-video') : document.createElement(jsonComponent.nodeType);
        const bodyElement = jsonComponent.subType === 'text' || file ?
            document.createElement(jsonComponent.nodeType) : undefined;

        domElement.setAttribute('class', 'intkn-editor-slide-' + (file ? 'file' : jsonComponent.subType));

        for (const [key, value] of Object.entries(jsonComponent.attributes)) {
            if (key === 'infos' || !value) {
                continue;
            }
            if (key === 'src'
                && jsonComponent.attributes.infos) {
                continue;
            }
            domElement.setAttribute(key, value);
        }
        for (const [key, value] of Object.entries(jsonComponent.styles)) {
            if (key == 'transform') {
                domElement.style.transform =
                    (jsonComponent.styles.transform?.rotate ? jsonComponent.styles.transform.rotate : '')
                    + (jsonComponent.styles.transform?.rotateY ? ' ' + jsonComponent.styles.transform.rotateY : '')
                    + (jsonComponent.styles.transform?.rotateX ? ' ' + jsonComponent.styles.transform.rotateX : '')
                continue;
            }
            domElement.style[key as unknown as number] = value;
        }

        (jsonComponent.attributes?.infos
            && jsonComponent.attributes.minimize !== 'true')
            ? file ? this._setDownloadEvent(jsonComponent, domElement as HTMLDivElement)
                : this._setMediaId(jsonComponent, domElement as HTMLImageElement | PlayerVideo)
            : this._setMinimize(jsonComponent, domElement as HTMLImageElement);

        if (this._listComponents[jsonComponent.externalId].domElement
            && this.container.contains(this._listComponents[jsonComponent.externalId].domElement)) {
            this.container.replaceChild(domElement, this._listComponents[jsonComponent.externalId].domElement);
        } else {
            this.container.appendChild(domElement);
        }
        this._listComponents[jsonComponent.externalId].domElement = domElement;

        if (jsonComponent.attributes?.infos
            && jsonComponent.attributes.minimize !== 'true') this._setMediaUrl(jsonComponent, domElement as HTMLImageElement | PlayerVideo);

        if (bodyElement) {
            bodyElement.setAttribute('class', 'intkn-editor-slide-' + (file ? 'file' : jsonComponent.subType) + '-body');
            if (jsonComponent.attributes['data-text']) {
                bodyElement.setAttribute('data-text', jsonComponent.attributes['data-text']);
            }
            if (jsonComponent.content) {
                bodyElement.innerHTML = jsonComponent.content;
            }
            domElement.classList.add(jsonComponent.subType);
            this._listComponents[jsonComponent.externalId].bodyElement = bodyElement;
            domElement.appendChild(bodyElement);
        }
        if (this._mode == 'edit') {
            const eventMethod = file ?
                '_setEventOnElementFile' : ['image', 'text', 'video'].includes(jsonComponent.subType) ?
                    '_setEventOnElement' + ucFirst(jsonComponent.subType) : undefined;

            if (eventMethod) this[eventMethod](this._listComponents[jsonComponent.externalId]);
        }
    }

    _setMinimize(jsonComponent: JsonComponent, domElement: HTMLImageElement) {

        switch (jsonComponent.subType) {
            case 'video':
                domElement.src = '/office/ressources/img/intkn_camera_white.svg'
                break;
            case 'image':
                domElement.src = '/office/ressources/img/intkn_image_background_slide_white.svg';
                break;
        }
        if (this._mode == 'edit'
            || jsonComponent.attributes.minimize != 'true'
            || !jsonComponent.attributes.infos) {
            return;
        }
        if (isFile.includes(jsonComponent.subType)) {
            this._setDownloadEvent(jsonComponent, domElement);
            return;
        }
        domElement.addEventListener('click', (evt) => {
            evt.stopPropagation();
            getMediaPlayer(jsonComponent.attributes.infos, this._user);
        });
    }
    _setDownloadEvent(jsonComponent: JsonComponent, domElement: HTMLDivElement) {
        if (this._mode == 'edit') {
            return;
        }
        domElement.addEventListener('click', (evt) => {
            evt.stopPropagation();
            donwloadMedia(jsonComponent.attributes.infos, this._user);
        });
    }
    _setMediaId(jsonComponent: JsonComponent, domElement: PlayerVideo | HTMLImageElement) {
        switch (jsonComponent.subType) {
            case 'video':
                (domElement as PlayerVideo).infos = jsonComponent.attributes.infos;
                (domElement as PlayerVideo).setAttribute('element-id', jsonComponent.attributes.infos.id)
                break;
        }
    }
    _setMediaUrl(jsonComponent: JsonComponent, domElement: PlayerVideo | HTMLImageElement, type?: string) {
        let url = '/api2/object/download/?query=';
        switch (jsonComponent.subType) {
            case 'video':
                (domElement as PlayerVideo).reduce = false;
                break;
            case 'image':
                type = 'jpg';
                const dimensions = jsonComponent.attributes.infos.dimension.split('/');
                const width = jsonComponent.styles.width.toString().split('px')[0];
                let source;
                const rect = domElement.getBoundingClientRect();
                if (jsonComponent.attributes.infos.extension == '.svg'
                    || jsonComponent.attributes.infos.extension == '.gif') {
                    type = undefined;
                    source = jsonComponent.attributes.infos.extension;
                }
                var query = {
                    action: "download",
                    user: this._user.login,
                    list: [{
                        id: jsonComponent.attributes.infos.id,
                        options: {
                            type: type,
                            source: source,
                            transfert: "inline",
                            resume: "on",
                            embeddedKey: jsonComponent.attributes.infos.embeddedKey,
                            size: {
                                width: rect.width + (rect.width * 20 / 100)
                            }
                        }
                    }]
                };
                (domElement as HTMLImageElement).src = url + encodeURIComponent(JSON.stringify(query));
        }
    }

    _setEventOnElementFile(component: Component) {
        component.domElement.addEventListener('mousedown', (evt) => {
            //console.log(component, 'mousedown')
            //evt.preventDefault();
            this._setSelectedElement(evt, component);

        });
        component.domElement.addEventListener('mouseup', (evt) => {
            this._draggable = false;
            //console.log(component, 'mouseup')
        });
        component.domElement.addEventListener('click', (evt) => {
            evt.stopPropagation();
            //console.log(component, 'click')
        });
        component.bodyElement.addEventListener('mousedown', (evt) => {
            evt.stopPropagation();
            this._setSelectedElement(evt, component);
            component.bodyElement.setAttribute('contenteditable', 'true');
            component.bodyElement.focus();
            component.recorder = component.bodyElement.innerHTML ? component.bodyElement.innerHTML : '';
            this._showFloatEditingBox(component);
            //console.log(component, 'bodyclick')
        });
        component.domElement.addEventListener('contextmenu', (evt) => {
            console.log(component, 'contextmenu')
        });

        component.bodyElement.addEventListener('keyup', (evt) => {
            evt.stopPropagation();
            this._showBoxSelectedArea();
        });
        /*component.domElement.addEventListener('dblclick', (evt) => {
            console.log(component, 'dblclick')
            component.domElement.setAttribute('contenteditable', 'true');
            component.domElement.focus();
            component.domElement.addEventListener('keyup', this._showBoxSelectedArea);
            this._showFloatEditingBox(component);
        });*/
        component.bodyElement.addEventListener('blur', (evt: FocusEvent) => {
            //console.log('bodyblur', evt)
            if (this._floatTextEditingBox.domElement
                && (this._floatTextEditingBox.domElement.contains(evt.relatedTarget as Node)
                    || this._floatTextEditingBox.pickers?.container?.contains(evt.relatedTarget as Node))
            ) {
                component.bodyElement.focus();
                return;
            }

            this._hideFloatEditingBox();
        });
    }
    _setEventOnElementText(component: Component) {
        component.domElement.addEventListener('mousedown', (evt) => {
            //console.log(component, 'mousedown')
            //evt.preventDefault();
            this._setSelectedElement(evt, component);

        });
        component.domElement.addEventListener('mouseup', (evt) => {
            this._draggable = false;
            //console.log(component, 'mouseup')
        });
        component.domElement.addEventListener('click', (evt) => {
            evt.stopPropagation();
            //console.log(component, 'click')
        });
        component.bodyElement.addEventListener('mousedown', (evt) => {
            evt.stopPropagation();
            this._setSelectedElement(evt, component);
            component.bodyElement.setAttribute('contenteditable', 'true');
            component.bodyElement.focus();
            component.recorder = component.bodyElement.innerHTML ? component.bodyElement.innerHTML : '';
            this._showFloatEditingBox(component);
            //console.log(component, 'bodyclick')
        });
        component.domElement.addEventListener('contextmenu', (evt) => {
            console.log(component, 'contextmenu')
        });

        component.bodyElement.addEventListener('keyup', (evt) => {
            evt.stopPropagation();
            this._showBoxSelectedArea();
        });
        /*component.domElement.addEventListener('dblclick', (evt) => {
            console.log(component, 'dblclick')
            component.domElement.setAttribute('contenteditable', 'true');
            component.domElement.focus();
            component.domElement.addEventListener('keyup', this._showBoxSelectedArea);
            this._showFloatEditingBox(component);
        });*/
        component.bodyElement.addEventListener('blur', (evt: FocusEvent) => {
            //console.log('bodyblur', evt)
            if (this._floatTextEditingBox.domElement
                && (this._floatTextEditingBox.domElement.contains(evt.relatedTarget as Node)
                    || this._floatTextEditingBox.pickers?.container?.contains(evt.relatedTarget as Node))
            ) {
                component.bodyElement.focus();
                return;
            }

            this._hideFloatEditingBox();
        });
    }
    _setEventOnElementImage(component: Component) {
        component.domElement.setAttribute('tabindex', '0');
        component.domElement.setAttribute('draggable', 'false');
        component.domElement.addEventListener('mousedown', (evt) => {
            //console.log(component, 'mousedown')
            //evt.preventDefault();
            this._setSelectedElement(evt, component);

        });

        component.domElement.addEventListener('mouseup', (evt) => {
            //component.domElement.focus();
            this._draggable = false;
            //console.log(component, 'mouseup')
        });
        if (this._mode !== 'edit') {
            component.domElement.addEventListener('click', (evt) => {
                evt.stopPropagation();
                component.domElement.focus();
                //console.log(component, 'click')
            });
        } else {
            component.domElement.addEventListener('click', (evt) => {
                evt.stopPropagation();
                component.domElement.focus();
                //console.log(component, 'click')
            });
        }
        /*component.domElement.addEventListener('contextmenu', (evt) => {
            console.log(component, 'contextmenu')
        });
        component.domElement.addEventListener('dblclick', (evt) => {
            console.log(component, 'dblclick')
        });
        component.domElement.addEventListener('blur', (evt: FocusEvent) => {
 
        });*/
    }

    _setEventOnElementVideo(component: Component) {
        component.domElement.addEventListener('mousedown', (evt) => {
            //console.log(component, 'mousedown')
            //evt.preventDefault();
            this._setSelectedElement(evt, component);
        });
        component.domElement.addEventListener('click', (evt) => {
            evt.stopPropagation();
            component.domElement.focus();
            //console.log(component, 'click')
        });

        component.domElement.addEventListener('mouseup', (evt) => {
            this._draggable = false;
            //console.log(component, 'mouseup')
        });

        /*component.domElement.addEventListener('contextmenu', (evt) => {
            console.log(component, 'contextmenu')
        });
        component.domElement.addEventListener('dblclick', (evt) => {
            console.log(component, 'dblclick')
        });
        component.domElement.addEventListener('blur', (evt: FocusEvent) => {
 
        });*/
    }

    _setSelectedElement(evt: MouseEvent, component: Component) {
        if (!evt.ctrlKey) {
            if (!this._listSelectedComponents[component.data.externalId]) {
                this._resetListSelectedElements();
            }
        } else {
            this._hideFloatEditingBox();
        }
        if (!this._listSelectedComponents[component.data.externalId]) {
            this._listSelectedComponents[component.data.externalId] = true;
        }
        this._showBoxSelectedArea();
        this._showFloatTopBox();
        this._setEnableBoxMove(evt);
    }

    _resetListSelectedElements() {
        this._hideFloatEditingBox();
        Object.entries(this._listSelectedComponents).map(([key, value]) => delete this._listSelectedComponents[key]);
    }
    _deleteSelectedElements() {
        //console.log('_deleteSelectedElements')
        const deletedComponents: JsonComponent[] = [];
        Object.entries(this._listSelectedComponents).map(([key, value]) => {
            //MUST DELETE WITH API
            this._listComponents[key].domElement?.remove();
            deletedComponents.push(Object.assign({}, this._listComponents[key].data));
            delete this._listComponents[key];
        });
        this._hideBoxSelectedArea();
        this._hideFloatTopBox();
        this._resetListSelectedElements();
        this._deleteComponentSender(deletedComponents)
    }
    _deleteComponentSender(deletedComponents: JsonComponent[]) {

        let dataAction: {
            id: string,
            type: string,
            users?: [any]
        }[] = [];

        deletedComponents.forEach(json => {

            dataAction.push(
                {
                    id: json.id,
                    type: 'media',
                    users: [{
                        id: this._user.login,
                        setAction: {
                            deleteShare: "true",
                            deleteLink: [
                                { id: this.element.id }
                            ]
                        }
                    }]
                }
            )
        });

        const query = {
            action: "delete",
            user: this._user.login,
            dataAction: dataAction,
            domain: [
                this._user.domain
            ]
        };
        const callBacks: any = {
            done: {
                action: (self: EditToolbar, data: any, extra: any) => {
                    console.log('deleted');
                }
            },
            empty: false,
            error: {
                action: (self: EditToolbar, dataError: any) => {
                    console.log('error', dataError);
                    let message = dataError.dataSet.message;
                    let options: { [key: string]: string } = {};
                    if (message) {
                        options.message = message;
                    }
                    console.log(options);
                }
            }
        };

        let api = new Api(
            '/api2/object/delete/',
            {
                login: this._user.login,
                key: this._user.key,
                signature: 1,
                query: query
            },
            callBacks,
            this
        );
        api.sendRequest();
    }
    _setEnableBoxTransform(evt: MouseEvent) {
        if (this._inTransformation) return;
        this._positionCursor.x = evt.clientX;
        this._positionCursor.y = evt.clientY;
        this._draggable = false;
        this._inTransformation = (evt.target as HTMLElement).getAttribute('data-handler');
        this.container.classList.add('noSelection');
        Object.entries(this._listSelectedComponents).map(
            ([key, value]) => {
                const target = this._listComponents[key].domElement;
                const data = this._listComponents[key].data;
                const size: BoxSize = {
                    left: target.offsetLeft,
                    top: target.offsetTop,
                    width: target.offsetWidth,
                    height: target.offsetHeight,
                    cx: target.offsetLeft + target.offsetWidth / 2,
                    cy: target.offsetTop + target.offsetHeight / 2,
                    angle: this._getAngleFromData(data)
                };
                this._listComponents[key].initialSize = size;
                this._setPositionForAngle(target, size, data);
            }
        );
    };
    _setPositionForAngle(target: HTMLElement, size: BoxSize, data: JsonComponent) {
        const coord = this._getCoordinates(size, size.angle);
        switch (this._inTransformation) {
            case 'bottomRight':
            case 'right':
            case 'bottom':
                // toFixPos = 'TopLeft';
                //resizer.style.transformOrigin = 'top left';
                target.style.transformOrigin = 'top left';
                target.style.left = coord.ax + 'px';
                target.style.top = coord.ay + 'px';
                if (data.styles.transform?.rotateX == 'rotateX(180deg)'
                    && data.styles.transform?.rotateY == 'rotateY(180deg)') {
                    target.style.transform += 'translateX(-100%)';
                    target.style.transform += 'translateY(-100%)';
                } else if (data.styles.transform?.rotateY == 'rotateY(180deg)') {
                    target.style.transform += 'translateX(-100%)';
                } else if (data.styles.transform?.rotateX == 'rotateX(180deg)') {
                    target.style.transform += 'translateY(-100%)';
                }
                break;
            case 'bottomLeft':
            case 'left':
                // toFixPos = 'TopRight';
                //resizer.style.transformOrigin = 'top right';
                target.style.transformOrigin = 'top right';
                target.style.left = coord.bx - size.width + 'px';
                target.style.top = coord.by + 'px';
                if (data.styles.transform?.rotateX == 'rotateX(180deg)'
                    && data.styles.transform?.rotateY == 'rotateY(180deg)') {
                    target.style.transform += 'translateX(100%)';
                    target.style.transform += 'translateY(-100%)';
                } else if (data.styles.transform?.rotateY == 'rotateY(180deg)') {
                    target.style.transform += 'translateX(100%)';
                } else if (data.styles.transform?.rotateX == 'rotateX(180deg)') {
                    target.style.transform += 'translateY(-100%)';
                }
                break;
            case 'topRight':
                // toFixPos = 'BottomLeft';
                //resizer.style.transformOrigin = 'bottom left';
                target.style.transformOrigin = 'bottom left';
                target.style.left = coord.dx + 'px';
                target.style.top = coord.dy - size.height + 'px';
                if (data.styles.transform?.rotateX == 'rotateX(180deg)'
                    && data.styles.transform?.rotateY == 'rotateY(180deg)') {
                    target.style.transform += 'translateX(-100%)';
                    target.style.transform += 'translateY(100%)';
                } else if (data.styles.transform?.rotateY == 'rotateY(180deg)') {
                    target.style.transform += 'translateX(-100%)';
                } else if (data.styles.transform?.rotateX == 'rotateX(180deg)') {
                    target.style.transform += 'translateY(100%)';
                }
                break;
            case 'topLeft':
            case 'top':
                // toFixPos = 'BottomRight';
                //resizer.style.transformOrigin = 'bottom right';
                if (data.styles.transform?.rotateX == 'rotateX(180deg)'
                    && data.styles.transform?.rotateY == 'rotateY(180deg)') {
                    target.style.transform += 'translateX(100%)';
                    target.style.transform += 'translateY(100%)';
                } else if (data.styles.transform?.rotateY == 'rotateY(180deg)') {
                    target.style.transform += 'translateX(100%)';
                } else if (data.styles.transform?.rotateX == 'rotateX(180deg)') {
                    target.style.transform += 'translateY(100%)';
                }
                target.style.transformOrigin = 'bottom right';
                target.style.left = coord.cx - size.width + 'px';
                target.style.top = coord.cy - size.height + 'px';
                break;
            default:
                break;
        }
    }
    _getAngle(target: HTMLElement) {
        let angle = 0;
        if (target) {
            angle = 0;
            const tmatrix = window.getComputedStyle(target).transform;
            if (tmatrix && tmatrix != 'none') {
                const matrixValues = tmatrix.split('(')[1].split(')')[0].split(',');
                const matrix_a = parseFloat(matrixValues[0]);
                const matrix_b = parseFloat(matrixValues[1]);
                const rotationInRadians = Math.atan2(matrix_b, matrix_a);
                angle = rotationInRadians * (180 / Math.PI);
                if (angle < 0) {
                    angle = 360 + angle;
                }
            }
        }
        return Math.round(angle);
    };
    _getAngleFromData(jsonComponent: JsonComponent) {

        return jsonComponent.styles.transform?.rotate
            ? parseInt(jsonComponent.styles.transform?.rotate.split('rotate(')[1].split(')')[0]) : 0;
    }
    _getCoordinates(size: BoxSize, angle: number) {
        /*
                      a(x,y) _____________________________  b(x,y)
                            |                             |
                            |                             |
                            |                             |
                            |                             |
                      d(x,y)|_____________________________| c(x,y)               
             */
        const radians = (Math.PI / -180) * angle;
        const cos = Math.cos(radians);
        const sin = Math.sin(radians);
        const x1 = size.left;
        const y1 = size.top;
        const x2 = x1 + size.width;
        const y2 = y1;
        const x3 = x1 + size.width;
        const y3 = y1 + size.height;
        const x4 = x1;
        const y4 = y1 + size.height;
        const px = x1 + size.width / 2;
        const py = y1 + size.height / 2;
        // console.table({ angle, radians, cos, sin, x1, y1, x2, y2, x3, y3, x4, y4, px, py });

        const ax = cos * (x1 - px) + sin * (y1 - py) + px;
        const ay = cos * (y1 - py) - sin * (x1 - px) + py;

        const bx = cos * (x2 - px) + sin * (y2 - py) + px;
        const by = cos * (y2 - py) - sin * (x2 - px) + py;

        const cx = cos * (x3 - px) + sin * (y3 - py) + px;
        const cy = cos * (y3 - py) - sin * (x3 - px) + py;

        const dx = cos * (x4 - px) + sin * (y4 - py) + px;
        const dy = cos * (y4 - py) - sin * (x4 - px) + py;

        return { ax, ay, bx, by, cx, cy, dx, dy, px, py };
    };
    _adjustRotate(size: BoxSize, oldSize: BoxSize, angle: number) {
        let toFixPos = 'TopLeft';
        switch (this._inTransformation) {
            case 'bottomRight':
            case 'right':
            case 'bottom':
                toFixPos = 'topLeft';
                break;
            case 'left':
            case 'bottomLeft':
                toFixPos = 'topRight';
                break;
            case 'topLeft':
            case 'top':
                toFixPos = 'bottomRight';
                break;
            case 'topRight':
                toFixPos = 'bottomLeft';
                break;
        }
        const s1 = this._getCoordinates(oldSize, angle);
        const s2 = this._getCoordinates(size, angle);
        /* console.log('--------------------');
        console.log('oldSize:=', oldSize);
        console.log('s1:=', s1);
        console.log('newSize:=', size);
        console.log('s2:=', s2);
        console.log(toFixPos);*/
        let newX = 0;
        let newY = 0;

        switch (toFixPos) {
            case 'topLeft':
                newX = size.left + s1.ax - s2.ax;
                newY = size.top + s1.ay - s2.ay;
                break;
            case 'topRight':
                newX = size.left + s1.bx - s2.bx;
                newY = size.top + s1.by - s2.by;
                break;
            case 'bottomRight':
                newX = size.left + s1.cx - s2.cx;
                newY = size.top + s1.cy - s2.cy;
                break;
            case 'bottomLeft':
                newX = size.left + s1.dx - s2.dx;
                newY = size.top + s1.dy - s2.dy;
                break;
            default:
                newX = size.left + s1.ax - s2.ax;
                newY = size.top + s1.ay - s2.ay;
                break;
        }
        return {
            x: Math.round(newX),
            y: Math.round(newY),
        };
    };
    _setEnableBoxMove(evt: MouseEvent) {
        this._positionCursor.x = evt.clientX;
        this._positionCursor.y = evt.clientY;
        this._inTransformation = undefined;
        this._draggable = true;
        this.container.classList.add('noSelection');
    };

    _setDisableBoxMove(evt: MouseEvent) {
        //evt.stopPropagation();
        evt.preventDefault();

        this.container.classList.remove('noSelection');

        Object.entries(this._listSelectedComponents).map(
            ([key, value]) => {
                const target = this._listComponents[key].domElement;
                const angle = this._getAngleFromData(this._listComponents[key].data);
                if (this._inTransformation
                    && this._inTransformation != 'rotate'
                    && angle) {
                    //RECONTRUCT POSITION AFTER RESIZE
                    target.style.left = this._listComponents[key].data.styles.left.toString();
                    target.style.top = this._listComponents[key].data.styles.top.toString();
                    target.style.transformOrigin = '';
                    target.style.transform = target.style.transform.split('translateY')[0].split('translateX')[0];
                }
            }
        );

        this._draggable = false;
        this._inTransformation = undefined;
        this._boxSelectedArea.domElement.style.transformOrigin = '';
        this._showBoxSelectedArea();
        //console.log('_setDisableBoxMove');
    }
    _setBoxMove(evt: MouseEvent) {
        if (this._editing) return;
        if (this._inTransformation) this._setBoxTransform(evt);
        if (!this._draggable) { /*console.log('nodragable');*/ return; }
        evt.stopPropagation();
        evt.preventDefault();
        const rectContainer = {
            right: this.container.offsetLeft + this.container.offsetWidth,
            bottom: this.container.offsetTop + this.container.offsetHeight
        }
        const primaryDistanceX = (evt.clientX - this._positionCursor.x);
        const primaryDistanceY = (evt.clientY - this._positionCursor.y);
        const distanceX = this._getScaledDistance(primaryDistanceX);
        const distanceY = this._getScaledDistance(primaryDistanceY);
        this._positionCursor.x = evt.clientX;
        this._positionCursor.y = evt.clientY;
        const updated = (new Date().getTime() / 1000);
        Object.entries(this._listSelectedComponents).map(
            ([key, value]) => {
                const rectComponent = {
                    width: this._listComponents[key].domElement.offsetWidth,
                    height: this._listComponents[key].domElement.offsetHeight
                };

                let boxPosLeft = Math.round(this._listComponents[key].domElement.offsetLeft + distanceX);
                if (boxPosLeft < 0) {
                    boxPosLeft = 0;
                    this._setDisableBoxMove(evt);
                } else if (boxPosLeft > (rectContainer.right - rectComponent.width)) {
                    boxPosLeft = Math.round(rectContainer.right - rectComponent.width);
                    this._setDisableBoxMove(evt);
                }
                this._listComponents[key].domElement.style.left = boxPosLeft + 'px';
                this._listComponents[key].data.styles.left = boxPosLeft + 'px';

                let boxPosTop = this._listComponents[key].domElement.offsetTop + distanceY;
                if (boxPosTop < 0) {
                    boxPosTop = 0;
                    this._setDisableBoxMove(evt);
                } else if (boxPosTop > (rectContainer.bottom - rectComponent.height)) {
                    boxPosTop = Math.round(rectContainer.bottom - rectComponent.height);
                    this._setDisableBoxMove(evt);
                }
                this._listComponents[key].domElement.style.top = boxPosTop + 'px';
                this._listComponents[key].data.styles.top = boxPosTop + 'px';
                this._listComponents[key].updatedAt.time = updated;
                this._listComponents[key].updatedAt.on = Object.assign(this._listComponents[key].updatedAt.on, { styles: true });
            }
        );
        this.updatedAt = updated;
        this._showBoxSelectedArea();
        this._hideFloatTopBox();
    }
    _setBoxTransform(evt: MouseEvent) {
        //console.log('_setBoxTransform');
        evt.stopPropagation();
        evt.preventDefault();
        const rectContainer = {
            width: this.container.offsetWidth,
            height: this.container.offsetHeight
        }

        const primaryDistanceX = (evt.clientX - this._positionCursor.x);
        const primaryDistanceY = (evt.clientY - this._positionCursor.y);
        let distanceX = this._getScaledDistance(primaryDistanceX);
        let distanceY = this._getScaledDistance(primaryDistanceY);

        const updated = (new Date().getTime() / 1000);

        Object.entries(this._listSelectedComponents).map(
            ([key, value]) => {
                const target = this._listComponents[key].domElement;
                const rect = target.getBoundingClientRect();
                const currentSize: BoxSize = {
                    left: target.offsetLeft,
                    top: target.offsetTop,
                    width: target.offsetWidth,
                    height: target.offsetHeight,
                    cx: rect.left + rect.width / 2,
                    cy: rect.top + rect.height / 2,
                    angle: this._getAngleFromData(this._listComponents[key].data)
                };
                if (currentSize.angle) {
                    const oldXY = this._rotateXY(currentSize.cx, currentSize.cy, this._positionCursor.x, this._positionCursor.y, currentSize.angle);
                    const newXY = this._rotateXY(currentSize.cx, currentSize.cy, evt.clientX, evt.clientY, currentSize.angle);
                    distanceX = this._getScaledDistance(newXY.x - oldXY.x);
                    distanceY = this._getScaledDistance(newXY.y - oldXY.y);

                }
                let width: number;
                let height: number;
                let left: number;
                let top: number;
                let angle: number;
                const minWidth = 80;
                const minHeight = 80;
                const aspectRatio = true;
                switch (this._inTransformation) {
                    case 'se-resizer':
                    case 'bottomRight':
                        width = currentSize.width + distanceX;
                        height = currentSize.height + distanceY;

                        if (width < minWidth) {
                            width = minWidth;
                        }
                        if (height < minHeight) {
                            height = minHeight;
                        }
                        if (aspectRatio) {
                            const hr = currentSize.height / currentSize.width;
                            height = width * hr;
                            if (height < minHeight) {
                                height = minHeight;
                                const wr = currentSize.width / currentSize.height;
                                width = height * wr;
                            }
                        }
                        width = width;
                        height = height;
                        break;
                    case 'sw-resizer':
                    case 'bottomLeft':
                        width = currentSize.width - distanceX;
                        height = currentSize.height + distanceY;
                        if (width < minWidth) {
                            width = minWidth;
                        }
                        if (height < minHeight) {
                            height = minHeight;
                        }
                        if (aspectRatio) {
                            const hr = currentSize.height / currentSize.width;
                            height = width * hr;
                        }
                        width = width;
                        height = height;
                        left = currentSize.left + currentSize.width - width;
                        break;
                    case 'ne-resizer':
                    case 'topRight':
                        width = currentSize.width + distanceX;
                        height = currentSize.height - distanceY;
                        if (width < minWidth) {
                            width = minWidth;
                        }
                        if (height < minHeight) {
                            height = minHeight;
                        }
                        if (aspectRatio) {
                            const hr = currentSize.height / currentSize.width;
                            height = width * hr;
                        }
                        width = width;
                        height = height;
                        top = currentSize.top + currentSize.height - height;
                        break;
                    case 'nw-resizer':
                    case 'topLeft':
                        width = currentSize.width - distanceX;
                        height = currentSize.height - distanceY;
                        if (width < minWidth) {
                            width = minWidth;
                        }
                        if (height < minHeight) {
                            height = minHeight;
                        }
                        if (aspectRatio) {
                            const hr = currentSize.height / currentSize.width;
                            height = width * hr;
                        }
                        width = width;
                        height = height;
                        top = currentSize.top + currentSize.height - height;
                        left = currentSize.left + currentSize.width - width;
                        break;
                    case 'n-resizer':
                    case 'top':
                        height = currentSize.height - distanceY;
                        if (height < minHeight) {
                            height = minHeight;
                        }
                        //width = currentSize.width;
                        height = height;
                        top = currentSize.top + currentSize.height - height;
                        break;
                    case 'e-resizer':
                    case 'right':
                        width = currentSize.width + distanceX;
                        if (width < minWidth) {
                            width = minWidth;
                        }
                        width = width;
                        //height = currentSize.height;
                        break;
                    case 's-resizer':
                    case 'bottom':
                        height = currentSize.height + distanceY;
                        if (height < minHeight) {
                            height = minHeight;
                        }
                        //width = currentSize.width;
                        height = height;
                        break;
                    case 'w-resizer':
                    case 'left':
                        width = currentSize.width - distanceX;
                        if (width < minWidth) {
                            width = minWidth;
                        }
                        width = width;
                        //height = currentSize.height;
                        left = currentSize.left + currentSize.width - width;
                        break;
                    case 'rotate':
                        angle = Math.atan2(evt.clientX - currentSize.cx, -(evt.clientY - currentSize.cy)) * (180 / Math.PI) - 180;
                        angle = angle >= 0 ? angle : 360 + angle;
                        angle = currentSize.angle + (Math.round(angle) - currentSize.angle);
                    //showAngleValue(x, y, angle);
                }



                if (width) {
                    width = Math.round(width);
                    this._listComponents[key].domElement.style.width = width + 'px';
                    this._listComponents[key].data.styles.width = width + 'px';
                }
                if (height) {
                    height = Math.round(height);
                    this._listComponents[key].domElement.style.height = height + 'px';
                    this._listComponents[key].data.styles.height = height + 'px';
                }
                if (top) {
                    this._listComponents[key].domElement.style.top = Math.round(top) + 'px';
                }
                if (left) {
                    this._listComponents[key].domElement.style.left = Math.round(left) + 'px';
                }

                if (angle) {
                    angle = Math.round(angle);
                    if (target.style.transform.indexOf('rotate') >= 0) {
                        target.style.transform = target.style.transform.replace(/rotate\(\w*\)/gi, 'rotate(' + angle + 'deg)');
                    } else {
                        target.style.transform += ' rotate(' + angle + 'deg)';
                    }
                    if (!this._listComponents[key].data.styles.transform) {
                        this._listComponents[key].data.styles.transform = {};
                    }
                    this._listComponents[key].data.styles.transform.rotate = 'rotate(' + angle + 'deg)';
                }

                const initialSize = this._listComponents[key].initialSize;



                if (initialSize.angle && this._inTransformation != 'rotate') {

                    const newSize = {
                        left: initialSize.left,
                        top: initialSize.top,
                        width: target.offsetWidth,
                        height: target.offsetHeight,
                    };
                    const adj = this._adjustRotate(newSize, initialSize, initialSize.angle);

                    if (adj.x !== initialSize.left) {
                        this._listComponents[key].data.styles.left = adj.x + 'px';
                    }
                    if (adj.y !== initialSize.top) {
                        this._listComponents[key].data.styles.top = adj.y + 'px';
                    }
                }
                this._listComponents[key].updatedAt.time = updated;
                this._listComponents[key].updatedAt.on = Object.assign(this._listComponents[key].updatedAt.on, { styles: true });
            }
        );
        this._positionCursor.x = evt.clientX;
        this._positionCursor.y = evt.clientY;
        this.updatedAt = updated;
        this._showBoxSelectedArea();
        this._hideFloatTopBox();
    }
    _rotateXY = (cx: number, cy: number, x: number, y: number, angle: number) => {
        let radians = (Math.PI / 180) * angle,
            cos = Math.cos(radians),
            sin = Math.sin(radians),
            nx = cos * (x - cx) + sin * (y - cy) + cx,
            ny = cos * (y - cy) - sin * (x - cx) + cy;
        return { x: nx, y: ny };
    };
    _getScaledDistance(distance: number) {
        //console.log(Math.round(distance / this._zoom), distance, this._zoom);
        return Math.round(distance / parseFloat(this._zoom.toString()));
    };
    //FLOAT TOP MENU
    //FLOAT MENU TEXT
    _showFloatTopBox() {

        if (!this._floatTopBox.domElement) this._constructFloatTopBox();
        if (Object.entries(this._listSelectedComponents).length > 1) {
            this._hideFloatTopBox();
            return;
        }
        Object.entries(this._listSelectedComponents).map(([key, value]) => {
            const rect = {
                left: this._listComponents[key].domElement.offsetLeft,
                right: (this._listComponents[key].domElement.offsetLeft + this._listComponents[key].domElement.offsetWidth),
                top: this._listComponents[key].domElement.offsetTop,
                bottom: (this._listComponents[key].domElement.offsetTop + this._listComponents[key].domElement.offsetHeight),
            };
            this._floatTopBox.styles = {
                left: (rect.left) + 'px',
                width: 'auto',
                top: (rect.top - 35) + 'px',
                height: 'auto',
                position: 'absolute',
                borderWidth: '2px'
            };

            const buttonsAll = this._floatTopBox.domElement
                .querySelectorAll('button');
            buttonsAll?.forEach((node) => {
                node.classList.add('none');
            });
            const buttonsDefault = this._floatTopBox.domElement
                .querySelectorAll('.default');
            buttonsDefault?.forEach((node) => {
                node.classList.remove('none');
            });
            const buttons = this._floatTopBox.domElement
                .querySelectorAll('.' + this._listComponents[key].data.subType);
            buttons?.forEach((node) => {
                node.classList.remove('none');
            });
            const minimizeButton = this._floatTopBox.domElement.querySelector('.image.transform');
            if (this._listComponents[key].data.attributes?.minimize == 'true') {
                minimizeButton.classList.add('cover');
            } else {
                minimizeButton.classList.remove('cover');
            }
            if (isFile.includes(this._listComponents[key].data.subType)) {
                const fileButton = this._floatTopBox.domElement.querySelector('.file.upload');
                fileButton.classList.remove('none');
                minimizeButton.classList.remove('none');
            }

            const buttonFlipV = this._floatTopBox.domElement
                .querySelector('.flipV');
            buttonFlipV.classList.remove('selected');
            if (this._listComponents[key].data.styles.transform?.rotateX == 'rotateX(180deg)') {
                buttonFlipV.classList.add('selected');
            }
            const buttonFlipH = this._floatTopBox.domElement
                .querySelector('.flipH');
            buttonFlipH.classList.remove('selected');
            if (this._listComponents[key].data.styles.transform?.rotateY == 'rotateY(180deg)') {
                buttonFlipH.classList.add('selected');
            }
        });
        for (const [key, value] of Object.entries(this._floatTopBox.styles)) {
            this._floatTopBox.domElement.style[key as unknown as number] = value
        }
        this.container.appendChild(this._floatTopBox.domElement);
    }
    _hideFloatTopBox() {
        if (!this.container.contains(this._floatTopBox.domElement)) return;
        this.container.removeChild(this._floatTopBox.domElement);
    }
    _constructFloatTopBox() {
        this._floatTopBox.domElement = document.createElement('div');
        this._floatTopBox.domElement.setAttribute('class', 'intkn-editor-box-float-top');
        this._floatTopBox.domElement.innerHTML = `
                <button class="default parameters"></button>
                <button class="image upload none"></button>
                <button class="video upload none"></button>
                <button class="file upload none"></button>
                <button class="url none"></button>
                <button class="settings none"></button>
                <button class="image video file transform none"></button>
                <button class="image flipH none"></button>
                <button class="image flipV none"></button>
                <button class="animations none"></button>
                <button class="interactions none"></button>
                <button class="default delete"></button>`; //

        const deleteButton = this._floatTopBox.domElement.querySelector('.delete');
        deleteButton.addEventListener('click', (evt) => {
            this._deleteSelectedElements();
        });
        const parametersButton = this._floatTopBox.domElement.querySelector('.parameters');
        parametersButton.addEventListener('click', (evt) => {
            evt.stopPropagation();
            Object.entries(this._listSelectedComponents).map(([key, value]) => {
                this._showFloatEditingBox(this._listComponents[key]);
            });
        });
        const imageButton = this._floatTopBox.domElement.querySelector('.image.upload');
        imageButton.addEventListener('click', (evt) => {
            evt.stopPropagation();
            Object.entries(this._listSelectedComponents).map(([key, value]) => {
                this._constructPopupImageUpload();
            });
        });
        const videoButton = this._floatTopBox.domElement.querySelector('.video.upload');
        videoButton.addEventListener('click', (evt) => {
            evt.stopPropagation();
            Object.entries(this._listSelectedComponents).map(([key, value]) => {
                this._constructPopupVideoUpload();
            });
        });
        const fileButton = this._floatTopBox.domElement.querySelector('.file.upload');
        fileButton.addEventListener('click', (evt) => {
            evt.stopPropagation();
            Object.entries(this._listSelectedComponents).map(([key, value]) => {
                this._constructPopupFileUpload();
            });
        });
        const fitButton = this._floatTopBox.domElement.querySelector('.image.transform');
        fitButton.addEventListener('click', (evt) => {
            evt.stopPropagation();
            Object.entries(this._listSelectedComponents).map(([key, value]) => {
                let v = "true";
                if (this._listComponents[key].data.attributes?.minimize === 'true') {
                    v = "false";
                }
                this._attributeUpdate(this._listComponents[key], 'minimize', v);
                this._appendComponent(this._listComponents[key].data);
            });

            this._showFloatTopBox();
            this._hideBoxSelectedArea();
            setTimeout((evt) => {
                if (Object.entries(this._listSelectedComponents).length > 0)
                    this._showBoxSelectedArea();
            }, 150);
        });

        const flipVButton = this._floatTopBox.domElement.querySelector('.flipV');
        flipVButton.addEventListener('click', (evt) => {
            evt.stopPropagation();
            Object.entries(this._listSelectedComponents).map(([key, value]) => {
                this._setBoxFlip('x', this._listComponents[key]);
            });
        });
        const flipHButton = this._floatTopBox.domElement.querySelector('.flipH');
        flipHButton.addEventListener('click', (evt) => {
            evt.stopPropagation();
            Object.entries(this._listSelectedComponents).map(([key, value]) => {
                this._setBoxFlip('y', this._listComponents[key]);
            });
        });
    }
    //POPUP
    _constructPopupVideoUpload() {
        if (!this._popups.video) this._popups.video = new IntknPopupUpload();
        this._popups.video.element = this._element;
        this._popups.video.scope = ['playerLibrary'];
        this._popups.video.title = 'addavideo';
        this._popups.video.type = 'video';
        this._popups.video.addEventListener('intkn-popup-upload-add', (evt: CustomEvent) => {
            const updated = (new Date().getTime() / 1000);
            Object.entries(this._listSelectedComponents).map(([key, value]) => {
                this._listComponents[key].data.attributes.infos = {
                    id: evt.detail.element.id,
                    embeddedKey: evt.detail.element.embeddedKey,
                    scope: evt.detail.element.scope,
                    export: evt.detail.element.export,
                    dimension: evt.detail.element.dimension,
                    type: 'video',
                    extension: evt.detail.element.extension
                }
                this._listComponents[key].data.styles.height = 'auto';
                this._listComponents[key].updatedAt.time = updated;
                this._listComponents[key].updatedAt.on =
                    Object.assign(this._listComponents[key].updatedAt.on, { attributes: true, styles: true });
                this._appendComponent(this._listComponents[key].data);
            });
            this._hideFloatEditingBox();
            this._hideBoxSelectedArea();
            this._hideFloatTopBox();

            this.updatedAt = updated;
        });
        document.body.appendChild(this._popups.video);
    }

    _constructPopupImageUpload() {
        if (!this._popups.image) this._popups.image = new IntknPopupUpload();
        this._popups.image.element = this._element;
        this._popups.image.scope = ['playerLibrary'];
        this._popups.image.title = 'addaimage';
        this._popups.image.type = 'image';
        this._popups.image.addEventListener('intkn-popup-upload-add', (evt: CustomEvent) => {
            const updated = (new Date().getTime() / 1000);
            Object.entries(this._listSelectedComponents).map(([key, value]) => {
                this._listComponents[key].data.attributes.infos = {
                    id: evt.detail.element.id,
                    embeddedKey: evt.detail.element.embeddedKey,
                    scope: evt.detail.element.scope,
                    export: evt.detail.element.export,
                    dimension: evt.detail.element.dimension,
                    type: 'image',
                    extension: evt.detail.element.extension
                };
                this._listComponents[key].data.styles.height = 'auto';
                this._listComponents[key].updatedAt.time = updated;
                this._listComponents[key].updatedAt.on =
                    Object.assign(this._listComponents[key].updatedAt.on, { attributes: true, styles: true });
                this._appendComponent(this._listComponents[key].data);
            });
            this._hideFloatEditingBox();
            this._hideBoxSelectedArea();
            this._hideFloatTopBox();
            this.updatedAt = updated;
        });
        document.body.appendChild(this._popups.image);
    }

    _constructPopupFileUpload() {
        if (!this._popups.file) this._popups.file = new IntknPopupUpload();
        this._popups.file.element = this._element;
        this._popups.file.scope = ['playerLibrary'];
        this._popups.file.title = 'addafile';
        this._popups.file.type = 'file';
        this._popups.file.addEventListener('intkn-popup-upload-add', (evt: CustomEvent) => {
            const updated = (new Date().getTime() / 1000);
            Object.entries(this._listSelectedComponents).map(([key, value]) => {
                this._listComponents[key].data.attributes.infos = {
                    id: evt.detail.element.id,
                    embeddedKey: evt.detail.element.embeddedKey,
                    scope: evt.detail.element.scope,
                    export: evt.detail.element.export,
                    dimension: evt.detail.element.dimension,
                    type: evt.detail.element.subType,
                    extension: evt.detail.element.extension
                }
                this._listComponents[key].data.subType = evt.detail.element.subType;
                this._listComponents[key].data.content = evt.detail.element.name;
                this._listComponents[key].data.styles.height = 'auto';
                this._listComponents[key].updatedAt.time = updated;
                this._listComponents[key].updatedAt.on =
                    Object.assign(this._listComponents[key].updatedAt.on, { attributes: true, styles: true, content: true, subType: true });
                this._appendComponent(this._listComponents[key].data);
            });
            this._hideFloatEditingBox();
            this._hideBoxSelectedArea();
            this._hideFloatTopBox();

            this.updatedAt = updated;
        });
        document.body.appendChild(this._popups.file);
    }
    //FLOAT MENU TEXT
    _showFloatEditingBox(component: Component) {
        if (!component) return;
        this._editing = component;
        if (!this._floatTextEditingBox.domElement) this._constructFloatEditingBox();
        const rect = {
            left: component.domElement.offsetLeft,
            right: (component.domElement.offsetLeft + component.domElement.offsetWidth),
            top: component.domElement.offsetTop,
            bottom: (component.domElement.offsetTop + component.domElement.offsetHeight),
        };
        const domRect = rect;
        this._floatTextEditingBox.styles = {
            left: (domRect.left - 150 - 10) + 'px',
            width: 'auto',
            top: (domRect.top) + 'px',
            height: 'auto',
            position: 'absolute',
            borderWidth: '2px'
        };
        for (const [key, value] of Object.entries(this._floatTextEditingBox.styles)) {
            this._floatTextEditingBox.domElement.style[key as unknown as number] = value
        }

        if (component.bodyElement) {
            this._floatTextEditingBox.domElement.querySelectorAll('.text-enable').forEach((node) => {
                node.classList.remove('none');
            })
            this._floatTextEditingBox.domElement.querySelectorAll('.media-enable').forEach((node) => {
                node.classList.add('none');
            })
        } else {
            this._floatTextEditingBox.domElement.querySelectorAll('.text-enable').forEach((node) => {
                node.classList.add('none');
            })
            this._floatTextEditingBox.domElement.querySelectorAll('.media-enable').forEach((node) => {
                node.classList.remove('none');
            })
        }
        const handlers = this._boxSelectedArea.domElement?.querySelectorAll('.intkn-editor-box-handler');
        handlers?.forEach((node) => {
            node.classList.add('none');
        });
        //SELECTED TEXT
        this._floatTextEditingBox.domElement.querySelector('.left').classList.remove('selected');
        this._floatTextEditingBox.domElement.querySelector('.right').classList.remove('selected');
        this._floatTextEditingBox.domElement.querySelector('.justify').classList.remove('selected');
        this._floatTextEditingBox.domElement.querySelector('.center').classList.remove('selected');
        if (this._editing.data.styles.textAlign) {
            this._floatTextEditingBox.domElement.querySelector('.' + this._editing.data.styles.textAlign).classList.add('selected');
        }

        this._floatTextEditingBox.domElement.querySelector('.bold').classList.remove('selected');
        if (this._editing.data.styles.fontWeight
            && this._editing.data.styles.fontWeight == 'bold') {
            this._floatTextEditingBox.domElement.querySelector('.bold').classList.add('selected');
        }

        this._floatTextEditingBox.domElement.querySelector('.italic').classList.remove('selected');
        if (this._editing.data.styles.fontStyle
            && this._editing.data.styles.fontStyle == 'italic') {
            this._floatTextEditingBox.domElement.querySelector('.italic').classList.add('selected');
        }
        this._floatTextEditingBox.domElement.querySelector('.underline').classList.remove('selected');
        if (this._editing.data.styles.textDecoration
            && this._editing.data.styles.textDecoration == 'underline') {
            this._floatTextEditingBox.domElement.querySelector('.underline').classList.add('selected');
        }
        this._floatTextEditingBox.domElement.querySelector('.through').classList.remove('selected');
        if (this._editing.data.styles.textDecoration
            && this._editing.data.styles.textDecoration == 'line-through') {
            this._floatTextEditingBox.domElement.querySelector('.through').classList.add('selected');
        }
        if (!this._editing.data.styles.color) {
            this._editing.data.styles.color = '#000000';
        }
        const color: HTMLElement = this._floatTextEditingBox.domElement.querySelector('.color');
        color.style.backgroundColor = this._editing.data.styles.color;

        if (this._editing.data.styles.backgroundColor) {
            const bckgd: HTMLElement = this._floatTextEditingBox.domElement.querySelector('.background');
            bckgd.style.backgroundColor = this._editing.data.styles.backgroundColor;
        }
        if (this._editing.data.styles.borderColor) {
            const bckgd: HTMLElement = this._floatTextEditingBox.domElement.querySelector('.border');
            bckgd.style.backgroundColor = this._editing.data.styles.borderColor;
        }

        this.container.appendChild(this._floatTextEditingBox.domElement);

        //this._showBoxSelectedArea();
    }
    _hideFloatEditingBox() {

        //console.log('_hideFloatEditingBox')
        if (!this.container.contains(this._floatTextEditingBox.domElement)) return;
        this._floatTextEditingBox?.pickers?.container?.classList.add('none');
        this._floatTextEditingBox?.pickers?.backgroundColorPicker?.domElement?.classList.add('none');
        this._floatTextEditingBox?.pickers?.fontColorPicker?.domElement?.classList.add('none');
        this._floatTextEditingBox?.pickers?.borderColorPicker?.domElement?.classList.add('none');
        this.container.removeChild(this._floatTextEditingBox.domElement);

        this._editing?.bodyElement?.setAttribute('contenteditable', 'false');
        if (this._editing?.bodyElement
            && this._editing.recorder !== this._editing.bodyElement?.innerHTML) {
            this._contentUpdate(this._editing);
        }
        this._editing = undefined;
    }
    _constructFloatEditingBox() {
        this._floatTextEditingBox.domElement = document.createElement('div');
        this._floatTextEditingBox.domElement.setAttribute('class', 'intkn-editor-box-float-text');
        this._floatTextEditingBox.domElement.innerHTML = `
        <div class="line media-enable">
                <div class="circleLabel buttonUpdateFile">
                    <button class="circle updatefile"></button>
                    <div class="label">Modifier</div>
                </div>
                <div class="circleLabel buttonFlip none">
                    <button class="circle flip"></button>
                    <div class="label">Retourner</div>
                </div>
            </div>
            <div class="line text-enable">
                <button class="bold">B</button>
                <button class="italic">I</button>
                <button class="underline">U</button>
                <button class="through">ab</button>
            </div>
            <div class="delimiter text-enable"></div>
            <div class="line text-enable">
                <button class="left"></button>
                <button class="center"></button>
                <button class="right"></button>
                <button class="justify"></button>
            </div>
            <div class="delimiter"></div>
            <div class="line">
                <div class="circleLabel buttonFC text-enable">
                    <button class="circle color"></button>
                    <div class="label">Texte</div>
                </div>
                <div class="circleLabel buttonBGC" >
                    <button class="circle background"></button>
                    <div class="label">Arrière plan</div>
                </div>
            </div>
            <div class="delimiter"></div>
            <div class="line">
                <div class="circleLabel buttonBRC">
                    <button class="circle border"></button>
                    <div class="label">Bordure</div>
                </div>
                <div class="circleLabel buttonRadius disabled" >
                    <button class="circle radius"></button>
                    <div class="label">Options</div>
                </div>
            </div>
            <div class="delimiter none"></div>
            <div class="line none">
                <div class="circleLabel buttonLayers">
                    <button class="circle layers"></button>
                    <div class="label">Calques</div>
                </div>
                <div class="circleLabel buttonEvents none" >
                    <button class="circle events"></button>
                    <div class="label">Evènements</div>
                </div>
            </div>
        `;
        const bodyPicker = document.createElement('div');
        bodyPicker.setAttribute('class', 'pickerContainer none');
        bodyPicker.innerHTML = `<div class="picker backgroundColor none" tabindex="0">
            <div class="title">
                <button class="close"></button>
            </div>
        </div>
        <div class="picker fontColor none" tabindex="0">
            <div class="title">
                <button class="close"></button>
            </div>
        </div>
        <div class="picker borderColor none" tabindex="0">
            <div class="title">
                <button class="close"></button>
            </div>
        </div>`;
        document.body.appendChild(bodyPicker);
        bodyPicker.addEventListener('mousedown', (evt) => {
            evt.stopPropagation();
        });

        this._floatTextEditingBox.domElement.addEventListener('click', (evt) => {
            evt.stopPropagation();
        });
        this._floatTextEditingBox.domElement.querySelector('.flip').addEventListener('click', (evt) => {
            this._setBoxFlip('y', this._listComponents[key]);
        });
        this._floatTextEditingBox.domElement.querySelector('.bold').addEventListener('click', (evt) => {
            this._setBoxCSS('fontWeight', 'bold', true);
        });
        this._floatTextEditingBox.domElement.querySelector('.italic').addEventListener('click', (evt) => {
            this._setBoxCSS('fontStyle', 'italic', true);
            /*execCommandStyle({
                style: 'font-style',
                value: 'italic',
                initial: (element: HTMLElement | null) =>
                    Promise.resolve(element &&
                        element.style.fontStyle === 'italic')
            }, '',
                this._contentUpdate
            );*/
        });
        this._floatTextEditingBox.domElement.querySelector('.underline').addEventListener('click', (evt) => {
            this._setBoxCSS('textDecoration', 'underline', true);
        });
        this._floatTextEditingBox.domElement.querySelector('.through').addEventListener('click', (evt) => {
            this._setBoxCSS('textDecoration', 'line-through', true);
        });
        this._floatTextEditingBox.domElement.querySelector('.left').addEventListener('click', (evt) => {
            this._setBoxCSS('textAlign', 'left');
        });
        this._floatTextEditingBox.domElement.querySelector('.center').addEventListener('click', (evt) => {
            this._setBoxCSS('textAlign', 'center');
        });
        this._floatTextEditingBox.domElement.querySelector('.right').addEventListener('click', (evt) => {
            this._setBoxCSS('textAlign', 'right');
        });
        this._floatTextEditingBox.domElement.querySelector('.justify').addEventListener('click', (evt) => {
            this._setBoxCSS('textAlign', 'justify');
        });
        const uploadButton = this._floatTextEditingBox.domElement.querySelector('.updatefile');
        uploadButton.addEventListener('click', (evt) => {
            evt.stopPropagation();
            Object.entries(this._listSelectedComponents).map(([key, value]) => {
                if (this._listComponents[key].data.subType == 'video') {
                    this._constructPopupVideoUpload();
                }
                if (this._listComponents[key].data.subType == 'image') {
                    this._constructPopupImageUpload();
                }
            });
        });
        const backgroundColor: HTMLElement = bodyPicker.querySelector('.backgroundColor');
        const backgroundColorPicker = AColorPicker.createPicker(backgroundColor, {
            showHSL: false, showAlpha: true, paletteEditable: true, useAlphaInPalette: true, color: "#0078af"
        });
        backgroundColor.addEventListener('click', (evt: MouseEvent) => {
            evt.stopPropagation();
        });
        const closeBGC = backgroundColor.querySelector('.close');
        closeBGC.addEventListener('click', (evt) => {
            bodyPicker.classList.add('none');
            backgroundColor.classList.add('none');
        });


        const fontColor: HTMLElement = bodyPicker.querySelector('.fontColor');
        const fontColorPicker = AColorPicker.createPicker(fontColor, {
            showHSL: false, showAlpha: true, paletteEditable: true, useAlphaInPalette: true, color: "#0078af"
        });
        fontColor.addEventListener('click', (evt: MouseEvent) => {
            evt.stopPropagation();
        });
        const closeFC = fontColor.querySelector('.close');
        closeFC.addEventListener('click', (evt) => {
            bodyPicker.classList.add('none');
            fontColor.classList.add('none');
        });

        const borderColor: HTMLElement = bodyPicker.querySelector('.borderColor');
        const borderColorPicker = AColorPicker.createPicker(borderColor, {
            showHSL: false, showAlpha: true, paletteEditable: true, useAlphaInPalette: true, color: "#0078af"
        });
        borderColor.addEventListener('click', (evt: MouseEvent) => {
            evt.stopPropagation();
        });
        const closeBDC = borderColor.querySelector('.close');
        closeBDC.addEventListener('click', (evt) => {
            bodyPicker.classList.add('none');
            borderColor.classList.add('none');
        });

        backgroundColorPicker.on('change', (picker, color: string) => {
            //console.log('backgroundColorPicker', color);
            this._setBoxCSS('backgroundColor', color);
            this._showFloatEditingBox(this._editing);
        });
        const buttonBGC = this._floatTextEditingBox.domElement.querySelector('.buttonBGC');
        buttonBGC.addEventListener('click', (evt: MouseEvent) => {
            bodyPicker.style.left = (evt.clientX - 116) + 'px';
            bodyPicker.style.top = (evt.clientY - 192) + 'px';
            backgroundColor.classList.toggle('none');
            bodyPicker.classList.toggle('none');
            borderColor.classList.add('none');
            fontColor.classList.add('none');
        })
        fontColorPicker.on('change', (picker, color: string) => {
            //console.log('color', color);
            this._setBoxCSS('color', color);
            this._showFloatEditingBox(this._editing);
        });
        const buttonFC = this._floatTextEditingBox.domElement.querySelector('.buttonFC');
        buttonFC.addEventListener('click', (evt: MouseEvent) => {
            bodyPicker.style.left = (evt.clientX - 116) + 'px';
            bodyPicker.style.top = (evt.clientY - 192) + 'px';
            fontColor.classList.toggle('none');
            bodyPicker.classList.toggle('none');
            borderColor.classList.add('none');
            backgroundColor.classList.add('none');
        })

        borderColorPicker.on('change', (picker, color: string) => {
            //console.log('color', color);
            if (!this._editing?.data?.styles.borderWidth) {
                this._setBoxCSS('borderWidth', '1px');
                this._setBoxCSS('borderStyle', 'solid');
            }
            this._setBoxCSS('borderColor', color);
            this._showFloatEditingBox(this._editing);
        });
        const buttonBRC = this._floatTextEditingBox.domElement.querySelector('.buttonBRC');
        buttonBRC.addEventListener('click', (evt: MouseEvent) => {
            bodyPicker.style.left = (evt.clientX - 116) + 'px';
            bodyPicker.style.top = (evt.clientY - 192) + 'px';
            borderColor.classList.toggle('none');
            bodyPicker.classList.toggle('none');
            backgroundColor.classList.add('none');
            fontColor.classList.add('none');
        });
        this._floatTextEditingBox.pickers = {
            container: bodyPicker,
            backgroundColorPicker: {
                domElement: backgroundColor,
                picker: backgroundColorPicker
            },
            fontColorPicker: {
                domElement: fontColor,
                picker: fontColorPicker
            },
            borderColorPicker: {
                domElement: borderColor,
                picker: borderColorPicker
            },
        };
    }
    _contentUpdate(component: Component) {
        //UPDATE ATTRIBUTE
        if (this._listComponents[component.data.externalId]) {
            const updated = (new Date().getTime() / 1000);
            this._listComponents[component.data.externalId].data.content = component.bodyElement
                ? component.bodyElement.innerHTML : component.domElement.innerHTML;
            this._listComponents[component.data.externalId].updatedAt.time = updated;
            this._listComponents[component.data.externalId].updatedAt.on =
                Object.assign(this._listComponents[component.data.externalId].updatedAt.on, { content: true });
            this.updatedAt = updated;
            console.log('_contentUpdate')
        }
    }
    _attributeUpdate(component: Component, attribute: string, value: string) {
        if (this._listComponents[component.data.externalId]) {
            const updated = (new Date().getTime() / 1000);
            this._listComponents[component.data.externalId].data.attributes[attribute] = value;
            this._listComponents[component.data.externalId].updatedAt.time = updated;
            this._listComponents[component.data.externalId].updatedAt.on =
                Object.assign(this._listComponents[component.data.externalId].updatedAt.on, { attributes: true });
            this.updatedAt = updated;
            console.log('_attributeUpdate')
        }
    }
    _setBoxCSS(key: string, value: string, toggle?: boolean | string, component?: Component) {
        if (this._editing?.domElement) {
            component = this._editing;
        }
        if (!component) return;
        if (toggle && component.domElement.style[key as unknown as number] == value) value = (typeof (toggle) == 'string' ? toggle : 'initial');
        component.domElement.style[key as unknown as number] = value;
        const updated = (new Date().getTime() / 1000);
        this._listComponents[component.data.externalId].data.styles[key] = value;
        this._listComponents[component.data.externalId].updatedAt.time = updated;
        this._listComponents[component.data.externalId].updatedAt.on =
            Object.assign(this._listComponents[component.data.externalId].updatedAt.on, { styles: true });
        this.updatedAt = updated;
        if (this._editing) this._showFloatEditingBox(component);
    }
    _setBoxFlip(axis: string, component: Component) {
        const attribute: string = 'rotate' + axis.toUpperCase();
        const rotate = this._listComponents[component.data.externalId].data.styles.transform[attribute];
        let value = '180deg';
        if (rotate) {
            if (rotate.split('(')[1]
                .split(')')[0] == value) {
                value = '0deg';
            }
        }
        const updated = (new Date().getTime() / 1000);
        if (!component.data.styles.transform
            || typeof component.data.styles.transform === 'string') this._listComponents[component.data.externalId].data.styles.transform = {};

        this._listComponents[component.data.externalId].data.styles.transform[attribute] = attribute + '(' + value + ')';

        const transform = (this._listComponents[component.data.externalId].data.styles.transform.rotate ? this._listComponents[component.data.externalId].data.styles.transform.rotate : '')
            + (this._listComponents[component.data.externalId].data.styles.transform.rotateY ? ' ' + this._listComponents[component.data.externalId].data.styles.transform.rotateY : '')
            + (this._listComponents[component.data.externalId].data.styles.transform.rotateX ? ' ' + this._listComponents[component.data.externalId].data.styles.transform.rotateX : '');
        this._listComponents[component.data.externalId].domElement.style.transform = transform;
        this._listComponents[component.data.externalId].updatedAt.time = updated;
        this._listComponents[component.data.externalId].updatedAt.on =
            Object.assign(this._listComponents[component.data.externalId].updatedAt.on, { styles: true });
        this.updatedAt = updated;
        this._showFloatTopBox();
    }

    //BOX SELECTED AREA
    _constructBoxSelectedArea() {
        this._boxSelectedArea.domElement = document.createElement('div');
        this._boxSelectedArea.domElement.setAttribute('class', 'intkn-editor-box-selected');
        this._boxSelectedArea.domElement.innerHTML = `
            <div class="intkn-editor-box-handler topLeft" data-handler="topLeft"></div>
            <div class="intkn-editor-box-handler top" data-handler="top"></div>
            <div class="intkn-editor-box-handler topRight" data-handler="topRight"></div>
            <div class="intkn-editor-box-handler right" data-handler="right"></div>
            <div class="intkn-editor-box-handler bottomRight" data-handler="bottomRight"></div>
            <div class="intkn-editor-box-handler bottom" data-handler="bottom"></div>
            <div class="intkn-editor-box-handler bottomLeft" data-handler="bottomLeft"></div>
            <div class="intkn-editor-box-handler left" data-handler="left"></div>
            <div class="intkn-editor-box-handler rotate" data-handler="rotate"></div>
        `;

        const handlers = this._boxSelectedArea.domElement?.querySelectorAll('.intkn-editor-box-handler');
        handlers?.forEach((node) => {
            node.addEventListener('mousedown', (evt: MouseEvent) => {
                this._setEnableBoxTransform(evt);
            });
        });
    }

    _showBoxSelectedArea() {

        if (!this._boxSelectedArea.domElement) this._constructBoxSelectedArea();
        let domRect: {
            left: number,
            right: number,
            top: number,
            bottom: number,
        };
        let minimize = false;
        let rotate: number;
        let transformOrigin: string;
        Object.entries(this._listSelectedComponents).map(([key, value]) => {
            const rect = {
                left: this._listComponents[key].domElement.offsetLeft,
                right: (this._listComponents[key].domElement.offsetLeft + this._listComponents[key].domElement.offsetWidth),
                top: this._listComponents[key].domElement.offsetTop,
                bottom: (this._listComponents[key].domElement.offsetTop + this._listComponents[key].domElement.offsetHeight),
            };
            transformOrigin = this._listComponents[key].domElement.style.transformOrigin
                ? this._listComponents[key].domElement.style.transformOrigin : '';
            rotate = this._getAngleFromData(this._listComponents[key].data);

            if (this._listComponents[key].data.attributes?.minimize == 'true') {
                minimize = true;
            }

            if (!domRect) {
                domRect = {
                    left: rect.left,
                    right: rect.right,
                    top: rect.top,
                    bottom: rect.bottom
                };
                return;
            }
            if (domRect.left > rect.left) {
                domRect.left = rect.left;
            }
            if (domRect.top > rect.top) {
                domRect.top = rect.top;
            }
            if (domRect.right < rect.right) {
                domRect.right = rect.right;
            }
            if (domRect.bottom < rect.bottom) {
                domRect.bottom = rect.bottom;
            }

        });
        if (!domRect) return;
        const handlers = this._boxSelectedArea.domElement
            .querySelectorAll('.intkn-editor-box-handler');
        handlers?.forEach((node) => {
            if (this._draggable
                || minimize
                || this.container.contains(this._floatTextEditingBox.domElement)) {
                node.classList.add('none');
                return;
            }
            node.classList.remove('none');
        });

        this._boxSelectedArea.styles = {
            left: domRect.left + 'px',
            width: (domRect.right - domRect.left) + 'px',
            top: domRect.top + 'px',
            height: (domRect.bottom - domRect.top) + 'px',
            position: 'absolute',
            borderWidth: '2px',
            transformOrigin: transformOrigin,
        };

        for (const [key, value] of Object.entries(this._boxSelectedArea.styles)) {
            this._boxSelectedArea.domElement.style[key as unknown as number] = value
        }
        if (Object.entries(this._listSelectedComponents).length == 1
            && rotate) {
            this._boxSelectedArea.domElement.style.transform = 'rotate(' + rotate + 'deg)';
        } else {
            this._boxSelectedArea.domElement.style.transform = '';
        }
        this.container.appendChild(this._boxSelectedArea.domElement);
    }
    _hideBoxSelectedArea() {
        if (!this.container.contains(this._boxSelectedArea.domElement)) return;
        this.container.removeChild(this._boxSelectedArea.domElement);
    }
    _resetBoxSelectedArea() {
        if (!this._boxSelectedArea.domElement) return;
        this._resetListSelectedElements();
        this._hideBoxSelectedArea();
    }
    connectedCallback() {
        this.classList.add(this._mode);
        this.innerHTML = `
            <div class="intkn-editor-toolbar">
                <button class="edit"></button>
                <button class="text"></button>
                <button class="image"></button>                
                <button class="video"></button>
                <button class="audio none"></button>
                <button class="file"></button>
                <button class="pencil none"></button>                
            <div>           
        `;


        if (!this.container) {
            this.container = this._container || document.querySelector('.editorContainer');
        }

        this.container.setAttribute('tabindex', '0');
        if (this._mode == 'edit' || this._mode == 'preview') {

            this.addEventListener('click', (evt) => {
                evt.stopPropagation();
                this._resetBoxSelectedArea();
                this._hideFloatTopBox();
                this._hideFloatEditingBox();
            });
            this.container.addEventListener('mouseup', this._setDisableBoxMove.bind(this));
            this.container.addEventListener('mousemove', this._setBoxMove.bind(this));
            this.container.addEventListener('click', (evt) => {
                //evt.stopPropagation()
                this._resetBoxSelectedArea();
                this._hideFloatTopBox();
                this._hideFloatEditingBox();
                this.focus();
            });
            this.container.addEventListener('mousedown', (evt => { evt.stopPropagation(); }));
            this.container.addEventListener('keydown', (evt) => {
                const key = evt.key;
                if (key === "Delete"
                    && !this._editing) {
                    this._deleteSelectedElements();
                }
                if ((evt.key == 'ArrowDown'
                    || evt.key == 'ArrowUp')
                    && this._editing?.bodyElement?.getAttribute('contenteditable') != 'true'
                ) {
                    // up down arrow
                    evt.preventDefault();
                }
            });
            this.container.addEventListener('keyup', (evt) => {
                evt.stopPropagation();
                evt.preventDefault();
            });
            const textButton = this.querySelector('.text');
            textButton.addEventListener('click', (evt) => {
                this._constructComponent('div', 'text');
            });

            const imgButton = this.querySelector('.image');
            imgButton.addEventListener('click', (evt) => {
                this._constructComponent('img', 'image');
            });
            const imgVideo = this.querySelector('.video');
            imgVideo.addEventListener('click', (evt) => {
                this._constructComponent('video', 'video');
            });
            const imgFile = this.querySelector('.file');
            imgFile.addEventListener('click', (evt) => {
                this._constructComponent('div', 'file');
            });
            this.addEventListener('close-signup-popup', (evt) => {
                document.body.style.overflow = 'auto';
            });
            this.addEventListener('close-popup', (evt) => {
                document.body.style.overflow = 'auto';
            });
        }

        const editButton = this.querySelector('.edit');
        editButton.addEventListener('click', (evt) => {
            if (this._mode == 'viewer') return;
            if (this._mode == 'preview') {
                this._setMode('edit');
                return;
            }
            this._setMode('preview');
        });

        /*this.addEventListener('open-signup-popup', (evt: CustomEvent) => {
            //evt.target.remove();
            document.body.style.overflow = 'hidden';
            let popupSignup = new IntknPopup();
            this.appendChild(popupSignup);
            let dataId = new Date().getTime();
            let session = evt.detail.element;
            popupSignup.setAttribute('data-id', dataId.toString());
            popupSignup.setAttribute('display-title', evt.detail.element.parent[0].name);
            popupSignup.setAttribute('display-subtitle', this._translator.get('registration') + ' - ' + evt.detail.element.name);
            popupSignup.element = session;
            popupSignup.formStructure = signUpFormStructure;
            this._elementOnRegistration = evt.detail.element;
            popupSignup.addEventListener('data-form-popup-' + dataId, (evt: CustomEvent) => {
                console.log('data-form-signup-popup-' + dataId);
                this._sendSignupPlatform(evt.detail.data, popupSignup);
            })
        });*/


    }
}
if (!customElements.get('intkn-edit-toolbar')) {
    customElements.define('intkn-edit-toolbar', EditToolbar);
}

/*const editToolbar = document.createElement('intkn-edit-toolbar');
const get: any = getValuesUrl();

editToolbar.setAttribute('element-id', get.values.ressource);
document.body.appendChild(editToolbar);*/