"use strict";

export class Annotation {
    constructor() {
        this.id = generateUuId();
        this.externalId = this.id;
        this.intknId;
        // Types:
        // - highlight
        // - bookmark
        // - internalAnnotation
        this.type;
        this.styleSheet = {
            value: "yellow",
        };
        this.body = new Object();
        this.page;
        this.parameters = {
            anchorNode: null,
            focusNode: null,
            anchorOffset: null,
            focusOffset: null,
        };
        this.target = {
            id: generateUuId(),
            format: "application/pdf",
        };
    }

    setColor(color) {
        this.styleSheet.value = color;
    }

    setId(id) {
        this.id = id;
    }

    setType(type) {
        this.type = type;
    }

    setTargetId(targetId) {
        this.target.id = targetId;
    }

    setPage(pageIndex) {
        this.page = pageIndex;
    }

    setTargetForInternalAnnotation(tagName, type, value, id, checked) {
        this.target.selector = {
            type: type,
            tagName: tagName,
            value: value,
            id: id,
        };
        if (typeof checked !== "undefined") {
            this.target.selector.checked = checked;
        }
    }

    setSelector(selection) {
        var anchorNodePosition;
        var focusNodePosition;
        // What ?
        var anchorParentChilds =
            selection.anchorNode.parentNode.parentNode.childNodes;
        var focusParentChilds =
            selection.focusNode.parentNode.parentNode.childNodes;

        for (var i = 0; i < anchorParentChilds.length; i++) {
            if (selection.anchorNode === anchorParentChilds[i].firstChild) {
                anchorNodePosition = i;
                break;
            }
        }

        for (var i = 0; i < focusParentChilds.length; i++) {
            if (selection.focusNode === focusParentChilds[i].firstChild) {
                focusNodePosition = i;
                break;
            }
        }

        this.parameters.anchorNode = anchorNodePosition;
        this.parameters.anchorOffset = selection.anchorOffset;
        this.parameters.focusNode = focusNodePosition;
        this.parameters.focusOffset = selection.focusOffset;

        this.target.selector = {
            type: "FragmentSelector",
            value: `anchorNode=${anchorNodePosition}&anchorOffset=${selection.anchorOffset}&focusNode=${focusNodePosition}&focusOffset=${selection.focusOffset}`,
            text: selection.toString(),
        };
    }

    loadFromObject(annotationObject) {
        for (var indProperty in annotationObject) {
            this[indProperty] = annotationObject[indProperty];
        }

        if (annotationObject.type == "highlight") {
            this._parseSelector();
        }
    }

    _parseSelector() {
        let stringEntry = this.target.selector.value.split("&");
        for (const entry of stringEntry) {
            let [key, value] = entry.split("=");
            this.parameters[key] = value;
        }
    }
}

export class AnnotationController {
    /**
     * @param {*} pdfViewer
     * @memberof AnnotationController
     */

    constructor(pdfViewer) {
        this._pdfViewer = pdfViewer;
        this._annotations = [];
        this._colors = {
            red: "rgb(255,0,0, 0.5)",
            yellow: "rgb(255,255,0, 0.5)",
            blue: "rgb(0,0,255, 0.5)",
            blueTwo: "rgb(0, 234, 255, 0.5)",
            green: "rgb(0,255,0, 0.5)",
            greenTwo: "rgb(191,255,0, 0.5)",
            fuchsia: "rgb(107,35,143)",
            pink: "rgb(252,67,224, 0.5)",
            pinkTwo: "rgb(255,0,170)",
            orange: "rgb(255,127,0)",
        };
    }
    removeAllAnnotations() {
        this._annotations = [];
    }
    get(uuid) {
        if (this._annotations[uuid]) {
            return this._annotations[uuid].annotation;
        }
    }

    /**
     *	If the annotation already exists, it is replaced
     *
     * @deprecated
     * @param {Annotation} annotation
     * @memberof AnnotationController
     */
    add(annotation, callback) {
        this._annotations[annotation.id] = {
            annotation: annotation,
            callback: callback,
        };
    }

    /**
     *
     *
     * @param {String} id
     * @memberof AnnotationController
     *
     * TODO: Check for type
     */
    remove(id) {
        let annotation = this._annotations[id];
        if (!annotation) {
            return;
        }
        let type = annotation.annotation.type;
        delete this._annotations[id];

        switch (type) {
            case "bookmark":
                this._clearBookmark(annotation.annotation.page);
                break;
            case "highlight":
                this.refresh(annotation.annotation.page);
                break;
        }
    }

    /**
     * @param {Number} pageIndex
     * @memberof AnnotationController
     */
    refresh(pageIndex) {
        this._clearCanvas(pageIndex);
        this._clearEventElements(pageIndex);
        this._clearBookmark(pageIndex);
        this.drawAll(pageIndex);
    }

    /**
     *
     *
     * @param {Annotation} annotation
     * @param {Function} callback
     * @memberof AnnotationController
     */
    draw(annotation, callback) {
        switch (annotation.type) {
            case "bookmark":
                this._drawBookmark(annotation, callback);
                break;
            case "highlight":
                this._drawHighlight(annotation, callback);
                break;
        }
    }

    toggleBookmark(pageIndex) {
        let bookmark = false;
        for (const [id, annotation] of Object.entries(this._annotations)) {
            if (
                annotation.annotation.page == pageIndex &&
                annotation.annotation.type === "bookmark"
            ) {
                bookmark = annotation.annotation;
                break;
            }
        }

        if (bookmark) {
            this.remove(bookmark.id);
            let event = new CustomEvent("pdfreader-remove-note", {
                bubbles: true,
                detail: {
                    externalId: bookmark.id,
                    type: bookmark.type,
                },
            });

            this._pdfViewer.container.dispatchEvent(event);
            return;
        }

        bookmark = new Annotation();
        bookmark.setType("bookmark");
        bookmark.setTargetId(this._pdfViewer.id);
        bookmark.setPage(pageIndex);
        this.draw(bookmark);

        let event = new CustomEvent("pdfreader-add-note", {
            bubbles: true,
            detail: {
                id: bookmark.id,
                externalId: bookmark.id,
                type: bookmark.type,
                page: bookmark.page,
            },
        });

        this._pdfViewer.container.dispatchEvent(event);
    }

    /**
     * @param {Number} pageIndex
     * @memberof AnnotationController
     */
    drawAll(pageIndex) {
        this._clearCanvas(pageIndex);
        this._clearEventElements(pageIndex);
        this._clearBookmark(pageIndex);
        for (const [id, annotation] of Object.entries(this._annotations)) {
            if (annotation.annotation.page == pageIndex) {
                this.draw(annotation.annotation, annotation.callback);
            }
        }
    }

    _clearEventElements(pageIndex) {
        let page = this._pdfViewer.pages[pageIndex].pageDomElement;
        if (!page || !page.querySelectorAll) {
            console.warn("noquerySelectorAll", page);
            return;
        }
        let elements = page.querySelectorAll(".highlightPdfBox");
        for (const element of elements) {
            element.remove();
        }
    }
    /**
     * @param {Number} pageIndex
     * @memberof AnnotationController
     */
    _clearBookmark(pageIndex) {
        let page = this._pdfViewer.pages[pageIndex].pageDomElement;
        if (!page || !page.querySelectorAll) {
            console.warn("noquerySelectorAll", page);
            return;
        }
        let bookmarks = page.querySelectorAll(".bookmark");
        for (const bookmark of bookmarks) {
            bookmark.remove();
        }
        this._pdfViewer.toolbar.unsetActivatedBookmark();
    }

    /**
     * @param {Number} pageIndex
     * @memberof AnnotationController
     */
    _clearCanvas(pageIndex) {
        let canvas =
            this._pdfViewer.pages[pageIndex].canvasAnnotationDomElement;
        if (canvas) {
            let context = canvas.getContext("2d");
            context.clearRect(0, 0, canvas.width, canvas.height);
        }
    }

    /**
     * @param {Annotation} annotation
     * @memberof AnnotationController
     */
    update(annotation) {
        //console.log(this._annotations, annotation);
        if (!this._annotations[annotation.id]) {
            throw new Error("Annotation undefined, cannot update");
        }

        Object.assign(this._annotations[annotation.id].annotation, annotation);
        //console.log(this._annotations[annotation.id]);
    }

    /**
     * @param {Annotation} annotation
     * @param {Function} callback
     * @returns
     * @memberof AnnotationController
     */
    _drawBookmark(annotation, callback) {
        // register the annotation to be able to redraw it later
        this._annotations[annotation.id] = {
            annotation: annotation,
            callback: callback,
        };
        let pageDomElement =
            this._pdfViewer.pages[annotation.page].pageDomElement;
        if (!pageDomElement) {
            return;
        }
        let bookmark = document.createElement("div");
        bookmark.className = "bookmark";
        bookmark.innerHTML = `
			<div class="bookmarkSquare"></div>
			<div class="bookmarkTriangleContainer">
				<div class="bookmarkTriangleLeft"></div>
				<div class="bookmarkTriangleRight"></div>
			</div>
		`;
        this._pdfViewer.toolbar.setActivatedBookmark();
        bookmark.addEventListener("click", callback);

        pageDomElement.appendChild(bookmark);
        return bookmark;
    }

    /**
     * @param {Annotation} annotation
     * @param {Function} callback
     * @memberof AnnotationController
     */
    _drawHighlight(annotation, callback) {
        // NOTE: Can we not draw on the same canvas as PDFJS does the render ?
        let canvas =
            this._pdfViewer.pages[annotation.page].canvasAnnotationDomElement;
        let textLayer = this._pdfViewer.pages[annotation.page].textDomElement;
        let pageDomElement =
            this._pdfViewer.pages[annotation.page].pageDomElement;
        if (!pageDomElement) {
            return;
        }
        // This is to handle old buggy annotations that are not in any
        // possible range. We should not do a try catch inside here
        try {
            var ranges = this._createSelectionRanges(
                textLayer,
                annotation.parameters
            );
            //console.log(ranges);
        } catch (err) {
            //console.log(annotation);
            console.log(err);
            return;
        }
        var context = canvas.getContext("2d");
        context.globalAlpha = 0.5;
        // Disable the anti-aliasing that can occur when the drawn rectangles
        // do not align with any pixels
        context.imageSmoothingEnabled = false;
        context.fillStyle =
            this._colors[annotation.styleSheet.value] || this._colors["yellow"];

        let scale = this._pdfViewer.resolutionFactor / this._pdfViewer.zoom;
        for (var i = 0; i < ranges.length; i++) {
            var boundingBox = ranges[i].getBoundsRelativeTo(canvas);
            // Draw the highlight
            context.fillRect(
                boundingBox.x * scale,
                boundingBox.y * scale,
                boundingBox.width * scale,
                boundingBox.height * scale
            );
            // Handle events over canvas
            let element = this._createEventElements(boundingBox, callback);
            pageDomElement.appendChild(element);
            //console.log(element);
        }
    }

    /**
     * @param {Annotation} annotation
     * @param {Number} rect.x
     * @param {Number} rect.y
     * @param {Number} rect.width
     * @param {Number} rect.height
     * @param {Function} handler
     */
    _createEventElements(rect, handler) {
        let scale = this._pdfViewer.resolutionFactor / this._pdfViewer.zoom;
        //console.log(this._pdfViewer.zoom);
        var highlightElement = document.createElement("div");
        highlightElement.style.left = rect.left / this._pdfViewer.zoom + "px";
        highlightElement.style.top = rect.top / this._pdfViewer.zoom + "px";
        highlightElement.style.width = rect.width / this._pdfViewer.zoom + "px";
        highlightElement.style.height =
            rect.height / this._pdfViewer.zoom + "px";
        highlightElement.setAttribute("class", "highlightPdfBox");

        highlightElement.addEventListener("click", handler);

        return highlightElement;
    }

    /**
     *
     *
     * @param {Element} textLayer
     * @param {Object} parameters
     * @param {Number} parameters.anchorNode
     * @param {Number} parameters.focusNode
     * @param {Number} parameters.anchorOffset
     * @param {Number} parameters.focusOffset
     * @returns {Range[]}
     * @memberof AnnotationController
     */
    _createSelectionRanges(textLayer, parameters) {
        let { anchorNode, focusNode, anchorOffset, focusOffset } = parameters;
        let rangeSize = Math.abs(anchorNode - focusNode);
        let ranges = [];

        let offset = Math.min(anchorNode, focusNode);

        // invert the offset if we select from right to left
        if (
            anchorNode > focusNode ||
            (anchorNode == focusNode && anchorOffset > focusOffset)
        ) {
            [anchorOffset, focusOffset] = [focusOffset, anchorOffset];
        }

        for (var i = 0; i <= rangeSize; i++) {
            var text = textLayer.childNodes[i + offset];
            if (!text || !text.firstChild) continue;
            var startOffset = parseInt(i == 0 ? anchorOffset : 0);
            var endOffset = parseInt(
                i == rangeSize ? focusOffset : text.firstChild.length
            );
            if (startOffset > endOffset) {
                var tempEnd = startOffset;
                startOffset = endOffset;
                endOffset = tempEnd;
            }
            var range = document.createRange(text);
            range.setStart(text.firstChild, startOffset);
            range.setEnd(text.firstChild, endOffset);
            ranges.push(range);
        }
        return ranges;
    }
}
