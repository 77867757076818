class SelectionToolbar extends HTMLElement {

    get selection() {
        return this._selection;
    }

    set selection(selection) {
        this._selection = selection;
    }

    set buttons(buttons) {
        this._buttons = buttons;
    }

    constructor() {
        super();
        this._selection;
        this.actionMode = 'default';
    }

    connectedCallback() {
        /*this.addEventListener('click', evt => {
            evt.stopPropagation();
            this.remove();
        });*/
        //console.log('connectedCallback');
        this.addEventListener('mouseup', evt => {
            evt.stopPropagation();
        });
        this.addEventListener('mousedown', evt => {
            evt.stopPropagation();
        });
    }

    setSelectedTextActions() {
        this.classList.remove('selectionToolbarDefault');
        this._buttons.highlight.classList.remove('disabledAction');
        this._buttons.copy.classList.remove('disabledAction');
        this._buttons.comment.classList.remove('disabledAction');
        this._buttons.bookmark.classList.add('disabledAction');
        this._buttons.textTools.classList.add('disabledAction');
        this.actionMode = 'text';
    }

    setDefaultAction() {
        this.classList.add('selectionToolbarDefault');
        this._buttons.highlight.classList.add('disabledAction');
        this._buttons.copy.classList.add('disabledAction');
        this._buttons.comment.classList.add('disabledAction');
        this._buttons.bookmark.classList.add('disabledAction');
        this._buttons.textTools.classList.add('disabledAction');
        this.actionMode = 'default';
    }
    setPrimaryAction() {
        this.classList.remove('selectionToolbarDefault');
        this._buttons.highlight.classList.add('disabledAction');
        this._buttons.copy.classList.add('disabledAction');
        this._buttons.comment.classList.add('disabledAction');
        this._buttons.bookmark.classList.remove('disabledAction');
        this._buttons.textTools.classList.remove('disabledAction');
        this.actionMode = 'primary';
    }

    unsetActivatedBookmark() {
        this._buttons.bookmark.classList.remove('buttonActivatedBookmark');
        this._buttons.bookmark.querySelector("img").src ='/office/ressources/img/intkn_bookmark_outline.svg';

    }
    setActivatedBookmark() {
        this._buttons.bookmark.classList.add('buttonActivatedBookmark');
        this._buttons.bookmark.querySelector("img").src ='/office/ressources/img/intkn_bookmark_outline_white.svg';
    }
}

customElements.define('intkn-selection-toolbar', SelectionToolbar);