/* Copyright 2014 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { AnnotationLayer } from "pdfjs-dist";
import { NullL10n } from "./ui_utils.js";
import { SimpleLinkService } from "./pdf_link_service.js";

/**
 * @typedef {Object} AnnotationLayerBuilderOptions
 * @property {HTMLDivElement} pageDiv
 * @property {PDFPage} pdfPage
 * @property {AnnotationStorage} [annotationStorage]
 * @property {string} [imageResourcesPath] - Path for image resources, mainly
 *   for annotation icons. Include trailing slash.
 * @property {boolean} renderInteractiveForms
 * @property {IPDFLinkService} linkService
 * @property {DownloadManager} downloadManager
 * @property {IL10n} l10n - Localization service.
 */

class AnnotationLayerBuilder {
    /**
     * @param {AnnotationLayerBuilderOptions} options
     */
    constructor({
        pageDiv,
        pdfPage,
        linkService,
        downloadManager,
        annotationStorage = null,
        imageResourcesPath = "",
        renderInteractiveForms = false,
        l10n = NullL10n,
        pdfViewer,
    }) {
        this.pageDiv = pageDiv;
        this.pdfPage = pdfPage;
        this.linkService = linkService;
        this.downloadManager = downloadManager;
        this.imageResourcesPath = imageResourcesPath;
        this.renderInteractiveForms = renderInteractiveForms;
        this.l10n = l10n;
        this.annotationStorage = annotationStorage;
        this.div = null;
        this._cancelled = false;
        this._pdfViewer = pdfViewer;
        this.listInternalsAnnotations = {};
        this.listGroupsAnnotations = {};
    }

    /**
     * @param {PageViewport} viewport
     * @param {string} intent (default value is 'display')
     */
    render(viewport, intent = "display") {
        this.pdfPage
            .getAnnotations({ intent })
            .then((annotations) => {
                if (this._cancelled) {
                    return;
                }
                if (annotations.length === 0) {
                    return;
                }

                let parameters = {
                    viewport: viewport.clone({ dontFlip: true }),
                    div: this.div,
                    annotations,
                    page: this.pdfPage,
                    imageResourcesPath: this.imageResourcesPath,
                    renderInteractiveForms: this.renderInteractiveForms,
                    linkService: this.linkService,
                    downloadManager: this.downloadManager,
                    annotationStorage: this.annotationStorage,
                };
                if (this.div) {
                    // If an annotationLayer already exists, refresh its children's
                    // transformation matrices.
                    AnnotationLayer.update(parameters);
                } else {
                    // Create an annotation layer div and render the annotations
                    // if there is at least one annotation.
                    this.div = document.createElement("div");
                    this.div.className = "annotationLayer";
                    this.pageDiv.appendChild(this.div);
                    parameters.div = this.div;

                    AnnotationLayer.render(parameters);
                    this.l10n.translate(this.div).catch((err) => {
                        console.error(
                            "Error in localization translation:",
                            err
                        );
                    });
                }

                //SET EVENTS
                if (this.setEvents()) {
                    //SET VALUES
                    this.setValuesForInternalsAnnotations();
                }
            })
            .catch((error) => {
                console.error("Error while rendering annotations:", error);
            });
    }

    setValuesForInternalsAnnotations() {
        for (const id in this._pdfViewer.annotationController._annotations) {
            let annotation =
                this._pdfViewer.annotationController._annotations[id];
            if (annotation.annotation.type !== "internalAnnotation") {
                continue;
            }

            if (typeof this.listInternalsAnnotations[id] == "undefined") {
                console.log("notdomelementoninternalannotation", id);
                continue;
            }
            if (
                !annotation.annotation.target ||
                !annotation.annotation.target.selector
            ) {
                console.log("nottargetorselectoroninternalannotation", id);
                continue;
            }
            switch (annotation.annotation.target.selector.type) {
                case "checkbox":
                    this.listInternalsAnnotations[
                        id
                    ].domElement.childNodes[0].checked =
                        annotation.annotation.target.selector.checked;
                default:
                    this.listInternalsAnnotations[
                        id
                    ].domElement.childNodes[0].value =
                        annotation.annotation.target.selector.value;
            }
        }
    }

    cancel() {
        this._cancelled = true;
    }

    hide() {
        if (!this.div) {
            return;
        }
        this.div.setAttribute("hidden", "true");
    }
    setEvents() {
        if (!this.pageDiv.childNodes[0]) {
            console.log("noannotationforrenderer");
            return false;
        }
        this.div = this.pageDiv.childNodes[0];
        for (var c = 0; c < this.div.childNodes.length; c++) {
            var id = this.div.childNodes[c].getAttribute("data-annotation-id");
            this.listInternalsAnnotations[id] = {
                domElement: this.div.childNodes[c],
            };
            if (this.div.childNodes[c].firstChild) {
                var groupName =
                    this.div.childNodes[c].firstChild.getAttribute("name");

                //SET GROUP NAME
                if (groupName) {
                    if (!this.listGroupsAnnotations[groupName]) {
                        this.listGroupsAnnotations[groupName] = {};
                    }
                    this.listGroupsAnnotations[groupName][id] = {
                        domElement: this.div.childNodes[c],
                    };
                    this.listInternalsAnnotations[id]["groupName"] = groupName;
                }
            }
            this.setEventOnAnnotation(this.div.childNodes[c], id);
        }
        return true;
    }

    setEventOnAnnotation(container, id) {
        var tagName = container.childNodes[0].tagName.toLowerCase();
        var externalId = id;
        //STOP MENU COMMENT
        /*container.childNodes[0].addEventListener('mousedown', evt => {
            evt.stopPropagation();
            console.log('ok0');
           console.log(container);
        });*/

        /*container.childNodes[0].addEventListener('mouseup', evt => {
            evt.stopPropagation();
            console.log('ok2');
        });*/
        switch (tagName) {
            case "textarea":
                var type = container.childNodes[0]?.type?.toLowerCase();
                container.childNodes[0].addEventListener("keyup", (evt) => {
                    evt.stopPropagation();
                    this.setAnnotation({
                        playerPDF: this._pdfViewer,
                        tagName: tagName,
                        type: type,
                        externalId: externalId,
                        value: container.childNodes[0].value,
                    });
                });
                break;
            case "input":
            default:
                var type = container.childNodes[0]?.type?.toLowerCase();
                switch (type) {
                    case "checkbox":
                        container.childNodes[0].addEventListener(
                            "click",
                            (evt) => {
                                evt.stopPropagation();
                                this.setAnnotation({
                                    playerPDF: this._pdfViewer,
                                    tagName: tagName,
                                    type: type,
                                    externalId: externalId,
                                    value: "",
                                    checked: evt.target.checked,
                                });
                                /*  if (
                                    this.listInternalsAnnotations[id].groupName
                                ) {
                                    var groupName =
                                        this.listInternalsAnnotations[id]
                                            .groupName;
                                    if (this.listGroupsAnnotations[groupName]) {
                                        for (var checkId in this
                                            .listGroupsAnnotations[groupName]) {
                                            if (checkId == externalId) {
                                                continue;
                                            }
                                            var otherContainer =
                                                this.listGroupsAnnotations[
                                                    groupName
                                                ][checkId].domElement;
                                            if (
                                                !otherContainer.childNodes[0]
                                                    .checked
                                            ) {
                                                continue;
                                            }
                                            var otherTagName =
                                                otherContainer.childNodes[0].tagName.toLowerCase();
                                            var otherType =
                                                otherContainer.childNodes[0].type.toLowerCase();
                                            otherContainer.childNodes[0].checked = false;
                                            this.setAnnotation({
                                                playerPDF: this._pdfViewer,
                                                tagName: otherTagName,
                                                type: otherType,
                                                externalId: checkId,
                                                value: "",
                                                checked: false,
                                            });
                                        }
                                    }
                                } */
                                if (
                                    this.listInternalsAnnotations[id].groupName
                                ) {
                                    const groupName =
                                        this.listInternalsAnnotations[id]
                                            .groupName;
                                    const group =
                                        this.listGroupsAnnotations[groupName] ||
                                        {};

                                    Object.keys(group).forEach((checkId) => {
                                        if (checkId === externalId) return;

                                        const otherCheckbox =
                                            group[checkId].domElement
                                                .childNodes[0];
                                        if (otherCheckbox.checked) {
                                            otherCheckbox.checked = false;
                                            this.setAnnotation({
                                                playerPDF: this._pdfViewer,
                                                tagName:
                                                    otherCheckbox.tagName.toLowerCase(),
                                                type: otherCheckbox.type.toLowerCase(),
                                                externalId: checkId,
                                                value: "",
                                                checked: false,
                                            });
                                        }
                                    });
                                }
                            }
                        );

                        break;
                    case "text":
                        container.childNodes[0].addEventListener(
                            "keyup",
                            (evt) => {
                                evt.stopPropagation();
                                this.setAnnotation({
                                    playerPDF: this._pdfViewer,
                                    tagName: tagName,
                                    type: type,
                                    externalId: externalId,
                                    value: container.childNodes[0].value,
                                });
                            }
                        );
                        break;
                }
                break;
        }
    }

    setAnnotation({
        playerPDF: playerPDF,
        tagName: tagName,
        type: type,
        externalId: externalId,
        value: value,
        checked: checked = null,
    }) {
        playerPDF.createInternalAnnotation({
            type: type,
            id: externalId,
            tagName: tagName,
            value: value,
            checked: checked,
        });
    }
}

/**
 * @implements IPDFAnnotationLayerFactory
 */
class DefaultAnnotationLayerFactory {
    /**
     * @param {HTMLDivElement} pageDiv
     * @param {PDFPage} pdfPage
     * @param {AnnotationStorage} [annotationStorage]
     * @param {string} [imageResourcesPath] - Path for image resources, mainly
     *   for annotation icons. Include trailing slash.
     * @param {boolean} renderInteractiveForms
     * @param {IL10n} l10n
     * @returns {AnnotationLayerBuilder}
     */
    createAnnotationLayerBuilder(
        pageDiv,
        pdfPage,
        annotationStorage = null,
        imageResourcesPath = "",
        renderInteractiveForms = false,
        l10n = NullL10n
    ) {
        return new AnnotationLayerBuilder({
            pageDiv,
            pdfPage,
            imageResourcesPath,
            renderInteractiveForms,
            linkService: new SimpleLinkService(),
            l10n,
            annotationStorage,
        });
    }
}

export { AnnotationLayerBuilder, DefaultAnnotationLayerFactory };
